import React, { useState } from "react";
import { RxPencil1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { MdAdd, MdLocationingWatermark, MdLocationCity, MdOutlineDelete, MdOutlineGroup, MdRemoveRedEye, MdEdit, MdFileDownload, MdRefresh, MdOutlineLocationOn } from "react-icons/md";
import { observer } from "mobx-react";
import Filter from "./LocationFilter/Filter"
import ReactPaginate from 'react-paginate';
import { LocationStore, useLocationStore, LocationStoreContext } from "./store/LocationStore";
import { useAuthStore } from "../Auth/store/Auth";
import { useEffect } from "react";
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import { toast, ToastContainer } from "react-toastify";
import Moment from "react-moment";

const LocationManagement = () => {
    const store = new LocationStore({
        isloading: true,
        editloading: false
    });
    return (
        <LocationStoreContext.Provider value={store}>
            <Screen />
        </LocationStoreContext.Provider>
    )
}
export default LocationManagement;


const Screen = observer(() => {
    const {
        Location,
        ImportLoader,
        StatusLoader,
        BulkHomeCourt,
        setCsvFile,
        unsuccessdata,
        bulk,
        success,
        failed,
        handleChange,
        bulkupload,
        setBulkhomecourt,
        setbulkpopup,
        setbulkpopupclose,
        loadcity,
        nolist,
        Citypopup,
        BulkDelete,
        BulkstatusChange,
        LocationLoader,
        // setallLocation,
        // allLocation,
        setPaginationPage,
        setalldeactivate,
        alldeactivate,
        getLocationList,
        getFilterLocation,
        TotalPages,
        setLocationpopup,
        Locationpopup,
        deletepopup,
        setdeletepopup,
        setdelateLoader,
        delateLoader,
        statusChange,
        setstatuspopup,
        getid,
        getstatus,
        deletefunction,
        setchecked,
        checkeddd,
        getToken
    } = useLocationStore();

    const { adminAuthApproved, admin_permissions } = useAuthStore();

    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);

    const [currentPage, setCurrentPage] = useState(0);
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [allLocation, setallLocation] = useState([])
    // const [checkeddd, setchecked] = useState(false)

    const [photoIndex, setPhotoIndex] = useState("");
    const ITEMS_PER_PAGE = 10;
    const handlePageChange = ({ selected }) => {
        setPaginationPage(selected + 1);
        getFilterLocation();
        setCurrentPage(selected);
    };

    const handleClick = () => {
        const element = document.getElementById("tablescroll");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }
    const startIndex = currentPage * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const Locations = Location?.slice(startIndex, endIndex);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const openLightbox = (index) => {
        setLightboxIsOpen(true);
    };

    const closeLightbox = () => {
        setLightboxIsOpen(false);
    };

    const checkAllCheckboxes = () => {
        setallLocation([]);
        // Get all checkboxes with the same name attribute
        var checkboxes = document.getElementsByName('myCheckbox');

        if (checkeddd) {
            for (var i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = false;
            }
            setallLocation([]);
        } else {
            var emails = [];
            var commaSeparatedEmails = [];
            // var emailArray = emailString.split(",").map((email) => email.trim());
            // Loop through each checkbox and check it
            for (var i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = true;
                var emailValues = checkboxes[i].value.split(',');
                if (emailValues.length > 1) {
                    commaSeparatedEmails.push(...emailValues);
                } else {
                    var emailValue = emailValues[0].trim();
                    if (emailValue !== '' && !emails.includes(emailValue)) {
                        emails.push(emailValue);
                    }

                }
            }
            setallLocation((prevEmails) => [...prevEmails, ...emails, ...commaSeparatedEmails]);
        }

    }
    const isallcheck = (e) => {
        var checkboxes = document.getElementsByName('myCheckbox');
        for (var i = 0; i < checkboxes.length; i++) {
            var updatedEmails = Array.from(checkboxes)
                .filter((checkbox) => checkbox.checked)
                .flatMap((checkbox) => checkbox.value.split(',').map((email) => email.trim()));
            setallLocation(updatedEmails);
            if (!checkboxes[i].checked) {
                setchecked(false)
                return
            }
            else {
                setchecked(true)
            }

        }
    }
    useEffect(() => {
        setTimeout(() => {
            getFilterLocation();
        }, 1000);
    }, [setCsvFile])

    console.log(Location);

    return (
        <>
            <div className="flex justify-between">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">Location Management</p>
                    <p className=" opacity-80 text-sm">Home / Location</p>
                </div>
                <div>
                    <label
                        title=""
                        onClick={() => { setBulkhomecourt(true); setbulkpopup() }}
                        htmlFor="csv"
                        className="mr-2 btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                        <MdFileDownload />
                        State Bulk Import
                    </label>
                    <Link
                        to="./addlocation"
                        className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                        <MdAdd />
                        Add Location
                    </Link>
                </div>
                {/* <Link
                    to="./addlocation"
                    className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                >
                    <MdAdd />
                    Add Location
                </Link> */}


            </div>

            <Filter />

            {/* 
            <div className="flex justify-between items-center py-2">
                <div className="ml-3">
                    <label className=" font-extrabold">
                        <input className="mr-2" checked={checkeddd} type="checkbox" name="selectall"
                            onClick={() => { checkAllCheckboxes(); setchecked(!checkeddd) }}


                        />
                        Select All
                    </label>
                </div>
                <div className="space-x-3 flex justify-center">
           
                    <label class={`relative ${allLocation.length == 0 && "opacity-50"}  inline-flex items-center  cursor-pointer`}>
                        {admin_permissions.Location === 1 ?
                            <input disabled={allLocation.length == 0 && true} onClick={() => { setalldeactivate(); BulkstatusChange(allLocation) }} type="checkbox" class="sr-only peer" />
                            :
                            <input disabled onClick={() => {
                                toast.warning("You have no permissions to edit.")

                            }} type="checkbox" class="sr-only peer" />
                        }

                        <div class="w-7 h-3 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:bLocation-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:bLocation-gray-300 after:bLocation after:rounded-full after:h-4 after:w-4 after:transition-all dark:bLocation-gray-600 peer-checked:bg-blue-600"></div>
                        <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"> {!alldeactivate ? "All Activate" : "All Deactivate"}
                        </span>
                    </label>

           
                    <label class={`relative ${allLocation.length == 0 && "opacity-50"}  justify-center text-center   inline-flex   cursor-pointer`}>
                    {admin_permissions.Location === 1 ?

                        <button disabled={allLocation.length == 0 && true} className="flex" onClick={() => { BulkDelete(allLocation) }}  >
                            <MdOutlineDelete className="text-[red]" />
                            <span class="ms-1 text-sm font-medium text-center text-gray-900 dark:text-gray-300">
                                All Delete
                            </span>
                        </button>
                        :
                        <button disabled={allLocation.length == 0 && true} className="flex" onClick={() => {  toast.warning("You have no permissions to edit.") }}  >
                        <MdOutlineDelete className="text-[red]" />
                        <span class="ms-1 text-sm font-medium text-center text-gray-900 dark:text-gray-300">
                            All Delete
                        </span>
                    </button>
}

                    </label>


                </div>
            </div> */}

            <div className="card bg-base-100 shadow-md p-6 mt-8">
                <div className="overflow-x-auto w-full">
                    <table id="tablescroll" className="table table-zebra w-full">
                        <thead>
                            <tr>
                                {/* <th></th> */}
                                <th>State</th>
                                <th>City</th>
                                <th>PIN Code</th>
                                <th> status</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody className="text-sm relative">
                            {LocationLoader &&
                                <>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="animate-pulse py-6"></td>
                                    </tr>
                                </>
                            }
                            {
                                // (Locations.length === 0 ? Location : Locations)?.map((data, index) => (
                                //     <tr key={index} className="capitalize">
                                //         {/* <td>

                                //             <input
                                //                 value={data?.id}
                                //                 onChange={(e) => { setchecked(false); isallcheck(e.target.value); }}
                                //                 type="checkbox"
                                //                 name="myCheckbox"
                                //             />
                                //         </td> */}
                                //         <td>
                                //             {data?.Location_id}
                                //         </td>

                                //         <td className="flex flex-col ">
                                //             <div>
                                //                 {data?.buyer_fullname ?? "N/A"}

                                //             </div>
                                //             <div>
                                //                 {data?.buyer_email ?? "N/A"}

                                //             </div>

                                //         </td>
                                //         <td>
                                //             <Moment
                                //                 format={
                                //                     "DD/MM/YYYY"
                                //                 }
                                //             >
                                //                 {data.Location_date_time}
                                //             </Moment>

                                //         </td>

                                //         <td>
                                //             {data.total_amount}

                                //         </td>



                                //         <td>
                                //             <label
                                //                 title="Change Status"
                                //                 htmlFor="my-modal-5"
                                //                 className={`btn capitalize Active" ${data?.is_verified === 1 ? "bg-[#1dc9b7] hover:bg-[#1dc9b7]" : "bg-error hover:bg-error"}  text-[#000] bLocation-hidden  btn-xs`}

                                //             // onClick={(e) => {
                                //             //     getid(data?.id);
                                //             //     setProductCatalogpopup(true);
                                //             //     getstatus(data?.is_active === 1 ? 0 : 1);
                                //             // }}
                                //             >
                                //                 {data?.payment_status ?? "n/a"}
                                //             </label>
                                //         </td>

                                //         <th >
                                //             <div className="flex space-x-3">

                                //                 <div className="flex items-center space-x-3">
                                //                     {admin_permissions.Locations === 1 ?
                                //                         <label
                                //                             htmlFor="deletecity"
                                //                             className=" text-[#d02c2c] cursor-pointer"
                                //                             title="Delete"
                                //                             onClick={() => { setdeletepopup(true); getid(data?.id) }}
                                //                         >
                                //                             <MdOutlineDelete />
                                //                         </label>
                                //                         :
                                //                         <label
                                //                             htmlFor="deletecity"
                                //                             className=" text-[#d02c2c] cursor-pointer"
                                //                             title="Delete"
                                //                             onClick={() => { toast.warning("You have no permissions to edit.") }}
                                //                         >
                                //                             <MdOutlineDelete />
                                //                         </label>
                                //                     }
                                //                     <button title="view product">
                                //                         <Link to={`./product/${data?.Location_id}/${data.total_amount}`}>

                                //                             <MdEdit />

                                //                         </Link>
                                //                     </button>


                                //                 </div>
                                //             </div>

                                //         </th>

                                //     </tr>
                                // ))
                                (Locations.length === 0 ? Location : Locations)?.map((data, index) => (
                                    <tr className="capitalize">
                                        {/* <td>

                                    <input
                                        value={data?.id}
                                        onChange={(e) => { setchecked(false); isallcheck(e.target.value); }}
                                        type="checkbox"
                                        name="myCheckbox"
                                    />
                                </td> */}
                                        <td>
                                            {data.state_name}
                                        </td>

                                        <td >

                                            {data.city}




                                        </td>
                                        <td>
                                            {data.pincode}

                                        </td>





                                        <th >
                                            <div className="flex space-x-3">
                                                <label
                                                    title="Change Status"
                                                    htmlFor="my-modal-5"
                                                    className={`btn capitalize Active" ${data?.is_active === 1 ? "bg-[#5dff5d] hover:bg-[#5dff5d]" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                                                    onClick={(e) => {
                                                        getid(data?.id);
                                                        setLocationpopup(true);
                                                        getstatus(data?.is_active === 1 ? 0 : 1);
                                                    }}
                                                >

                                                    {data?.is_active === 1 ? "Active" : "Inactive"}
                                                </label>

                                            </div>

                                        </th>

                                        <th >
                                            <div className="flex space-x-3">

                                                <div className="flex items-center space-x-3">
                                                    {/* {admin_permissions.Locations === 1 ? */}
                                                    <label
                                                        htmlFor="deletecity"
                                                        className=" text-[#d02c2c] cursor-pointer"
                                                        title="Delete"
                                                        onClick={() => { setdeletepopup(true); getid(data?.id) }}
                                                    // onClick={() => { setdeletepopup(true); }}


                                                    >
                                                        <MdOutlineDelete />
                                                    </label>
                                                    {/* :
                                                    <label
                                                        htmlFor="deletecity"
                                                        className=" text-[#d02c2c] cursor-pointer"
                                                        title="Delete"
                                                        onClick={() => { toast.warning("You have no permissions to edit.") }}
                                                    >
                                                        <MdOutlineDelete />
                                                    </label>
                                                } */}
                                                    <button title="Edit location">
                                                        <Link to={`./${data?.id}`}>


                                                            <MdEdit />

                                                        </Link>
                                                    </button>


                                                </div>
                                            </div>

                                        </th>

                                    </tr>
                                ))
                            }


                            {nolist &&
                                <tr>
                                    <td colSpan={7} className="my-10 text-center">
                                        <MdOutlineGroup className=" text-[#dedede] text-8xl mx-auto" />
                                        <p className=" font-semibold text-lg">No Location Found</p>
                                    </td>
                                </tr>}
                        </tbody>
                    </table>
                    {/* {!city?.length == 0 && */}

                    <ReactPaginate
                        initialPage={0}
                        pageCount={Math.ceil(TotalPages)}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        onClick={handleClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}

                    />
                    {/* } */}

                    {/* status change model start */}
                    {/* <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status ?
              </h3>
              <div className="modal-action justify-center">
                      <label
                        htmlFor="my-modal-5"
                        className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                        onClick={() => statusChange()}
                      >
                        Yes
                      </label> 
                <label htmlFor="my-modal-5" className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                  No
                </label>
              </div>
            </div>
          </div> */}
                    {/* status change model end */}
                    {deletepopup &&
                        <>
                            <input type="checkbox" id="deletecity" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative">
                                    <label

                                        className="btn btn-sm btn-circle absolute right-2 top-2"
                                        onClick={() => { setdelateLoader(false); setdeletepopup(false) }}
                                    >
                                        ✕
                                    </label>
                                    <h3 className="text-lg font-bold text-center">
                                        Are you sure you want to Delete this Location
                                    </h3>
                                    <div className="modal-action justify-center">
                                        {/* <label
                                            htmlFor="deletecity"
                                            className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                            onClick={() => deletefunction()}
                                        >
                                            Yes
                                        </label> */}
                                        {!delateLoader ?
                                            <label

                                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                                onClick={() => deletefunction()}
                                            >
                                                Yes
                                            </label> :
                                            <button
                                                // type="submit"
                                                disabled
                                                className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                                            // onClick={() => {
                                            //   PostMatchscore();
                                            // }}
                                            >
                                                Yes..
                                                <svg
                                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                            </button>}
                                        <label
                                            onClick={() => setdeletepopup(false)}
                                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>

                    }
                    {BulkHomeCourt && (
                        <>
                            <input type="checkbox" id="csv" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative">
                                    <form
                                        onSubmit={(e) => {
                                            bulkupload();
                                            e.preventDefault();
                                        }}
                                    >
                                        <button type="reset">
                                            <label
                                                type="reset"
                                                // htmlFor="csv"
                                                className="btn btn-sm btn-circle absolute right-2 top-2"
                                                onClick={() => {
                                                    setbulkpopupclose();
                                                    setBulkhomecourt(false)
                                                }}
                                            >
                                                ✕
                                            </label>
                                        </button>
                                        Download Sample File -:{" "}
                                        <a
                                            href={`${BASE_URL}/csv/state.csv`}
                                            className=" btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] "
                                            download
                                        >
                                            <MdFileDownload /> Download
                                        </a>
                                        <input
                                            required
                                            value={setCsvFile?.value}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                            type="file"
                                            className="mt-3 input focus:outline-0 w-full p-3 text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                                            accept=".csv"
                                        />
                                        <div className="modal-action justify-center">
                                            {!bulk ? (
                                                <>
                                                    <button
                                                        type="reset"
                                                        // onClick={reset}
                                                        className=" btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                                                    >
                                                        <MdRefresh />
                                                        Reset
                                                    </button>
                                                    {!ImportLoader ? (
                                                        <button
                                                            type="submit"
                                                            className=" btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                                        >
                                                            <MdFileDownload /> Import
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className=" btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                                            type="button"
                                                        //  onClick={()=> changeStep("4")}
                                                        >
                                                            <svg
                                                                className="animate-spin mr-1 h-5 w-5 text-white"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <circle
                                                                    className="opacity-25"
                                                                    cx="12"
                                                                    cy="12"
                                                                    r="10"
                                                                    stroke="currentColor"
                                                                    strokeWidth="4"
                                                                ></circle>
                                                                <path
                                                                    className="opacity-75"
                                                                    fill="currentColor"
                                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                ></path>
                                                            </svg>
                                                            Loading..
                                                        </button>
                                                    )}
                                                </>
                                            ) : (
                                                <button
                                                    type="button"
                                                    className="mt-5 btn w-full bg-[#000] hover:bg-[#000] text-color_theme hover:text-color_theme font-semibold border-none flex justify-center items-center"
                                                >
                                                    <svg
                                                        className="animate-spin mr-1 h-5 w-5 text-white"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            className="opacity-25"
                                                            cx="12"
                                                            cy="12"
                                                            r="10"
                                                            stroke="currentColor"
                                                            strokeWidth="4"
                                                        ></circle>
                                                        <path
                                                            className="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                        ></path>
                                                    </svg>
                                                </button>
                                            )}
                                        </div>
                                    </form>

                                    {unsuccessdata.length == 0 ? (
                                        <></>
                                    ) : (
                                        <>
                                            <div className="flex justify-between">
                                                <div className=" mb-4 mt-9">
                                                    <p className=" text-2xl font-semibold text-[#ff2d2d]">
                                                        Result
                                                    </p>
                                                </div>
                                                <div className="">
                                                    <div
                                                        title="Success Count"
                                                        className="mt-9 ml-28 btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                                                    >
                                                        <span>Successful: {success}</span>
                                                    </div>
                                                </div>
                                                <div
                                                    title="Success Count"
                                                    className="mt-9 btn btn-sm bg-[#000] text-[#ff2d2d] text-sm hover:bg-[#000]"
                                                >
                                                    <span>Failed: {failed}</span>
                                                </div>
                                            </div>
                                            <div className="max-h-[300px] overflow-auto">
                                                <table className="table table-zebra w-full">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>City</th>
                                                            <th>Address</th>
                                                            <th>Name</th>
                                                            <th>Zip Code</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-sm relative">
                                                        {unsuccessdata.map((data, index) => (
                                                            <tr key={index} className="capitalize">
                                                                <td>{index + 1}</td>
                                                                <td>{data?.city}</td>
                                                                <td>{data?.address}</td>
                                                                <td>{data?.name ?? "N/A"}</td>
                                                                <td>{data.zipcode ?? "N/A"}</td>
                                                            </tr>
                                                        ))}
                                                        {nolist && (
                                                            <tr>
                                                                <td colSpan={5} className="my-10 text-center">
                                                                    <MdOutlineLocationOn className=" text-[#dedede] text-8xl mx-auto" />
                                                                    <p className=" font-semibold text-lg">
                                                                        No Court Found
                                                                    </p>
                                                                    <Link
                                                                        to="./addhomecourt"
                                                                        className="btn btn-sm mt-4 bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                                                                    >
                                                                        <MdAdd />
                                                                        Add Court
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {Locationpopup &&
                        <>
                            <input type="checkbox" id="my-modal-5" className="modal-toggle" />
                            <div className="modal">
                                <div className="modal-box relative">
                                    <label

                                        className="btn btn-sm btn-circle absolute right-2 top-2"
                                        onClick={() => setLocationpopup(false)}
                                    >
                                        ✕
                                    </label>
                                    <h3 className="text-lg font-bold text-center">
                                        Are you sure you want to change Status ?
                                    </h3>
                                    <div className="modal-action justify-center">
                                        {!StatusLoader ? <label

                                            className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                            onClick={() => statusChange()}
                                        >
                                            Yes
                                        </label> :
                                            <button
                                                // type="submit"
                                                disabled
                                                className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                                            // onClick={() => {
                                            //   PostMatchscore();
                                            // }}
                                            >
                                                Yes..
                                                <svg
                                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                            </button>}

                                        <label
                                            onClick={() => setLocationpopup(false)}
                                            // htmlFor="voidstatus"
                                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                                        >
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </>

                    }
                </div>
            </div>
        </>
    );
});

