import React, { useEffect, useState } from "react";
import { MdAdd, MdOutlinedFlag, MdRemoveRedEye, MdSportsHandball,MdSportsTennis,MdMan3 } from "react-icons/md";
import {AiOutlineCrown} from "react-icons/ai"
import { RxEyeOpen, RxPencil1 } from "react-icons/rx";
import { Link, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import Filter from "./LeagueFilter/Filter";
import {
  useLeagueStore,
  LeagueStore,
  LeagueStoreContext,
} from "./store/LeagueStore";
import Moment from "react-moment";
import { BsSignpostSplit } from "react-icons/bs";
import { useAuthStore } from "../Auth/store/Auth";
const LeagueManagement = () => {
  const store = new LeagueStore({
    isloading: true,
    seasonlist: true,
    editloading: false,
  });
  return (
    <LeagueStoreContext.Provider value={store}>
      <Screen />
    </LeagueStoreContext.Provider>
  );
};
export default LeagueManagement;

const Screen = observer(() => {
  const {
    League,
    LeagueLoader,
    Leaguepopup,
    setleaguepopup,
    getstatus,
    getleagaueid,
    statusChange,
    nolist,
    getleaguestatus,
    getallleaguestatus,
    loadleague,
    leaguestatus,
    leveldropdown,
    getToken
  } = useLeagueStore();
  const { adminAuthApproved } = useAuthStore();
  const { id } = useParams();
  const navigate =useNavigate()
  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }
  
  }, [adminAuthApproved]);
const [level, setLevel] = useState("")
const [leagueid,setLeagueid ] = useState("")
const [leaguename,setLeaguename ] = useState("")

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">League Management</p>
          {/* <p className=" opacity-80 text-sm">Home / League</p> */}
        </div>
        <div>
          {/* <Link to="./addleague" className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"><MdAdd/>Add League</Link> */}
        </div>
      </div>
      <Filter league={id} />

      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th>#</th>
                <th>League Name</th>
                <th>Season Name</th>
                <th>City Name</th>
                <th>Year</th>
                <th>League Type</th>
                <th>League Category</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Registration Start</th>
                <th>Registration End</th>
                <th>Fees Amount</th>
                {/* <th>Last Registration Date</th> */}
                <th>Status</th>
                <th>League Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {loadleague && (
                <>
                  <tr>
                    <td colSpan={13} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={13} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={13} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={13} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={13} className="animate-pulse py-6"></td>
                  </tr>
                </>
              )}

              {League.map((data, index) => (
                <tr key={index} className=" capitalize">
                  <td>{index + 1}</td>
                  <td>{data.name ?? "N/A"}</td>
                  <td>{data?.season_id?.name ?? "N/A"}</td>
                  <td>{data?.season_id?.city?.city_name ?? "N/A"}</td>
                  <td>{data?.season_id?.year ?? "N/A"}</td>
                  <td>{data.league_type ?? "N/A"}</td>
                  <td>{data.league_catagory ?? "N/A"}</td>
                  <td>
                    {<Moment format="MM/DD/YYYY">{data.start_date}</Moment> ??
                      "N/A"}
                  </td>
                  <td>
                    {<Moment format="MM/DD/YYYY">{data.end_date}</Moment> ??
                      "N/A"}
                  </td>
                  <td>
                    {<Moment format="MM/DD/YYYY">{data?.start_registration_date}</Moment> ??
                      "N/A"}
                  </td>
                  <td>
                    {<Moment format="MM/DD/YYYY">{data?.last_registration_date}</Moment> ??
                      "N/A"}
                  </td>
                
                  <td>$ {data.fees_amount ?? "N/A"}</td>

                  {/* <td className=" text-center">{<Moment format="YYYY/MM/DD">{data.last_registration_date}</Moment> ?? "N/A"}</td> */}

                  <th>
                    <label
                      title="Change Status"
                      htmlFor="courtstatus"
                      className={`btn capitalize ${
                        data?.isActive
                          ? "bg-color_theme hover:bg-color_theme"
                          : "bg-warning hover:bg-warning"
                      }  text-[#000] border-hidden  btn-xs`}
                      onClick={(e) => {
                        setleaguepopup(true)
                        getleagaueid(data?._id);
                        getstatus(data?.isActive);
                      }}
                    >
                      {data?.isActive ? "Active" : "Inactive"}
                    </label>
                  </th>
                  <th>
                    <label
                      title="League Status"
                      // htmlFor="leaguestatus"
                      className={`btn capitalize ${
                        data?.league_status == "entry_open"
                          ? "bg-color_theme hover:bg-color_theme"
                          : data.league_status == "entry_close"
                          ? "bg-error hover:bg-error"
                          : data.league_status == "completed"
                          ? "bg-color_theme hover:bg-warning"
                          : data.league_status === "open"
                          ? "bg-color_theme hover:bg-color_theme"
                          :  data.league_status === "upcoming" ?
                          "bg-[yellow] hover:bg-[yellow]":
                          null
                      }  text-[#000] border-hidden  btn-xs`}
                      onClick={(e) => {
                        getleagaueid(data?._id);
                        getleaguestatus(data?.league_status);
                      }}
                    >
                      {data?.league_status == "entry_open" ? (
                        <>Entry Open</>
                      ) : data.league_status === "entry_close" ? (
                        "Entry Closed"
                      ) : data.league_status === "completed" ? (
                        "Completed"
                      ) : data.league_status === "open" ? (
                        "Open"
                      ) :  data.league_status === "upcoming" ? (
                          "Upcoming") :
                          null
                      }
                    </label>
                  </th>
                  <td className=" text-base">
                    <span className="flex items-center space-x-4">
                      <span className="tooltip" data-tip="Edit">
                      <Link className="cursor-pointer" to={`/leaguemanagement/${data?._id}`}>
                        <RxPencil1 />
                      </Link>
                      </span>
                      <span className="tooltip" data-tip="View Players">
                      <Link className="cursor-pointer" to={`../playersmanagement/league/${data?._id}`}>
                        <MdSportsHandball />
                      </Link>
                      </span>
                      <span className="tooltip" data-tip="View Divisions">
                      <Link className="cursor-pointer" to={`/divisionmanagement/league/${data?._id}`} >
                        <BsSignpostSplit />
                      </Link> 
                      </span>

                        <label className="tooltip" data-tip="Playoff Hierarchy" htmlFor="levelpopup"
                        onClick={()=>{
                        setLeagueid(data?._id);
                        setLeaguename(data?.name)
                        }} 
                        >
                        <AiOutlineCrown
                        //  title="Playoff Hierarchy"
                         className="cursor-pointer"/>
                        </label>

                        <label className="tooltip" data-tip="Playoff Matches" title="Playoff Matches" htmlFor="playoffpopup"
                        onClick={()=>{
                        setLeagueid(data?._id);
                         setLeaguename(data?.name)
                        }} 
                        >
                        <MdSportsTennis
                        //  title="Playoff Matches"
                         className="cursor-pointer"/>
                        </label>
                        <span className="tooltip" data-tip="View Excluded Player">
                      <Link className="cursor-pointer" to={`/playerreason/${data?._id}`} >
                        <MdMan3 />
                      </Link> 
                      </span>
                    </span>
                   
                  </td>
                </tr>
              ))}
              {nolist && (
                <tr>
                  <td colSpan={13} className="my-10 text-center">
                    <MdOutlinedFlag className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">No League Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* popup for Playoff matches level */}
          <input type="checkbox" id="playoffpopup" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="playoffpopup"
                className="btn btn-sm btn-circle absolute right-2 top-2"
                onClick={()=>{
                  setLeagueid("");
                  setLevel("")
                  setLeaguename("")
                  }}
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Select Level For Playoff Matches
              </h3>
              <div className="modal-action justify-center">
                <select
                   onChange={(e) => setLevel(e.target.value)}
                   value={level}
                  className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                  // aria-label="Default select example"
                >  
                 <option value=""> Select Level</option>
                {leveldropdown?.map((data,index)=> (
                        <option value={data?._id} key={index}>{data?.name.toFixed(1)} </option>
                        ))}
                  
                </select>
              </div>
              <div className="modal-action justify-center">
             
                <label
                  // htmlFor="levelpopup"
                  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                    onClick={() => {
                      if(level){
                        navigate(`/playoffmatches/${leagueid}/${level}/${leaguename}`)
                      }
                    }}
                >
                  Yes
                </label>
                <label
                  htmlFor="playoffpopup"
                  className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                  onClick={()=>{
                  setLeagueid("");
                  setLevel("")
                  setLeaguename("")
                  }}
                >
                  No
                </label>
              </div>
            </div>
          </div>


          {/* popup for Hierarchy level */}
          <input type="checkbox" id="levelpopup" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="levelpopup"
                className="btn btn-sm btn-circle absolute right-2 top-2"
                onClick={()=>{
                  setLeagueid("");
                  setLevel("")
                  setLeaguename("")
                  }}
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Select Level For Playoff Hierarchy
              </h3>
              <div className="modal-action justify-center">
                <select
                   onChange={(e) => setLevel(e.target.value)}
                   value={level}
                  className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                  // aria-label="Default select example"
                >  
                 <option value=""> Select Level</option>
                {leveldropdown?.map((data,index)=> (
                        <option value={data?._id} key={index}>{data?.name.toFixed(1)} </option>
                        ))}
                  
                </select>
              </div>
              <div className="modal-action justify-center">
                <label
                  // htmlFor="levelpopup"
                  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                    onClick={() => {
                      if(level){
                        navigate(`/playerstree/${leagueid}/${level}/${leaguename}`)
                      }
                    }}
                >
                  Yes
                </label>
                <label
                  htmlFor="levelpopup"
                  className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                  onClick={()=>{
                  setLeagueid("");
                  setLevel("")
                  setLeaguename("")
                  }}
                >
                  No
                </label>
              </div>
            </div>
          </div>

          {/* status popup */}
          {Leaguepopup && 
        <>
          <input type="checkbox" id="courtstatus" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
         
                className="btn btn-sm btn-circle absolute right-2 top-2"
                onClick={()=>setleaguepopup(false)}
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status ?
              </h3>
              <div className="modal-action justify-center">
                {!LeagueLoader ?        <label
           
                  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                  onClick={() => statusChange()}
                >
                  Yes
                </label> :   
                <button
                            // type="submit"
                            disabled
                            className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                            // onClick={() => {
                            //   PostMatchscore();
                            // }}
                          >
                            Yes..
                            <svg
                              className="animate-spin mr-1 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </button> }
         
                          <label
            onClick={()=>setleaguepopup(false)}
              // htmlFor="voidstatus"
              className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
            >
              No
            </label>
              </div>
            </div>
          </div>
        
        </>
       
}

          <input type="checkbox" id="leaguestatus" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="leaguestatus"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status ?
              </h3>
              <div className="modal-action justify-center">
                <select
                  onChange={(e) => getleaguestatus(e.target.value)}
                  value={leaguestatus}
                  className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                  aria-label="Default select example"
                >
                  <option value="entry_open">Entry Open</option>
                  <option value="open"> Open</option>
                  <option value="closed">Close</option>
                  <option value="completed">Completed</option>
                  <option value="upcoming">Upcoming</option>
                </select>
              </div>
              <div className="modal-action justify-center">
                <label
                  htmlFor="leaguestatus"
                  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                  onClick={() => getallleaguestatus()}
                >
                  Yes
                </label>
                <label
                  htmlFor="leaguestatus"
                  className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                >
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
