import React, { useEffect, useState } from "react";
import { Input } from "react-daisyui";
import { MdOutlineCheck, MdOutlineClose } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import {
  SeasonStore,
  SeasonStoreContext,
  useSeasonStore,
} from "./store/SeasonStore";
import { useAuthStore } from "../Auth/store/Auth";
const AddSeasonsManagement = observer(() => {
  return (
    <SeasonStoreContext.Provider
      value={
        new SeasonStore({
          loadeditcitybol: false,
          editloading: false,
          slectcity: true,
        })
      }
    >
      <Screen />
    </SeasonStoreContext.Provider>
  );
});

export default AddSeasonsManagement;
const Screen = observer(() => {
  const {
    addSeason,
    getstartdate,
    getenddate,
    citydrop,
    getcityname,
    getname,
    getstatus,
    getyear,
    saved,
    year,
    addseasoncourt,
    getToken
  } = useSeasonStore();
 
  const { adminAuthApproved } = useAuthStore();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }
  
  }, [adminAuthApproved]);

 
  const [startdatestate, setstartdatestate] = useState(null);
  const [updatedate,setUpdatedate] = useState(null);
  const [selectyear,setSelectyear] =useState(null);
  const [minDate, setMinDate] = useState('');

  useEffect(() => {
    // Get the current date and time in the New York time zone
    const now = new Date();
    const newYorkTimeZone = 'America/New_York';
    const nyNow = new Date(now.toLocaleString('en-US', { timeZone: newYorkTimeZone }));
  
    // Format the date in ISO format (YYYY-MM-DD)
    const minDate = nyNow.toISOString().split('T')[0];
    setMinDate(minDate);
  }, []);
  useEffect(() => {
    if (saved) {
      navigate("../seasonmanagement");
    }
  }, [saved, startdatestate]);
  
  const currentYear = new Date().getFullYear();
  const [years, setYears] = useState([]);
  useEffect(() => {
    // const getCurrentYear = () => {
    //   return 
      
    // };
    const generateYears = () => {
      
      const yearArray = [];

      for (let i = 0; i <= 5; i++) {
        const yearcurr = currentYear + i;
        yearArray.push(yearcurr);
      }

      setYears(yearArray);
    };

    generateYears();
  }, []);


//   const getCurrentDate = () => {
//     const today = new Date();
//     const selectyear = year
//     const month = String(today.getMonth() + 1).padStart(2, '0');
//     const day = String(today.getDate()).padStart(2, '0');
//     return `${selectyear}-${month}-${day}`;
//   };

//   const minDate = getCurrentDate();



  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Add Season</p>
          {/* <p className=" opacity-80 text-sm">Home / Season/ Add</p> */}
        </div>
        <div>
          <Link
            to="../seasonmanagement"
            className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <form
          onSubmit={(e) => {
            addSeason();
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
            <div>
              <label
                htmlFor="SeasonName"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Season Name<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                defaultValue=""
                id="SeasonName"
                required
                onChange={(e) => getname(e.target.value)}
                name="SeasonName"
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="" disabled className="hidden">
                  Select Season
                </option>
                <option value="Spring">Spring</option>
                <option value="Summer">Summer</option>
                <option value="Fall">Fall</option>
                <option value="Winter">Winter</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="City"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                City<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                required
                defaultValue=""
                id="city"
                name="city"
                onChange={(e) => getcityname(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="" disabled className="hidden">
                  Select city
                </option>
                {citydrop.map((data, index) => (
                  <option value={data?._id} key={index}>
                    {data?.city_name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label
                htmlFor="Year"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Year<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                defaultValue=""
                required
                name="Year"
                id="Year"
                onChange={(e) => {getyear(e.target.value);setSelectyear(e.target.value)}}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="" disabled className="hidden">
                  Select Year
                </option>
                {years.map((year, index) => (
                <option value={year} key={index}>
                {year}
                </option>
                ))}
                {/* <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option> */}
              </select>
            </div>

            <div>
              <label
                htmlFor="startdate"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Start Date<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <input
                name="startdate"
                placeholder=""
                disabled={selectyear === null ? true : false}
                onChange={(e) => {
                  getstartdate(e.target.value);
                  setstartdatestate(e.target.value);
                }}
                id="startdate"
                //  min={new Date().toISOString().split("T")[0]}
                // min={year}
                min={currentYear==year ? minDate :`${year}-01-01`}
                // max="2025-01-01"
                type="date"
                autoComplete="off"
                required
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>
            <div>
              <label
                htmlFor="enddate"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                End Date<span className="text-[#f00]">&nbsp;*</span>
              </label>

              <input
                name="enddate"
                placeholder=""
                onChange={(e) => getenddate(e.target.value)}
                id="enddate"
                disabled={startdatestate === null ? true : false}
                // max={}
                // min={
                //   new Date(startdatestate ? startdatestate : null)
                //     .toISOString()
                //     .split("T")[0]
                // }
                min={
                startdatestate
                ? new Date(new Date(startdatestate).getTime() + 89 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .split('T')[0]
                : ''
                }
                // max="2025-01-01"
                type="date"
                autoComplete="off"
                required
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>
            <div>
              <label
                htmlFor="Status"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Status<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                name="Status"
                id="Status"
                required
                onChange={(e) => getstatus(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="" className="hidden">
                  Select Status
                </option>
                <option value="true">Active</option>
                <option value="false">Inactive </option>
              </select>
            </div>
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
            <Link
              to="../seasonmanagement"
              className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
            >
              <MdOutlineClose className="mr-1" />
              Cancel
            </Link>
            {!addseasoncourt?
            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
            <MdOutlineCheck className="mr-1" />
            Add Season
          </button>
          :
          <button 
                         className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                          type="button"
                        //  onClick={()=> changeStep("4")}
                        >
                        <svg
                            className="animate-spin mr-1 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            >
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                          Loading..
                        </button>
            }
            
          </div>
        </form>
      </div>
    </>
  );
});
