import React, { useEffect } from "react";
import {
  MdRemoveRedEye,
  MdSportsTennis,
} from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import Filter from "./TieFilter/Filter";
import { TieStore, TieStoreContext, useTieStore } from "./store/store";
import { useAuthStore } from "../Auth/store/Auth";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
// const Setteled = () => {
//   const { settled } = useParams();

//   const store = new TieStore({
//     isloading: true,
//     tiebreaker: settled === "settled" ? false : true,
//   });
//   return (
//     <TieStoreContext.Provider value={store}>
//       <Screen />
//     </TieStoreContext.Provider>
//   );
// };

const Setteled = observer(() => {
  const { adminAuthApproved } = useAuthStore();
  const [open, setOpen] = React.useState();
 
  const handleOpen = (value) => setOpen(open === value ? 0 : value);
//   const { settled } = useParams();

  const { loadTie, TieList, nolist, getToken, getSettledTieBreaker } = useTieStore();
  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }
  }, [adminAuthApproved]);
  useEffect(()=>{
   
      getSettledTieBreaker();

    
  
  },[])

  const goBack = () => {
    window.history.back(); // Go back to the previous pagehistory.goBack(); // Go back to the previous page
  };

  function Icon({ id, open }) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
      </svg>
    );
  }

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Tie Breakers</p>
          {/* <p className=" opacity-80 text-sm">Home / League</p> */}
        </div>
        <div>
          <button
             onClick={()=>{window?.history?.back()}}
            className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
          >
            Back
          </button>
        </div>
      </div>
      {/* <Filter prop={settled} /> */}

      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
          {/* <table className="table table-zebra w-full">
            <thead>
              <tr className=" sticky top-0  ">
                <th className="px-3 text-left ">#</th>
                <th className="md:py-5 text-left px-3 ">Player Name</th>
                <th className="md:py-5 text-left px-3 ">Mate</th>
                <th className="md:py-5 text-left px-3 ">League Name</th>
                <th className="md:py-5 text-left px-3 ">Division</th>
                <th className="md:py-5 text-left px-3 ">Level</th>
                <th className="text-left px-3">Win</th>
                <th className="text-left px-3">Loss</th>
                <th className="text-left px-3">Points</th>
                <th className="text-left px-3" >Tie Points</th>
                <th className="md:py-5 text-left px-3 ">Settled</th>
                <th className="text-left px-3">Action</th>
              </tr>
            </thead>
            <tbody className="text-sm relative"> */}
          
{TieList.length != 0 &&
<>
{
                  Object.keys(TieList)?.map((r,index) => (
                    <>
                      <Accordion open={open === index+1} icon={<Icon id={index+1} open={open} />} >
        <AccordionHeader onClick={() => handleOpen(index+1)}> <p className="text-[20px]">Tie Breakers For Division: #{<span className="text-color_theme ">{r}</span>}</p></AccordionHeader>
        <AccordionBody>
        {loadTie && (
                <>
                  <tr>
                    <td colSpan={12} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={12} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={12} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={12} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={12} className="animate-pulse py-6"></td>
                  </tr>
                </>
              )}
                 <table className="table table-zebra w-full">
            <thead>
              <tr className=" sticky top-0  ">
                <th className="px-3 text-left ">#</th>
                <th className="md:py-5 text-left px-3 ">Player Name</th>
                <th className="md:py-5 text-left px-3 ">Mate</th>
                <th className="md:py-5 text-left px-3 ">League Name</th>
                <th className="md:py-5 text-left px-3 ">Division</th>
                <th className="md:py-5 text-left px-3 ">Level</th>
                <th className="text-left px-3">Win</th>
                <th className="text-left px-3">Loss</th>
                <th className="text-left px-3">Points</th>
                <th className="text-left px-3" >Tie Points</th>
                <th className="md:py-5 text-left px-3 ">Settled</th>
                <th className="text-left px-3">Action</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
          

            {TieList[r].map((data, index) => (
                        <tr className="capitalize">
                          <td className="px-1 ">{index + 1}</td>
                          <td className=' capitalize'>
                            {data?.player?.user?.firstName}{" "}
                            {data?.player?.user?.lastName}
                          </td>
                          <td className=' capitalize'>
                            {data?.player?.mate?.firstName??"-"}{" "}
                            {data?.player?.mate?.lastName??""}
                          </td>
                          <td>{data?.league?.name}</td>
                          <td>{data?.division?.code}</td>
                          <td>{data?.level?.name}</td>
                          <td>{data?.win}</td>
                          <td>{data?.loss}</td>
                          <td>{data?.totalPoints}</td>
        
                          <td>{data?.score === "" ? "-" : data?.score}</td>
        
                          <td><label
        
                              className={`btn capitalize ${
                                !data?.isWinnerGetted 
                                  ? "bg-warning hover:bg-warning"
                                  : " bg-color_theme hover:bg-color_theme"
                              }  text-[#000] border-hidden cursor-default btn-xs`}
                            >
                              {data?.isWinnerGetted ? "Yes" : "No"}
                            </label></td>
                          <td className="cursor-pointer">
                            <span className="tooltip" data-tip="View Tie">
                            <Link
                              to={`/divisionmanagement/matchlist/${
                                data?.division._id
                              }/${data?.league._id}/${data?.division.code}/${
                                data?.league?.name
                              }/${data?.level?._id}/${data?.level?.name}`}
                            >
                              <MdRemoveRedEye />
                            </Link>
                            </span>
                          </td>
                        </tr>
                      ))}
              

            </tbody>
          </table>
        </AccordionBody>
      </Accordion>
   
        
      
                     
                     
                    </>
                  ))
                }
</> 

}
            

              

              {nolist && (
                <div className="flex justify-center">
                  <td  className="my-10 text-center">
                    <MdSportsTennis className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">
                      No Tie-Breaker Found
                    </p>
                  </td>
                </div>
              )}
            {/* </tbody>
          </table> */}
          <input type="checkbox" id="couponstatus" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="couponstatus"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status.
              </h3>
              {/* <div className="modal-action justify-center">
                <label
                  htmlFor="couponstatus"
                  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                  onClick={() => statusChange()}
                >
                  Yes
                </label>
                <label
                  htmlFor="couponstatus"
                  className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                >
                  No
                </label>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Setteled;
