import React, { useState } from "react";
import {RxPencil1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { MdAdd, MdLocationCity, MdOutlineDelete } from "react-icons/md";
import { observer } from "mobx-react";
import Filter from "./CityFilter/filter";
import ReactPaginate from 'react-paginate';

import { useCityStore,CityStore,CityStoreContext  } from "./store/CityStore";
import { useAuthStore } from "../Auth/store/Auth";
import { useEffect } from "react";

const CityManagement = () => {
  const store = new CityStore({
    isloading:true,
    editloading:false
  });
  return(
    <CityStoreContext.Provider value={store}>

      <Screen/>
    </CityStoreContext.Provider>
  )
}
export default CityManagement;


const Screen = observer(() => {
  const {
    city,
    loadcity,
    nolist,
    Citypopup,
    CityLoader,
    setcitypopup,
    statusChange,
    setstatuspopup,
    getid,
    getstatus,
    deletefunction,
    loadstatus,
    getToken
  } = useCityStore();
  const { adminAuthApproved } = useAuthStore();
  
  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }
  
  }, [adminAuthApproved]);
  
  const [currentPage, setCurrentPage] = useState(0);
  const ITEMS_PER_PAGE = 50;
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
    
 
  };

  const handleClick=()=>{
    const element = document.getElementById("tablescroll");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
  const startIndex = currentPage * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentCity = city?.slice(startIndex, endIndex);

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">City Management</p>
          {/* <p className=" opacity-80 text-sm">Home / City</p> */}
        </div>
        <div>
          <Link
            to="./addcity"
            className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
          >
            <MdAdd />
            Add City
          </Link>
        </div>
      </div>
      <Filter/>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
          <table id="tablescroll" className="table table-zebra w-full">
            <thead>
              <tr>
                <th>#</th>
                <th>City Name</th>
                <th>State Name</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody className="text-sm relative">
              {loadcity && 
              <>
              <tr>
                <td colSpan={5} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={5} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={5} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={5} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={5} className="animate-pulse py-6"></td>
              </tr>
              </>
              }
              {
             (currentCity.length === 0  ? city : currentCity )?.map((data, index) => (
                <tr key={index} className="capitalize">
                  <td>
                  {currentPage * ITEMS_PER_PAGE + index+1}
                  </td>
                  <td>
                    {data?.city_name ?? "N/A"}
                  </td>
                  <td>
                    {data?.state?.name ?? "N/A"}
                  </td>
                  {/* <td>
                    USA
                    {data?.country ?? "USA"}
                  </td> */}
                  <th>
                    <label
                      title="Change Status"
                      htmlFor="my-modal-5"
                      className={`btn capitalize ${data?.isActive ? "bg-color_theme hover:bg-color_theme" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                      onClick={(e) => {
                        getid(data?._id);
                        setcitypopup(true);
                        getstatus(data?.isActive);
                      }}
                    >
                      {data?.isActive ? "Active" : "Inactive"}
                    </label>
                  </th>
                  <td className=" text-base">
                    <div className="flex items-center space-x-4">
                      {/* <label
                        htmlFor="deletecity"
                        className=" text-[#d02c2c] cursor-pointer"
                        title="Delete"
                        onClick={(e) => {getid(data?._id)}}
                      >
                        <MdOutlineDelete />
                      </label> */}
                      <button title="Edit">
                        <Link to={`/citymanagement/${data?._id}`}>
                            <RxPencil1 />
                        </Link>
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            }
            
              {nolist && 
              <tr>
                <td colSpan={5} className="my-10 text-center">
                  <MdLocationCity className=" text-[#dedede] text-8xl mx-auto" />
                  <p className=" font-semibold text-lg">No City Found</p>
                  <Link
                    to="./addcity"
                    className="btn btn-sm mt-4 bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                  >
                    <MdAdd />
                    Add City
                  </Link>
                </td>
              </tr>}
            </tbody>
          </table>
          {!city?.length == 0 && 
          
          <ReactPaginate
        initialPage={0}
        pageCount={Math.ceil(city?.length / ITEMS_PER_PAGE)}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        onPageChange={handlePageChange}
        onClick={handleClick}
        containerClassName={'pagination'}
        activeClassName={'active'}
      
        />
      }

          {/* status change model start */}
          {/* <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status ?
              </h3>
              <div className="modal-action justify-center">
                      <label
                        htmlFor="my-modal-5"
                        className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                        onClick={() => statusChange()}
                      >
                        Yes
                      </label> 
                <label htmlFor="my-modal-5" className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                  No
                </label>
              </div>
            </div>
          </div> */}
          {/* status change model end */}

          {/* city delete model start
          <input type="checkbox" id="deletecity" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="deletecity"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to Delete this City
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="deletecity"
                  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                  onClick={() => deletefunction()}
                >
                  Yes
                </label>
                <label htmlFor="deletecity" className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                  No
                </label>
              </div>
            </div>
          </div> */}
          
        {Citypopup && 
        <>
          <input type="checkbox" id="my-modal-5" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
         
                className="btn btn-sm btn-circle absolute right-2 top-2"
                onClick={()=>setcitypopup(false)}
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status ?
              </h3>
              <div className="modal-action justify-center">
                {!CityLoader ?        <label
           
                  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                  onClick={() => statusChange()}
                >
                  Yes
                </label> :   
                <button
                            // type="submit"
                            disabled
                            className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                            // onClick={() => {
                            //   PostMatchscore();
                            // }}
                          >
                            Yes..
                            <svg
                              className="animate-spin mr-1 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </button> }
         
                          <label
            onClick={()=>setcitypopup(false)}
              // htmlFor="voidstatus"
              className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
            >
              No
            </label>
              </div>
            </div>
          </div>
        
        </>
       
}
          {/* city delete model end */}
        </div>
      </div>
    </>
  );
});


