import React, { useState } from "react";
import { RxPencil1 } from "react-icons/rx";
import { Link, useParams } from "react-router-dom";
import { MdAdd, MdSectioningWatermark, MdLocationCity, MdOutlineDelete, MdOutlineGroup, MdCancel } from "react-icons/md";
import { observer } from "mobx-react";
import Filter from "./SectionsFilter/Filter";
import ReactPaginate from 'react-paginate';
import { SectionStore, useSectionStore, SectionStoreContext } from "./store/Sectionstore";
import { useAuthStore } from "../Auth/store/Auth";
import { useEffect } from "react";
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import { toast, ToastContainer } from "react-toastify";

const SectionProduct = () => {
    const { id } = useParams();

    const store = new SectionStore({
        Productlist: true,
        id: id
    });
    return (
        <SectionStoreContext.Provider value={store}>
            <Screen />
        </SectionStoreContext.Provider>
    )
}
export default SectionProduct;


const Screen = observer(() => {
    const {
        ProductCatalog,
        nolist,
        SectionLoader,
        Productlist,
        DuplicateProduct,
        savedfun,
        deleteProductSection,
        setSectionpopup,
        addProductSection,
        Sectionpopup,
        deletepopup,
        setdeletepopup,
        setdelateLoader,
        delateLoader,
        statusChange,
        deletefunction,
        getToken
    } = useSectionStore();

    const { adminAuthApproved, admin_permissions } = useAuthStore();
    const { id, name } = useParams();
    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);

    const [searchQuery, setSearchQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [showList, setShowList] = useState(false);
    const [isListVisible, setListVisibility] = useState(false);
    const [value, setValue] = useState("Select option...");
    const [categoryvalue, setcategoryvalue] = useState("Select option...");

    const handleFocus = () => {
        // Set the list visibility to true when the input is focused
        setListVisibility(true);
        setShowList(true);

        // Initial filtering to show the entire list
        setFilteredItems(ProductCatalog.map(item => item));
    };

    const handleBlur = () => {
        // Set the list visibility to false when the input loses focus
        setListVisibility(false);
        setShowList(true);

        // setFilteredItems(productList.map(item => item));

    };

    const handleSearch = (query) => {
        const filtered = ProductCatalog.map(item => item).filter((item) =>
            item.product.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredItems(filtered);
        setSearchQuery(query);
        setShowList(true);
    };
    const handleSelect = (item, Product_id) => {
        addProductSection(id, Product_id)
            .then(() => {
                setSelectedOptions(prevOptions => [...prevOptions, item]);

            })
            .catch(error => {
                // Handle the error if needed
                console.error("Error in addProductSection:", error);
            });

        // Additional code...
        setSearchQuery('');
        setFilteredItems([]);
        setShowList(false);
    };
    const handleRemove = (index) => {
        const updatedOptions = [...selectedOptions];
        // const updatedRatingOptions = [...rating_categories_id];

        updatedOptions.splice(index, 1);
        // updatedRatingOptions.splice(index, 1);
        setSelectedOptions(updatedOptions);
        // setrating_categories_id(updatedRatingOptions)

    };




    return (
        <>
            <div className="flex justify-between">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">{name} Section </p>
                    <p className=" opacity-80 text-sm">Home / Section / Product list </p>
                </div>
                <div>
                    <Link
                        to="../sections"
                        className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                        Back
                    </Link>
                </div>
            </div>
            {/* <Filter /> */}

            <div className="flex space-x-3">
                <div className="card bg-base-100  w-1/2 shadow-md p-6 mt-8">
                    <div className="overflow-x-auto w-full">
                        <div className="flex justify-center">
                            <div className=" mb-4">
                                <p className=" text-xl font-semibold"> Product List </p>
                            </div>
                        </div>
                        <table id="tablescroll" className="table table-zebra w-full">
                            <thead>
                                <tr>
                                    {/* <th></th> */}


                                    <th>ID</th>
                                    <th>Product Name</th>
                                    <th>Action</th>


                                </tr>
                            </thead>

                            <tbody className="text-sm relative">
                                {SectionLoader &&
                                    <>
                                        <tr>
                                            <td colSpan={3} className="animate-pulse py-6"></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3} className="animate-pulse py-6"></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3} className="animate-pulse py-6"></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3} className="animate-pulse py-6"></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3} className="animate-pulse py-6"></td>
                                        </tr>
                                    </>
                                }
                                {
                                    Productlist?.map((data, index) => (
                                        <tr key={index} className="capitalize">

                                            <td>
                                                {index + 1}
                                            </td>

                                            <td>
                                                {data.product}
                                            </td>
                                            <td>
                                                <label
                                                    htmlFor="deleteproduct"
                                                    className=" text-[#d02c2c] cursor-pointer"
                                                    title="Delete"
                                                    onClick={() => deleteProductSection(data?.record_id, id)}
                                                >
                                                    <MdOutlineDelete />
                                                </label>
                                            </td>

                                        </tr>
                                    ))
                                }
                            
                            </tbody>
                        </table>
                        {nolist &&
                                        <div colSpan={2} className="my-10 text-center justify-center flex flex-col ">
                                            <MdOutlineGroup className=" text-[#dedede] text-8xl mx-auto" />
                                            <p className=" font-semibold text-lg">No Product Added</p>

                                        </div>
                                    }


                        {deletepopup &&
                            <>
                                <input type="checkbox" id="deletecity" className="modal-toggle" />
                                <div className="modal">
                                    <div className="modal-box relative">
                                        <label

                                            className="btn btn-sm btn-circle absolute right-2 top-2"
                                            onClick={() => { setdelateLoader(false); setdeletepopup(false) }}
                                        >
                                            ✕
                                        </label>
                                        <h3 className="text-lg font-bold text-center">
                                            Are you sure you want to Delete this Section
                                        </h3>
                                        <div className="modal-action justify-center">
                                            {/* <label
                                            htmlFor="deletecity"
                                            className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                            onClick={() => deletefunction()}
                                        >
                                            Yes
                                        </label> */}
                                            {!delateLoader ?
                                                <label

                                                    className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                                    onClick={() => deletefunction()}
                                                >
                                                    Yes
                                                </label> :
                                                <button
                                                    // type="submit"
                                                    disabled
                                                    className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                                                // onClick={() => {
                                                //   PostMatchscore();
                                                // }}
                                                >
                                                    Yes..
                                                    <svg
                                                        className="animate-spin mr-1 h-5 w-5 text-white"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            className="opacity-25"
                                                            cx="12"
                                                            cy="12"
                                                            r="10"
                                                            stroke="currentColor"
                                                            strokeWidth="4"
                                                        ></circle>
                                                        <path
                                                            className="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                        ></path>
                                                    </svg>
                                                </button>}
                                            <label
                                                onClick={() => setdeletepopup(false)}
                                                className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </>

                        }
                        {Sectionpopup &&
                            <>
                                <input type="checkbox" id="my-modal-5" className="modal-toggle" />
                                <div className="modal">
                                    <div className="modal-box relative">
                                        <label

                                            className="btn btn-sm btn-circle absolute right-2 top-2"
                                            onClick={() => setSectionpopup(false)}
                                        >
                                            ✕
                                        </label>
                                        <h3 className="text-lg font-bold text-center">
                                            Are you sure you want to change Status ?
                                        </h3>
                                        <div className="modal-action justify-center">
                                            {!SectionLoader ? <label

                                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                                onClick={() => statusChange()}
                                            >
                                                Yes
                                            </label> :
                                                <button
                                                    // type="submit"
                                                    disabled
                                                    className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                                                // onClick={() => {
                                                //   PostMatchscore();
                                                // }}
                                                >
                                                    Yes..
                                                    <svg
                                                        className="animate-spin mr-1 h-5 w-5 text-white"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            className="opacity-25"
                                                            cx="12"
                                                            cy="12"
                                                            r="10"
                                                            stroke="currentColor"
                                                            strokeWidth="4"
                                                        ></circle>
                                                        <path
                                                            className="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                        ></path>
                                                    </svg>
                                                </button>}

                                            <label
                                                onClick={() => setSectionpopup(false)}
                                                // htmlFor="voidstatus"
                                                className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                                            >
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </>

                        }
                    </div>
                </div>

                <div className="card bg-base-100 w-1/2 shadow-md p-6 mt-8">
                    <div className="overflow-x-auto w-full">
                        <div className="flex justify-center mb-4">
                            <div className="mb-4">
                                <p className="text-xl font-semibold"> Add Product </p>
                            </div>
                        </div>

                        <div className="relative">
                            <input
                                type="text"
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={searchQuery}
                                onChange={(e) => handleSearch(e.target.value)}
                                className="p-3 pl-8 rounded w-full border border-gray-200 focus:bg-white focus:outline-none"
                                placeholder="Type to search..."
                            />
                            <svg
                                className="w-4 h-4 absolute left-2.5 top-3.5"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                />
                            </svg>
                        </div>
                        <div className="flex flex-wrap mt-1 gap-2">
                            {selectedOptions.map((option, index) => (
                                <div
                                    key={index}
                                    className="bg-blue-100 p-1 rounded flex items-center"
                                >
                                    {option}
                                    {/* <button
                                        type="button"
                                        className="ml-2 text-red-500 hover:text-red-700"
                                        onClick={() => handleRemove(index)}
                                    >
                                        <MdCancel />
                                    </button> */}
                                </div>
                            ))}
                        </div>

                        {showList && (
                            <div className="list-container mt-2 border border-gray-100 overflow-y-auto max-h-40">
                                <ul className="bg-white">
                                    {filteredItems.map((item, index) => (
                                        <li
                                            key={index}
                                            onClick={() => handleSelect(item.product || item, item.record_id)}
                                            className="pl-8 pr-2 py-1 border-b-2 border-gray-100 relative cursor-pointer hover:bg-yellow-50 hover:text-gray-900"
                                        >
                                            {`${item.product || item}`}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>

        </>
    );
});

