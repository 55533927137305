import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { Input } from "react-daisyui";
import {
  useHomecourtStore,
  HomecourtStore,
  HomecourtStoreContext,
} from "./store/HomecourtStore";
import { MdOutlineCheck, MdOutlineClose } from "react-icons/md";
import { usePlacesWidget } from "react-google-autocomplete";
import { useState } from "react";
import axios from "axios";
import { useAuthStore } from "../Auth/store/Auth";

const EditHomecourtManagement = observer(() => {
  const { id } = useParams();
  return (
    <HomecourtStoreContext.Provider
      value={
        new HomecourtStore({
          loadeditcitybol: false,
          editloading: true,
          slectcity: true,
          id: id,
        })
      }
    >
      <Screen />
    </HomecourtStoreContext.Provider>
  );
});

export default EditHomecourtManagement;
const Screen = observer(() => {
  const {
    saved,
    updatedcityname,
    getHomename,
    getzipcode,
    updatecity,
    getstatus,
    getlatitude,
    getlongitude,
    apikeyss,
    homename,
    verify,
    valueverify,
    newcityname,
    zipcode,
    status,
    citydrop,
    editaddress,
    editlatitude,
    editlongitude,
    editaddressed,
    editlongitudeed,
    editlatitudeed,
    getStorezipcode,
    Verifiedstatus,
    getverify,
    addloadhomecourt,
    suggest,
    setstatuspopup,
    statuspopup,
    updatecourtnickname,
    updatedCourtNickname,
    getToken
  } = useHomecourtStore();
  const { adminAuthApproved } = useAuthStore();
  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }
  
  }, [adminAuthApproved]);
  const { id } = useParams();
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [myaddres, setaddress] = useState(null);
  const [HomeCourtValid, setHomeCourtValid] = useState();
  const [zipCode, setzipcode] = useState(null);
  const [charCheck, set3charCheck] = useState();

  const navigate = useNavigate();
  useEffect(() => {
    if (saved) {
      navigate("../homecourtmanagement");
    }
  }, [saved]);


  const AddressCheck =async (address) => {
    // Perform address validation
    await axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apikeyss}`
      )
      .then((response) => {
        const { status, results } = response.data;
        set3charCheck(false);

        if (status === "OK" && results.length > 5) {
          setHomeCourtValid(false);


        } else {
          // Address is invalid
          console.error("Error while geocoding:", "error");
          setHomeCourtValid(true);

          // Handle the invalid address as needed
        }
      })
      .catch((error) => {
        console.error("Error while geocoding:", error);
        setHomeCourtValid(true);

      });
  };







  const handlePlaceSelected = async (lat, lng) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apikeyss}`
      );

      const {status, results } = response.data;
      if (status === "OK" && results.length > 5) {
        setHomeCourtValid(false);
        set3charCheck(false);


      } else{
        setHomeCourtValid(true);

      }
      let zipCode = "";
      let cityName = "";

      for (let i = 0; i < results.length; i++) {
        const addressComponents = results[i].address_components;
        for (let j = 0; j < addressComponents.length; j++) {
          const component = addressComponents[j];
          if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
          }
          if (component.types.includes("locality")) {
            cityName = component.long_name;
          }
          if (zipCode && cityName) {
            break;
          }
        }
        if (zipCode && cityName) {
          break;
        }
      }

      setzipcode(zipCode);
      getStorezipcode(zipCode)

     

      // You can use the zipCode and cityName variables as per your requirements
    } catch (error) {
      console.error("Error fetching zip code:", error);
    }
  };

if(zipCode){
  getStorezipcode(zipCode)

}



  const { ref } = usePlacesWidget({
    apiKey: apikeyss,
    onPlaceSelected: (place) => {
  
      getlatitude(place?.geometry?.location.lat());
      getlongitude(place?.geometry?.location.lng());
      setaddress(place?.formatted_address);
      setLat(place?.geometry?.location.lat());
      setLng(place?.geometry?.location.lng());
      handlePlaceSelected(
        place?.geometry.location.lat(),
        place?.geometry.location.lng()
      );
  
    },
    options: {
      types: ["geocode", "establishment"],
    },
  });

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Edit Court</p>
          {/* <p className=" opacity-80 text-sm">Home /City/ Edit</p> */}
        </div>
        <div>
          <Link
            to="../homecourtmanagement"
            className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <form
          onSubmit={(e) => {
            if(verify){
              updatecity(id)
            }
          
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
          <div>
              <label
                htmlFor="CourtNickname"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Court Nickname<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <input
                name="CourtNickname"
                required
                onChange={(e) => updatedCourtNickname(e.target.value)}
                value={updatecourtnickname}
                placeholder="Court Nickname"
                id="CourtNickname"
                type="text"
                autoComplete="off"
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>
            <div>
              <label
                htmlFor="CourtName"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Court Name<span className="text-[#f00]">&nbsp;*</span>
              </label>
              {lat === null ? (
                <Input
                  name="Address"
                  required
                  value={homename}
                  onChange={(e) => getHomename(e.target.value)}
                  onChangeCapture={(e) => {
                    const inputValue = e.target.value;
                    if ( inputValue.length  <= 3 && inputValue.length > 0 ){
                      set3charCheck(true);
                    }
                if (e.target.value !== ref?.current?.value) return;
                inputValue.length > 3 &&
                  AddressCheck(e.target.value)
              }}
             
                  placeholder="Address"
                  id="Address"
                  type="text"
                  autoComplete="off"
                  ref={ref}
                  className="focus:outline-0 w-full text-sm font-medium rounded-md"
                />
              ) : (
                <Input
                  onChange={getHomename(ref?.current?.value)}
                  
                  onChangeCapture={(e) => {
                    if (e.target.value !== ref?.current?.value) return;
                    AddressCheck(e.target.value)
                  }}
                  name="Address"
                  type="text"
                  required
                  placeholder="Address"
                  id="Address"
                  autoComplete="off"
                  className="focus:outline-0 w-full text-sm font-medium rounded-md"
                  ref={ref}
                  defaultValue=""
                />
              )}
              {/* <input
                name="CourtName"
                required
                onChange={(e) => getHomename(e.target.value)}
                value={homename}
                placeholder="Court Name"
                id="CourtName"
                type="text"
                autoComplete="off"
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              /> */}
                   {HomeCourtValid  === true || charCheck === true  ?  
                <span className=" text-[red] text-xs">
                          Please Provide Valid Home Court
                </span>
                :
                ""
              }
            </div>
            <div>
              <label
                htmlFor="Address"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Court Address<span className="text-[#f00]">&nbsp;*</span>
              </label>
               <input
                name="CourtName"
                required
                readOnly
                onChange={ myaddres === null ? (e) => editaddressed(e.target.value) :  editaddressed(myaddres) }
                value={myaddres === null ? editaddress : myaddres  }
                placeholder="Court Name"
                id="CourtName"
                type="text"
                autoComplete="off"
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
              {/* {lat === null ? (
                <Input
                  name="Address"
                  required
                  onChange={(e) => editaddressed(e.target.value)}
                  value={editaddress}
                  placeholder="Address"
                  id="Address"
                  type="text"
                  autoComplete="off"
                  ref={ref}
                  className="focus:outline-0 w-full text-sm font-medium rounded-md"
                />
              ) : (
                <Input
                  onChange={editaddressed(ref?.current?.value)}
                  name="Address"
                  type="text"
                  required
                  placeholder="Address"
                  id="Address"
                  autoComplete="off"
                  className="focus:outline-0 w-full text-sm font-medium rounded-md"
                  ref={ref}
                  defaultValue=""
                />
              )} */}
            </div>

            <div>
              <label
                htmlFor="City"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                City<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                value={newcityname?._id}
                id="city"
                name="city"
                onChange={(e) => updatedcityname(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                {citydrop.map((data, index) => (
                  <option value={data?._id} key={index}>
                    {data?.city_name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="Zipcode"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Zip Code<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <input
                name="Zipcode"
                required
                readOnly
                value={zipCode === null ? zipcode : zipCode}
                // onChange={(e) => getzipcode(zipCode === null ? zipCode : zipCode)}
                placeholder="Zip Code"
                id="Zipcode"
                type="text"
                maxLength={5}
                autoComplete="off"
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>
            <div>
              <label
                htmlFor="Country"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Country<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                id="Country"
                name="Country"
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="USA">USA</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="status"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Status<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                value={status}
                onChange={(e) => {
                  getstatus(e.target.value);
                }}
                name="Status"
                className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="Latitude"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Latitude<span className="text-[#f00]">&nbsp;*</span>
              </label>
              {lat === null ?         <Input
                name="Latitude"
                required
                onChange={
               
                 (e) => editlatitudeed(e.target?.value)
                }
                value={editlatitude}
                placeholder="Latitude"
                id="Latitude"
                readOnly
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />:
              <Input
              name="Latitude"
              required
              readOnly
              onChange={ editlatitudeed(lat) }
              value={lat}
              placeholder="Latitude"
              id="Latitude"
              type="text"
              autoComplete="off"
              className="focus:outline-0 w-full text-sm font-medium rounded-md"
            /> }
      
            </div>
            <div>
              <label
                htmlFor="Longitude"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Longitude<span className="text-[#f00]">&nbsp;*</span>
              </label>
              {lng === null ?  <Input
                name="Longitude"
                required
                onChange={
                  (e) => editlongitudeed(e.target.value)
                }
                value={editlongitude}
                readOnly
                placeholder="Longitude"
                id="Longitude"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />:  <Input
              name="Longitude"
              required
              readOnly
              onChange={editlongitudeed(lng)  }
              value={lng}
              placeholder="Longitude"
              id="Longitude"
              type="text"
              autoComplete="off"
              className="focus:outline-0 w-full text-sm font-medium rounded-md"
            /> }
            
            </div>
            <div>
                <label
                  htmlFor="approve"
                  className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                >
                  Added By User<span className="text-[#f00]">&nbsp;*</span>
                </label>
                <Input
              name="Addedbyuser"
              // required
              readOnly
              // onChange={ editlatitudeed(lat) }
              value={suggest === null ? "No" : "Yes"}
              placeholder=" Added By User"
              id=" Addedbyuser"
              type="text"
              autoComplete="off"
              className="focus:outline-0 w-full text-sm font-medium rounded-md"
            />
                {/* <select
                  defaultValue={suggest === null ? "No" : "Yes"}
                  // onChange={(e) => {
                  //   getverify(e.target.value);
                  // }}
                  name="Verify"
                  className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                >
                </select> */}
              </div>
              
        
            {!verify &&
              <div>
                <label
                  htmlFor="approve"
                  className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                >
                  Admin Approved<span className="text-[#f00]">&nbsp;*</span>
                </label>
                <Input
                  value={valueverify ==true ?"Yes":"No"}
                  readOnly
                  // onChange={(e) => {
                  //   getverify(e.target.value);
                  // }}

                  name="Verify"
                  className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                >
                  {/* <option value="true">Yes</option>
                  <option value="false">No</option> */}
                </Input>
              </div>
            }
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
            <Link
              to="../homecourtmanagement"
              className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
            >
              <MdOutlineClose className="mr-1" />
              Cancel
            </Link>
            {!addloadhomecourt? 
           ( !verify ?
            ( updatecourtnickname== null || updatecourtnickname== ""  ?
              <button type="submit" disabled ={ HomeCourtValid === true || charCheck === true ? true : false }  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
            <MdOutlineCheck className="mr-1" />
            Update
          </button >
          :
          <label  onClick={(e)=>setstatuspopup(true)} htmlFor="verified" title="verified" disabled ={HomeCourtValid === true || charCheck === true ? true : false} className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
          <MdOutlineCheck className="mr-1" />
          Update
          </label >
            )
          //   <label  onClick={(e)=>setstatuspopup(true)} htmlFor="verified" title="verified" disabled ={HomeCourtValid === true || charCheck === true ? true : false} className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
          //   <MdOutlineCheck className="mr-1" />
          //   Update
          // </label >
          :
          <button type="submit" disabled ={ HomeCourtValid === true || charCheck === true ? true : false }  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
            <MdOutlineCheck className="mr-1" />
            Update
          </button >
              )
             
             :
             <button 
                            className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                             type="button"
                           //  onClick={()=> changeStep("4")}
                           >
                           <svg
                               className="animate-spin mr-1 h-5 w-5 text-white"
                               xmlns="http://www.w3.org/2000/svg"
                               fill="none"
                               viewBox="0 0 24 24"
                               >
                               <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                               <path
                                   className="opacity-75"
                                   fill="currentColor"
                                   d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                               ></path>
                           </svg>
                             Loading..
                           </button>
            }
          
          </div>

          {/* approve hc popup */}
          {statuspopup && (
            <>
             <input type="checkbox" id="verified" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="verified"
                className="btn btn-sm btn-circle absolute right-2 top-2"
                 onClick={() => setstatuspopup(false)}
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
              Do you also want to approve this court to make it publically available?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="verified"
                  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                  onClick={() =>   updatecity(id,true)}
                >
                  Yes
                </label>
                <label
                  htmlFor="verified"
                  className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                  onClick={() => updatecity(id,false)}
                >
                  No
                </label>
              </div>
            </div>
          </div>
            </>)}
         
        </form>
      </div>
    </>
  );
});
