import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    MdOutlineClose,
    MdOutlineCheck,
    MdOutlineRefresh,
    MdOutlineSearch,
} from "react-icons/md";
import { Input, Button } from "react-daisyui";
// import { useCityStore } from "../store/CityStore";
import { observer } from "mobx-react";
import { useProductCatalogStore } from "../Store/ProductCatalogStore"; 


const Filter = observer(() => {
    const { SetfilterSearch, getFilterProductCatalog , allclear} = useProductCatalogStore();

    return (
        <>
            <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
                <form
                    onSubmit={(e) => {
                        getFilterProductCatalog();
                        e.preventDefault();
                    }}
                    className=" flex flex-row justify-between  "

                >
                    <div className="w-6/12">
                        <div>

                            <input
                                name="Productname"
                                onChange={(e) => SetfilterSearch(e.target.value)}
                                placeholder="Search By Product name"
                                id="Productname"
                                type="text"
                                autoComplete="off"
                                className="input focus:outline-0 w-full text-sm font-medium rounded-md capitalize focus:outline-offset-0 input-bordered"
                            />
                        </div>

                    </div>
                    <div >
                        <button
                            type="reset"
                            onClick={() => {
                                allclear();
                                // getSectionList();
                            }}
                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                        >
                            <MdOutlineRefresh className="mr-1" />
                            Reset
                        </button>
                        <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                            <MdOutlineSearch className="mr-1" />
                            Search
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
});
export default Filter;
