import React, { useEffect, useRef, useState } from "react";

import { useProductCatalogStore } from "../../ProductCatalog/Store/ProductCatalogStore";
import { observer } from "mobx-react";
import { autorun } from "mobx";

const SearchableDropdown = observer((List, listingOff) => {
    const { setparentId, setproductcatgory, setBrandName, setshop, setCategory, productDetail, shop } = useProductCatalogStore();

    const [query, setQuery] = useState("");

    const [querycategory, setQuerycategory] = useState("");

    const [isOpen, setIsOpen] = useState(false);
    const [HideShow, setHideShow] = useState(false);
    const [value, setValue] = useState("");
    const [check, setcheck] = useState("");
    const [Shops, setShops] = useState(check);

    const [categoryvalue, setcategoryvalue] = useState("Select option...");
    const inputRef = useRef(null);
    console.log("queryyyyyyyyyyyyyyyyyyyyyyyyyy", querycategory, "productDetail?.category_name ", productDetail?.brand_name);
    useEffect(() => {
        // Define a function to update logofile based on the store data
        const updateLogofile = () => {
            if (productDetail?.brand_name) {
                // Set logofile state with default images
                setQuery(productDetail?.brand_name);
            }
        };

        // Call updateLogofile when the store data changes
        const disposer = autorun(updateLogofile);

        // Cleanup function
        return () => {
            disposer(); // Dispose the autorun when the component unmounts
        };
    }, [productDetail]);
    useEffect(() => {
        // Define a function to update logofile based on the store data
        const updateLogofile = () => {
            if (productDetail?.shop_name) {
                // Set logofile state with default images
                setShops(productDetail?.shop_name);
            }
        };

        // Call updateLogofile when the store data changes
        const disposer = autorun(updateLogofile);

        // Cleanup function
        return () => {
            disposer(); // Dispose the autorun when the component unmounts
        };
    }, [productDetail]);



    useEffect(() => {
        // Define a function to update logofile based on the store data
        const updateLogofile = () => {
            if (productDetail?.category_name) {
                // Set logofile state with default images
                setQuerycategory(productDetail?.category_name);
            }
        };

        // Call updateLogofile when the store data changes
        const disposer = autorun(updateLogofile);

        // Cleanup function
        return () => {
            disposer(); // Dispose the autorun when the component unmounts
        };
    }, [productDetail]);
    useEffect(() => {
        document.addEventListener("click", toggleCategory, toggleBrand);
        return () => document.removeEventListener("click", toggleCategory, toggleBrand);
    }, []);

    const selectOption = (option, type) => {

        setparentId(option?.id);

        if (type == "shop") {
            setIsOpen((isOpen) => !isOpen);
            setshop(option.id);
            setcheck(option?.shop_name);
        }

        if (type == "brand") {
            setQuery(() => "");
            setValue(option['name']);
            console.log("Mybrandddddddddddd", option);

            setcategoryvalue(`${">".repeat(option.level)} ${option["name"]}`
            );
            setproductcatgory(option.name);

            setBrandName(option.id);
            setIsOpen((isOpen) => !isOpen);

        }
        if (type == "category") {
            setQuerycategory(() => "");

            setCategory(option.id);
            setValue(option['name']);
            setproductcatgory(option.name);
            setcategoryvalue(`${">".repeat(option.level)} ${option["name"]}`
            );
            setIsOpen((isOpen) => !isOpen);
        }

    };

    function toggleBrand(e) {
        setIsOpen((isOpen) => !isOpen);

        if (value == "" && value == query) {
            setQuery(() => "");
        }
    }

    function toggleCategory(e) {
        setIsOpen(e && e.target === inputRef.current);
        if (value == "" && value == querycategory ) {
            // setQuerycategory(() => "");
        }
    }

    function toggleShop(e) {
        setIsOpen(e && e.target === inputRef.current);
        // if (value == "" && value == Shops) {
        //     setShops(() => "");
        // }
    }

    const getDisplayValue = () => {
        if (query, querycategory) return (query, querycategory);
        if (value) return value;

        return "";
    };

    const filter = (options) => {
        return options?.filter(
            (option) => option['name'].toLowerCase().indexOf(querycategory?.toLowerCase()) > -1
        );
    };
    const filter1 = (options) => {
        return options?.filter(
            (option) => option['name'].toLowerCase().indexOf(query?.toLowerCase()) > -1
        );
    };
    const filter2 = (options) => {
        return options?.filter(
            (option) => option['shop_name'].toLowerCase().indexOf(Shops?.toLowerCase()) > -1
        );
    };

    console.log("raj ===============>>>>>>>>>>>>>>>>>", check   );
    return (
        <div className="dropdowncategory">
            <div className="control">
                <div className="selected-value">
                    <div
                        onClick={() => setHideShow(!HideShow)}

                        className=" p-3 border-[1px] border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md">
                        {check || categoryvalue}
                    </div>

                    {/* {HideShow && */}
                    <div class="relative">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                        </div>
                        {List.listingOff == "brand" &&
                            <input
                                ref={inputRef}
                                required
                                class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                type="text"
                                placeholder="Search..."
                                defaultValue={query || value}
                                name="searchTerm"
                                onChange={(e) => {
                                    setQuery(e.target.value);
                                    // setValue(null);
                                }}
                                onClick={toggleBrand}
                            />
                        }

                        {
                            List.listingOff == "categories" &&
                            <input
                                ref={inputRef}
                                required
                                class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                type="text"
                                placeholder="Search..."
                                defaultValue={querycategory || value}
                                name="searchTerm"
                                onChange={(e) => {
                                    setQuerycategory(e.target.value);
                                    // setValue(null);
                                }}
                                onClick={toggleCategory}
                            />
                        }




                        {List.listingOff == "shop" &&
                            <input
                                ref={inputRef}
                                required
                                class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                type="text"
                                placeholder="Search..."
                                defaultValue={Shops || check }
                                value={Shops || check}
                                name="searchTerm"
                                onChange={(e) => {
                                    setShops(e.target.value);
                                    // setValue(null);
                                }}
                                onClick={toggleShop}
                            />

                        }

                    </div>


                    {/* } */}


                </div>

                <div className={`arrow ${isOpen ? "open" : ""}`}></div>
            </div>

            <div className={`options ${isOpen ? "open" : ""}`}>
                {List.listingOff == "brand" &&
                    filter1(List.List).map((option, index) => (
                        <div
                            onClick={() => selectOption(option, "brand")}
                            className={`option ${option["name"] === value ? "selected" : ""}`}
                            key={`${"id"}-${index}`}
                        >
                            {`${">".repeat(option.level)} ${option["name"]}`}
                        </div>
                    ))
                }
                {List.listingOff == "categories" &&
                    filter(List.List).map((option, index) => (
                        <div
                            onClick={() => selectOption(option, "category")}
                            className={`option ${option["name"] === value ? "selected" : ""}`}
                            key={`${"id"}-${index}`}
                        >
                            {`${">".repeat(option.level)} ${option["name"]}`}
                        </div>
                    ))
                }
                {List.listingOff == "shop" &&
                    filter2(List.List).map((option, index) => (
                        <div
                            onClick={() => selectOption(option, "shop")}
                            className={`option ${option["shop_name"] === value ? "selected" : ""}`}
                            key={`${"id"}-${index}`}
                        >
                            {`${">".repeat(option.level)} ${option["shop_name"]}`}
                        </div>
                    ))
                }


            </div>
        </div>
    );
})



export default SearchableDropdown;
