import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { MdFlag, MdRemoveRedEye, MdSportsHandball, MdSportsTennis } from "react-icons/md";
import { RxPencil1 } from "react-icons/rx";
import Filter from "./DivisonFilter/Filter";
import {
  useDivisionStore,
  DivisionStore,
  DivisionStoreContext,
} from "./store/DivisionStore";
import { useAuthStore } from "../Auth/store/Auth";
import { useEffect } from "react";
const DivisionManagement = () => {
  const { id,name } = useParams();
  // const [isload,setisload] = useState(true)
  const store = new DivisionStore({
    isloading: id ? false : true,
    playerlist: true,
    id:id
  });
  return (
    <DivisionStoreContext.Provider value={store}>
      <Screen />
    </DivisionStoreContext.Provider>
  );
};
export default DivisionManagement;

const Screen = observer(() => {
  const { DivisionList, loadDivision, nolist,getToken } = useDivisionStore();
  const { adminAuthApproved } = useAuthStore();
  const { id,name } = useParams();
  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }
  
  }, [adminAuthApproved]);
  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Division Management</p>
          {/* <p className=" opacity-80 text-sm">Home / Division</p> */}
        </div>
        {/* <div>
        <Link to="./adddivision" className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"><MdAdd/>Add Division</Link>
      </div> */}
      </div>
      <Filter league={id} name={name} />
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th>#</th>
                <th>Division Code</th>
                <th>Players Count</th>
                <th>Level</th>
                <th>League Name</th>
                <th>Home Court </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {loadDivision && (
                <>
                  <tr>
                    <td colSpan={6} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={6} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={6} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={6} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={6} className="animate-pulse py-6"></td>
                  </tr>
                </>
              )}
              {DivisionList.map((data, index) => (
                <tr key={index} className="capitalize">
                  <td>{index + 1}</td>
                  <td>{data?.code ?? "N/A"}</td>
                  <td>{data?.players.length ?? "N/A"}</td>
                  <td>{data?.level?.name.toFixed(1) ?? "N/A"}</td>
                  <td>{data?.league ? data?.league.name ?? "N/A" : "N/A"}</td>
                  <td key={index}>
                    {data.homecourts?.map((homecourt, index) => (
                      <> {(index ? " , " : "") + homecourt?.displayName +" - "+ homecourt?.zipcode
                    }</>
                    ))}
                  </td>
                  <td className="ml-2">
                    <div className="flex">
                      <span data-tip="View Players"  className="tooltip " >
                    <Link to={`./playerlist/${data?._id}`}>
                        <MdRemoveRedEye   className="cursor-pointer" />
                      </Link>
                      </span>
                      <span data-tip="View Matches"  className="ml-5 tooltip tooltip-left" >
                      <Link to={`./matchlist/${data?._id}/${data?.league?._id}/${data?.code}/${data?.league?.name}/${data?.level?._id}/${data?.level?.name}`}>
                        <MdSportsTennis className= "cursor-pointer" />
                      </Link>
                      </span>
                    </div>
                     
                  </td>
                </tr>
              ))}
              {nolist && (
                <tr>
                  <td colSpan={6} className="my-10 text-center">
                    <MdFlag className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">No Division Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
});
