import React, { useEffect } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { RxDashboard, RxPerson, RxGear, RxHome, RxCrosshair1, RxCalendar, RxAspectRatio, RxStar, RxPinBottom, RxQuestionMarkCircled } from "react-icons/rx";
import { MdCalendarToday, MdLocationCity, MdOutlinedFlag, MdOutlineLocationOn, MdOutlineManageAccounts, MdPages, MdSportsHandball, MdSportsTennis, MdAttachMoney, MdRequestQuote, MdRequestPage, MdOutlineBrandingWatermark, MdPageview, MdFindInPage, MdReport, MdImportExport, MdLocalShipping, MdOutlineStackedLineChart, MdPercent, MdShoppingCart, MdDesktopMac, MdMoney, MdQuestionMark } from "react-icons/md";
import Logo from "../assets/images/logo.png"
import { NavLink } from "react-router-dom";
import { BsDatabaseFillLock, BsDot, BsPercent, BsQuestionCircle, BsSpeaker } from "react-icons/bs";
import { AiOutlineStock } from "react-icons/ai";
import { useAuthStore } from "../Auth/store/Auth";
import { useUserStore } from "../Users/store/UserStore";  
import { observer } from "mobx-react";
const Navbar = observer(() => {
  const { toggleSidebar, collapseSidebar, broken, collapsed } = useProSidebar();
  const { admin_permissions } = useAuthStore();
  const {
  RequestCount,
  getToken,
  getFilterUser
} = useUserStore();
const { adminAuthApproved } = useAuthStore();

useEffect(() => {
    if (adminAuthApproved) {
        getToken(localStorage.getItem("Admintoken"));
    }

}, [adminAuthApproved]);
  console.log(admin_permissions.brand);

  return (
    <Sidebar
    onClick={getFilterUser}
      id="sidebar"
      className="md:sticky fixed left-0 md:translate-x-0 -translate-x-full h-screen md:top-0 top-[70px] font-roboto z-50"
      backgroundColor="#000"
    >
      {/* <div className="h-[70px] flex">
        <img className="w-[65%] m-auto" alt="..." src={Logo}/>
      </div> */}
      <div className=" my-5 text-white text-center justify-center flex">
        {/* <h4 className="text-[#0098e5] text-center font-extrabold text-3xl my-5">

        </h4> */}
        <img className="w-[25%]  m-auto" alt="..." src={Logo}/>
        {/* Logo */}
      </div>
      <Menu>
        <NavLink className="link-main" to="/">
          <MenuItem onClick={getFilterUser} icon={<RxDashboard />}>
            Dashboard
          </MenuItem>
        </NavLink>
        <SubMenu label="Product Management" icon={<RxPerson />}>
          <NavLink className="link-main" to="/productcatalog">

            <MenuItem onClick={getFilterUser} icon={<BsDot />}> Product Catalog</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/sections">

            <MenuItem  onClick={getFilterUser} icon={<BsDot />}>Section</MenuItem>
          </NavLink>
          {/* <NavLink className="link-main" to="/inprogress">

            <MenuItem icon={<BsDot />}> Seller's Product Inventory</MenuItem>
          </NavLink> */}
          <NavLink className="link-main" to="/banner">
            <MenuItem onClick={getFilterUser} icon={<BsDot />}>Banner</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/categories">
            <MenuItem onClick={getFilterUser} icon={<BsDot />}>Categories</MenuItem>
          </NavLink>
          {admin_permissions.brand !== 0 &&
            <NavLink className="link-main" to="/brandmanagement">
              <MenuItem onClick={getFilterUser} icon={<BsDot />}> Brand</MenuItem>
            </NavLink>
          }

          {/* <NavLink className="link-main" to="/inprogress">

            <MenuItem icon={<BsDot />}> Shops </MenuItem>
          </NavLink> */}
          <NavLink className="link-main" to="/productoptions">

            <MenuItem onClick={getFilterUser} icon={<BsDot />}> Product Options</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/tag">

            <MenuItem onClick={getFilterUser} icon={<BsDot />}> Product Tags</MenuItem>
          </NavLink>
        </SubMenu>
        {/* <SubMenu label="Request" icon={<BsQuestionCircle />}>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Brand Requests </MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Category Requests </MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Master Product Requests </MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Seller's Product Requests </MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Seller's Registration Requests </MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Order Return Requests </MenuItem>
          </NavLink>
        </SubMenu> */}
        <SubMenu label="Orders" icon={<AiOutlineStock />}>
          <NavLink className="link-main" to="/order">
            <MenuItem onClick={getFilterUser} icon={<BsDot />}> Orders </MenuItem>
          </NavLink>
          {/* <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Subscription Orders </MenuItem>
          </NavLink> */}
          {/* <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}> Order Cancellation Reasons </MenuItem>
          </NavLink> */}
          {/* <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}> Order Return Reasons </MenuItem>
          </NavLink> */}
          {/* <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}> Order Status </MenuItem>
          </NavLink> */}
          {/* <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}> Product Reviews </MenuItem>
          </NavLink> */}
          {/* <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}> Abandoned Cart </MenuItem>
          </NavLink> */}
        </SubMenu>
        {/* <NavLink className="link-main" to="/employee">
          <MenuItem onClick={getFilterUser} icon={<RxPerson />}>
            Employees
          </MenuItem>
        </NavLink> */}
        <NavLink className="link-main" to="/reward">
          <MenuItem onClick={getFilterUser} icon={<MdMoney />}>
            Reward
          </MenuItem>
        </NavLink>
        <NavLink className="link-main" to="/faq">
          <MenuItem onClick={getFilterUser} icon={<MdQuestionMark />}>
           FAQ Management
          </MenuItem>
        </NavLink>
        <NavLink className="link-main" to="/querylist">
          <MenuItem onClick={getFilterUser} icon={<RxQuestionMarkCircled />}>
            Query List
          </MenuItem>
        </NavLink>
        <NavLink className="link-main" to="/location">
          <MenuItem onClick={getFilterUser} icon={<RxPinBottom />}>
            Location
          </MenuItem>
        </NavLink>
        <SubMenu label="PreOrders" icon={<MdShoppingCart />}>
        
      
    
        <NavLink className="link-main" to="/preordersettings">
          <MenuItem  onClick={getFilterUser} icon={<BsDot />}>PreOrders Settings</MenuItem>
        </NavLink>
        <NavLink className="link-main" to="/preorder">

          <MenuItem icon={<BsDot />}>PreOrders</MenuItem>
        </NavLink>

        {/* <MenuItem icon={<BsDot />}>Reward Users</MenuItem>
        <MenuItem icon={<BsDot />}>Transactions</MenuItem>
        <MenuItem icon={<BsDot />}>Deleted Users</MenuItem> */}

      </SubMenu>

      <SubMenu label="Website" icon={<MdDesktopMac />}>
        <NavLink className="link-main" to="/contactpage">
          <MenuItem  onClick={getFilterUser} icon={<BsDot />}>Contact Page</MenuItem>
        </NavLink>
        <NavLink className="link-main" to="/otherpages">

          <MenuItem icon={<BsDot />}>Other Pages</MenuItem>
        </NavLink>

        {/* <MenuItem icon={<BsDot />}>Reward Users</MenuItem>
        <MenuItem icon={<BsDot />}>Transactions</MenuItem>
        <MenuItem icon={<BsDot />}>Deleted Users</MenuItem> */}

      </SubMenu>

        <NavLink className="link-main" to="/review">
          <MenuItem onClick={getFilterUser} icon={<RxStar />}>
            Reviews
          </MenuItem>
        </NavLink>
        <SubMenu label="Users" icon={<RxPerson />}>
        
          <NavLink className="link-main" to="/adminusers">
            <MenuItem  onClick={getFilterUser} icon={<BsDot />}>Admin Users</MenuItem>
          </NavLink>
          {/* <NavLink className="link-main" to="/users">
            <div className="flex ">
              <MenuItem  onClick={getFilterUser} icon={<BsDot />}>
                <div className="flex justify-between ">
                  <span>Requested Sellers</span>
                  <span className="w-5 h-5 flex justify-center items-center text-center  bg-pink-500 rounded-full text-xs " >{RequestCount}</span>

                </div>

              </MenuItem>

            </div>

          </NavLink> */}
          {/* <NavLink className="link-main" to="/approvedusers">
            <MenuItem  onClick={getFilterUser} icon={<BsDot />}>Approved Sellers</MenuItem>
          </NavLink> */}
          {/* <NavLink className="link-main" to="/inprogress">

            <MenuItem icon={<BsDot />}> Users</MenuItem>
          </NavLink> */}

          {/* <MenuItem icon={<BsDot />}>Reward Users</MenuItem>
          <MenuItem icon={<BsDot />}>Transactions</MenuItem>
          <MenuItem icon={<BsDot />}>Deleted Users</MenuItem> */}

        </SubMenu>

        <NavLink className="link-main" to="/coupenmanagement">
          <MenuItem onClick={getFilterUser} icon={<MdPercent />}>
           Coupon Management
          </MenuItem>
        </NavLink>

   
        {/* <SubMenu label="Promotions" icon={<BsSpeaker />}>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Special Price</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Volume Discounts</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Similar Products</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Frequently Bought Together</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>PPC Promotions Management</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Reward on Purchase</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Product Event Weightages</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Recommended Product Tag Weightages</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Discount Coupons</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Push notifications</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Badages</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Ribbons</MenuItem>
          </NavLink>
        </SubMenu> */}
        {/* <SubMenu label="Blog" icon={<MdFindInPage />}>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Blog Posts Categories</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Blog Posts</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Blog Contribution Requests</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Blog Comments</MenuItem>
          </NavLink>
        </SubMenu> */}
        {/* <SubMenu label="Tax" icon={<BsPercent />}>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Tax Structures</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Tax Categories</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Tax Categories Rules</MenuItem>
          </NavLink>
        </SubMenu> */}
        {/* <SubMenu label="CMS" icon={<BsDatabaseFillLock />}>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Homepage Slides</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Banners</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Content Pages</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Content Blocks</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Import instructions</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>FAQs</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Testimonials</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Navigations</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Collections</MenuItem>
          </NavLink>
        </SubMenu> */}
        {/* <SubMenu label="Reports" icon={<MdReport />}>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Sales Reports</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Users Reports</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Financial Reports</MenuItem>
          </NavLink>

          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Subscription Reports</MenuItem>
          </NavLink>

          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Discount Coupons</MenuItem>
          </NavLink>

        </SubMenu> */}
        {/* <SubMenu label="IMPORT EXPORT" icon={<MdImportExport />}>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Import Export </MenuItem>
          </NavLink>
        </SubMenu> */}
        {/* <SubMenu label=" Shipping & Picking" icon={<MdLocalShipping />}>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}> Shipping company users </MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}> Shipping packages</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}> Shipping profile </MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Pickup addresses </MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}> Shipped products </MenuItem>
          </NavLink>
        </SubMenu>
        <SubMenu label="SEO" icon={<MdOutlineStackedLineChart />}>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}> URL rewriting</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}> Image attributes</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}> Generate sitemap</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}> View HTML</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}> View XML</MenuItem>
          </NavLink>
          <NavLink className="link-main" to="/inprogress">
            <MenuItem icon={<BsDot />}>Meta tags  management</MenuItem>
          </NavLink>
        </SubMenu> */}

        {/* <NavLink className="link-main" to="/inprogress">
          <MenuItem icon={<RxGear />}>Settings</MenuItem>
        </NavLink> */}


      </Menu>
    </Sidebar>
  );
});

export default Navbar;
