import { createContext, useContext } from "react";
import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";
const SEO_URL = process.env.REACT_APP_SEO_URL;





export class ReviewStore {

    Token = null;
    Review = [];
    type = "";
    ReviewLoader = false;
    Reviewtype = [];
    filterSearch = "";
    PaginationPage = "1";
    TotalPages = "";
    ReviewDimiensions = ""
    crop = {};
    errLoadReview = "";
    LoadReview = false;
    delateLoader = false;
    nolist = false;
    Reviewpopup = false;
    deletepopup = false;
    Reviewname = "";
    status = "1";
    statesid = "";
    Reviewid = [];
    seoUrl = "";
    statuspopup = false;
    addReviewname = "";
    imageurl = "";
    logoimage = "";
    Reviewimage = "";
    Base64logoImage = "";
    Base64ReviewImage = "";
    logofile = [];
    allReview = [];
    alldeactivate = 'false';
    isReviewUpdated = "false";
    isLogoUpdated = "false"
    checkeddd = '';
    ReviewFormDetails = {
        "title": "",
        "section_name": "",
        "url": "",
        "section_id": "",
        "image_name": "",
        "is_active": ""
    };
    saved = false;

    constructor(isloading = false) {
        makeAutoObservable(this, {
            addReviewname: observable,
            status: observable,
            errorMessages: observable,
            setaddReviewname: action,
            getstatus: action,
            resetErrors: action,
        });

        this.Token = localStorage.getItem("Admintoken");
        if (isloading.isloading) {
            this.getReviewList();
            //   this.getStateList();
            return
        }
        // if (isloading.addloading) {
        //     this.getReviewTypeList();
        // }
        // if (isloading.Revieweditloading) {
        //     this.getReviewform(isloading.id);
        //     this.getReviewTypeList();

        //     return
        // }
    }
    //All Clear 
    allclear = () => {
        this.filterSearch = ""
        this.getFilterReview();
        //   this.status="";
        //   this.statesid="";
    }
    setimage = (text, type) => {
        this.imageurl = URL.createObjectURL(text[0]);
    }

    setalldeactivate = (text) => {
        console.log(text);
        this.alldeactivate = !this.alldeactivate;
    };

    getid = (text) => {
        this.Reviewid.push(text);
    };



    // status popup
    setstatuspopup = () => {
        this.statuspopup = !this.statuspopup;
    };


    setallReview = (text) => {
        this.allReview = text;
    }

    // get status 
    getstatus = (text) => {
        this.status = text;
    };

    //get city name
    getReviewname = (text) => {
        this.Reviewname = text;
    };

    setaddReviewname = (text) => {
        this.addReviewname = text;
    };
    setCrop = (text) => {
        this.crop = text;
    };

    setReviewDimiensions = (text) => {
        this.ReviewDimiensions = text;
    };

    setlogofile = (e) => {
        this.logofile = e;
        console.log(this.logofile);
    };

    setReviewpopup = (e) => {
        this.Reviewpopup = e;
    };
    setdeletepopup = (e) => {
        this.deletepopup = e;
    };
    setdelateLoader = (e) => {
        this.delateLoader = e;
    }
    getToken = (e) => {
        this.Token = e;
    }
    gettype = (e) => {
        this.type = e;
    }
    getseourl = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.seoUrl = text;
    };
    getlogoimage = (text) => {
        console.log("checking in the Review", text);
        this.logoimage = text;
    };
    getReview_image = (text) => {
        console.log(text);
        this.Reviewimage = text;
    };
    SetfilterSearch = (text) => {
        this.filterSearch = text;
    };
    setPaginationPage = (text) => {
        this.PaginationPage = text;
    };
    SetTotalPages = (text) => {
        this.TotalPages = text;
    };
    setchecked = (text) => {
        this.checkeddd = text;
    };
    setisReviewUpdated = (text) => {
        this.isReviewUpdated = text;
    };
    setisLogoUpdated = (text) => {
        this.isLogoUpdated = text;
    };

    isBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };

    setReviewFormDetails = (ReviewDetail) => {
        this.ReviewFormDetails = {
            ...this.ReviewFormDetails,
            title: ReviewDetail.title || "", // Use default value if name is undefined
            url: ReviewDetail.url || "", // Use default value if seo_url is undefined
            // logo_image: this.isBase64( this.ReviewFormDetails.logo_image) ? this.logofile.length == 0 ? ReviewDetail.logo_image    : this.ReviewFormDetails.logo_image  : this.logofile.length !== 0 ? this.ReviewFormDetails.logo_image :  ReviewDetail.logo_image   || "",
            // Review_image: this.isBase64(ReviewDetail.Review_image) ? this.ReviewFormDetails.Review_image : ReviewDetail.Review_image || "",
            image_name: ReviewDetail.image_name || "",
            section_id: ReviewDetail.section_id || "",
            section_name: ReviewDetail.section_name || "",

            is_active: ReviewDetail.is_active
        };
    };
    // setReviewFormDetails = (text) => {
    //     this.ReviewFormDetails = text;
    //     console.log("setReviewFormDetails", this.ReviewFormDetails.Reviewname);
    // };
    base64toFile(base64Data, fileName, mimeType) {
        if (base64Data) {
            const byteCharacters = atob(base64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters?.length; offset += 512) {
                const slice = byteCharacters?.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const file = new File(byteArrays, fileName, { type: mimeType });

            return file;
        }

    }

    // get Review list
    getReviewList = () => {
        runInAction(() => {
            this.ReviewLoader = true;
            this.errLoadReview = "";
            this.saved = false;
            this.nolist = false;
            this.Review = [];
        });
        getMethodData(`/api/v1/admin/review/get-list?page=${this.PaginationPage}`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ReviewLoader = false;
                        this.errLoadReview = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ReviewLoader = false;
                        this.errLoadReview = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Review = resp?.data?.data;
                        // this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.ReviewLoader = false;
                    this.errLoadReview = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ReviewLoader = false;
                    if (!error.response) {
                        this.errLoadReview = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadReview = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };


    // filter League court

    getFilterReview = () => {
        runInAction(() => {
            this.ReviewLoader = true;
            this.errReviewLoader = "";
            this.saved = false;
            this.nolist = false;
            this.getReviewList = [];
        });
        postMethodData(`/api/v1/admin/review/search?page=${this.PaginationPage}`, {
            keyword: this.filterSearch
        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.loadleague = false;
                        this.ReviewLoader = false;

                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.loadcity = false;
                        this.ReviewLoader = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Review = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.loadleague = false;
                    this.ReviewLoader = false;

                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };



    //Active and inactive
    statusChange = () => {
        runInAction(() => {
            this.SectionLoader = true;
            this.Sectionpopup = true;

            this.errLoadSection = "";
        });
        postMethodData(`/api/v1/admin/Review/update-status`, {

            "record_id": this.Reviewid,
            "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.Sectionid = [];
                        this.Reviewid = [];

                        this.SectionLoader = false;
                        this.Reviewpopup = false;
                        this.errLoadSection = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.SectionLoader = false;
                        this.Reviewpopup = false;
                        this.errLoadSection = resp?.data?.message;
                        this.getFilterReview();
                        this.Sectionid = [];
                        this.Reviewid = [];
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.SectionLoader = false;
                    this.Reviewpopup = false;

                    this.errLoadSection = resp?.data?.message;
                })
            })
            .catch((error) => {
                this.Reviewid = [];

                runInAction(() => {
                    if (!error.response) {
                        this.SectionLoader = false;
                        this.Reviewpopup = false;
                        this.Sectionid = [];
                        this.errLoadSection = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.SectionLoader = false;
                    this.Reviewpopup = false;
                    this.Sectionid = [];


                    this.errLoadSection = error?.response?.data?.message;
                })
                return;
            })
    };


    //Delete method
    deletefunction = () => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errLoadReview = "";
        });
        postMethodData(`/api/v1/admin/review/delete`, {

            "record_id": this.Reviewid,

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.Reviewid = [];

                    this.LoadReview = false;
                    this.setdelateLoader(true);
                    this.deletepopup = true;
                    this.errLoadReview = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadReview = false;
                    this.Reviewid = [];

                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    this.errLoadReview = resp?.data?.message;
                    this.getFilterReview();
                    toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadReview = false;
                this.errLoadReview = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    this.Reviewid = [];

                    if (!error.response) {
                        this.LoadReview = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadReview = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadReview = false;
                    this.errLoadReview = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }






}




export const ReviewStoreContext = createContext(new ReviewStore());
export const useReviewStore = () => useContext(ReviewStoreContext);
