import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdImageAspectRatio, MdImage, MdSave, MdEdit } from "react-icons/md";
import { Input, Button, Select } from "react-daisyui";
// import { CityStore, CityStoreContext } from "./store/CityStore";
import { EmployeeStore, EmployeeStoreContext, useEmployeeStore }  from "../Employees/store/EmployeeStore";
import { observer } from "mobx-react";
import { useAuthStore } from "../Auth/store/Auth";
import { useState } from "react";
import ImgCrop from "antd-img-crop";
import { Upload } from 'antd';
import axios from "axios";
import { RxEyeClosed, RxEyeOpen } from "react-icons/rx";
const SEO_URL = process.env.REACT_APP_SEO_URL;

const EditAdminUsers = observer(() => {
    const { id } = useParams();
    return (
        <EmployeeStoreContext.Provider
            value={
                new EmployeeStore({
                    loadeditcitybol: false,
                    Employeeeditloading: true,
                    id: id,
                })
            }
        >
            <Screen />
        </EmployeeStoreContext.Provider>
    );
});
export default EditAdminUsers;
// licence_img_1 = "";
// licence_img_2 = "";
// voter_img_2 = "";
// voter_img_1 = "";
// aadhar_img_1 = ""
// aadhar_img_2 = ""
// bank_passbook_img = ""
// fullname = ""
// username=""
// email = ""
// password = ""
// mobile = ""
// bank_ifsc = ""
// bank_branch_addr = ""
// bank_ac_no = ""
// aadhar_no = ""
// voter_no = ""
// pan_no= ""
// licence_no= ""
// addr_line_1= ""
// addr_line_2= ""
// city= ""
// state= ""
const Screen = observer(() => {
    const { getstatus, setaddEmployeename, updateEmployee, setuserId, setCrop, setaadhar_img_1, setaadhar_img_2, setlicence_img_1, 
        setlicence_img_2, setaddr_line_2, setvoter_img_1, setvoter_img_2, setbank_passbook_img, setname, getusername ,setbank_ac_no, setpan_no, setbank_ifsc, setpan_img , getEmployee_image, setcity,
        setbankName,setbankDetail , bankDetail , setstate, setemail, setmobile, setbank_branch_addr, setsalary, setDesignation, passwordChanged, Confirmpassw, setaadhar_no,
        setvoter_no, setlicence_no,
        setotherDetail, 
        setAdminUserFormDetails , isBase64 , OtherFormDetail, Base64logoaadhar_img_1 , Base64logoaadhar_img_2 , Base64logvoter_img_1,  Base64logvoter_img_2 ,Base64logolicence_img_1 , Base64logolicence_img_2 , 
        Base64logopan_img_1 , Base64logoUser_img, Base64logoBank_pass_image,
        
        AdminUserFormDetails,
        confirmresetpas,

        setBannerDimiensions, getlogoimage, getbanner_image, crop, logoimage, errorMessages, addEmployeename, saved, getseourl, seoUrl, addloadcity, getToken } = useEmployeeStore();
    const { adminAuthApproved } = useAuthStore();
    const [logofile, setlogofile] = useState();
    const [AdharImage2, setAdharImage2] = useState();
    const [VoterId_1, setVoterId_1] = useState();
    const [VoterId_2, setVoterId_2] = useState();
    const [Licence_1, setLicense_1] = useState();
    const [Licence_2, setLicense_2] = useState();
    const [PanImage, setPanImage] = useState();
    const [Employee_image, setEmployeeImage] = useState();
    const [Passbook, setPassbook] = useState();
    const [showpassword, setshowpassword] = useState(false);
    const [confirmpassword, setconfirmpassword] = useState(false);
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const { id } = useParams();




    const [bannerfile, setbannerfile] = useState();
    const [croppedImage, setCroppedImage] = useState(null);
    // const [crop, setCrop] = useState({ aspect: 16 / 9, dimensions: { width: 480, height: 270 } });
    // const { register, handleSubmit, errors } = useForm();

    const onSubmit = (data) => {
        // Handle form submission logic here
        console.log(data);
    };

    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);
    const navigate = useNavigate();


    useEffect(() => {
        if (saved) {
            navigate("../adminusers");

        }
    }, [saved]);

    const handleChange = (e) => {
        // resetErrors(); // Reset errors on input change
        console.log(e);
        switch (e) {
            case 'Employeename':
                setaddEmployeename(e.target.value);
                break;
            case 'status':
                getstatus(e.target.value);
                break;
            // Handle other form fields in a similar manner
            default:
                break;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateEmployee(id);
        // Perform API call or any other logic using MobX store data
        // Example: addCity();
    };

    console.log(SEO_URL);







    const getLogoSrcFromFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
    };

    const getBase64 = file => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });
    };





    const LogoPreview = async (file) => {
        const src = file.url || (await getLogoSrcFromFile(file));
        if (src) {
            const imgWindow = window.open(src);
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            if (imgWindow) {
                const image = new Image();
                image.src = src;
                imgWindow.document.write(image.outerHTML);
            } else {
                window.location.href = src;
            }
        };
    }





    const getBannerSrcFromFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
    };

    const LogChange = async ({ fileList: newFileList }) => {
        // Allow only the first file in the list
        const file = newFileList[0];
        // Get the base64 representation of the file
        if (file) {
            const base64Image = await getBase64(file.originFileObj);
            const files = base64Image;
            setotherDetail({
                ...OtherFormDetail,
                aadhar_img_1: files,
            })
            setaadhar_img_1(files);
        }

        // Update the logofile state with the newFileList
        setlogofile(newFileList.slice(0, 1));

    };
    const AdharImage2Change = async ({ fileList: newFileList }) => {
        // Allow only the first file in the list
        const file = newFileList[0];
        // Get the base64 representation of the file
        if (file) {
            const base64Image = await getBase64(file.originFileObj);
            const files = base64Image;
            setotherDetail({
                ...OtherFormDetail,
                aadhar_img_2: files,
            })
            setaadhar_img_2(files);

        }

        // Update the logofile state with the newFileList
        setAdharImage2(newFileList.slice(0, 1));

    };
    const voterId_1 = async ({ fileList: newFileList }) => {
        // Allow only the first file in the list
        const file = newFileList[0];
        // Get the base64 representation of the file
        if (file) {
            const base64Image = await getBase64(file.originFileObj);
            const files = base64Image;
            setotherDetail({
                ...OtherFormDetail,
                voter_img_1: files,
            })
            setvoter_img_1(files);

        }

        // Update the logofile state with the newFileList
        setVoterId_1(newFileList.slice(0, 1));

    };
    const voterId_2 = async ({ fileList: newFileList }) => {
        // Allow only the first file in the list
        const file = newFileList[0];
        // Get the base64 representation of the file
        if (file) {
            const base64Image = await getBase64(file.originFileObj);
            const files = base64Image;
            setotherDetail({
                ...OtherFormDetail,
                voter_img_2: files,
            })

        }

        // Update the logofile state with the newFileList
        setVoterId_2(newFileList.slice(0, 1));

    };
    const licence_1 = async ({ fileList: newFileList }) => {
        // Allow only the first file in the list
        const file = newFileList[0];
        // Get the base64 representation of the file
        if (file) {
            const base64Image = await getBase64(file.originFileObj);
            const files = base64Image;
            setotherDetail({
                ...OtherFormDetail,
                licence_img_1: files,
            })
            setlicence_img_1(files);

        }

        // Update the logofile state with the newFileList
        setLicense_1(newFileList.slice(0, 1));

    };
    const licence_2 = async ({ fileList: newFileList }) => {
        // Allow only the first file in the list
        const file = newFileList[0];
        // Get the base64 representation of the file
        if (file) {
            const base64Image = await getBase64(file.originFileObj);
            const files = base64Image;
            setotherDetail({
                ...OtherFormDetail,
                licence_img_2: files,
            })
            setlicence_img_2(files);

        }

        // Update the logofile state with the newFileList
        setLicense_2(newFileList.slice(0, 1));

    };
    const employeeImage = async ({ fileList: newFileList }) => {
        // Allow only the first file in the list
        const file = newFileList[0];
        // Get the base64 representation of the file
        if (file) {
            const base64Image = await getBase64(file.originFileObj);
            const files = base64Image;
            setAdminUserFormDetails({
                ...AdminUserFormDetails,
                image: files,
            })
            getEmployee_image(files);

        }

        // Update the logofile state with the newFileList
        setEmployeeImage(newFileList.slice(0, 1));

    };
    const panImage = async ({ fileList: newFileList }) => {
        // Allow only the first file in the list
        const file = newFileList[0];
        // Get the base64 representation of the file
        if (file) {
            const base64Image = await getBase64(file.originFileObj);
            const files = base64Image;
            setotherDetail({
                ...OtherFormDetail,
                pan_img_1: files,
            })
            setpan_img(files);

        }

        // Update the logofile state with the newFileList
        setPanImage(newFileList.slice(0, 1));

    };
    const passbook = async ({ fileList: newFileList }) => {
        // Allow only the first file in the list
        const file = newFileList[0];
        // Get the base64 representation of the file
        if (file) {
            const base64Image = await getBase64(file.originFileObj);
            const files = base64Image;
            setbankDetail({
                ...bankDetail,
                passbook_img: files,
            })
            setbank_passbook_img(files);

        }

        // Update the logofile state with the newFileList
        setPassbook(newFileList.slice(0, 1));

    };




   

 

   



    const handleCrop = croppedImageUrl => {
        setCroppedImage(croppedImageUrl);
    };



    return (
        <>
            <div className="flex justify-between">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">Employee Settings</p>
                    <p className=" opacity-80 text-sm">Home /Employee Settings</p>
                </div>
                <div>

                    <Link
                        onClick={() => navigate(-1)}
                        className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                        Back
                    </Link>
                </div>

            </div>
            <div className="card bg-base-100 shadow-md p-6 mt-8">
                <form onSubmit={handleSubmit}>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">

                        {/* <div>
                            <label
                                htmlFor="Employeeid"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Employee id<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="id"
                                required
                                onChange={(e) => { setuserId(e.target.value); }}
                                placeholder="id"
                                id="id"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div> */}
                        <div>
                            <label
                                htmlFor="name"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Full name<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="name"
                                required
                                placeholder="name"
                                value={AdminUserFormDetails.full_name}
                                onChange={(e) => {
                                    setAdminUserFormDetails({
                                        ...AdminUserFormDetails,
                                        full_name: e.target.value,
                                    });
                                }}
                                id="name"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>
                        <div>
                            <label
                                htmlFor="Username"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Username<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="Username"
                                disabled
                                placeholder="username"
                                value={AdminUserFormDetails.username}
                                onChange={(e) => {
                                    setAdminUserFormDetails({
                                        ...AdminUserFormDetails,
                                        username: e.target.value,
                                    });
                                }}
                                id="Username"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="Employeeemail"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Email id<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="Email"
                                required
                                value={AdminUserFormDetails.email}
                                onChange={(e) => {
                                    setAdminUserFormDetails({
                                        ...AdminUserFormDetails,
                                        email: e.target.value,
                                    });
                                }}
                                placeholder="Email"
                                id="email"
                                type="email"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>
                        <div>
                            <label
                                htmlFor="Employeenumber"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Mobile No.<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="mobile"
                                required
                                value={AdminUserFormDetails.mobile}
                                onChange={(e) => {
                                    setAdminUserFormDetails({
                                        ...AdminUserFormDetails,
                                        mobile: e.target.value,
                                    });
                                }}
                                placeholder="Mobile No."
                                id="mobile"
                                type="number"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>
                        <div>
                            <label
                                htmlFor="Employeeaddress"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Address<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="Address"
                                required
                                value={OtherFormDetail.address_line_2}
                                onChange={(e) => {
                                    setotherDetail({
                                        ...OtherFormDetail,
                                        address_line_2: e.target.value,
                                    });
                                }}
                                placeholder="Address"
                                id="Address"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>
                        <div>
                            <label
                                htmlFor="Employeeaddress"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Salary<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="Salary"
                                required
                                value={OtherFormDetail.salary}
                                onChange={(e) => {
                                    setotherDetail({
                                        ...OtherFormDetail,
                                        salary: e.target.value,
                                    });
                                }}
                                placeholder="Salary"
                                id="Salary"
                                type="number"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>

                        <div>
                            <label
                                htmlFor="Employeedesgnation"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Designation<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="Designation"
                                required
                                value={OtherFormDetail.designation}
                                onChange={(e) => {
                                    setotherDetail({
                                        ...OtherFormDetail,
                                        designation: e.target.value,
                                    });
                                }}
                                placeholder="Designation"
                                id="Designation"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>
                    



                    </div>
                    <div className="border-t-2 flex gap-[13%] border-dashed mt-9 ml-3 ">
                        <div className="mt-3 w-[50%]">
                            <label htmlFor="image" className="block text-md font-semibold text-zinc-700 mt-3 dark:text-stone-400 mb-2">
                                Employee name<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name=" Employeename"
                                required
                                value={AdminUserFormDetails.full_name}
                                onChange={(e) => {
                                    setAdminUserFormDetails({
                                        ...AdminUserFormDetails,
                                        full_name: e.target.value,
                                    });
                                }}
                                placeholder=" Employee Name"
                                id=" Employeename"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full mt-5 text-sm font-medium rounded-md"
                            />

                        </div>



                        <div>
                            <label htmlFor="image" className="block text-md font-semibold text-zinc-700 mt-3 dark:text-stone-400 mb-2">
                                Employee Image <span className="text-[#f00]">*</span>
                            </label>

                            {/* {imageurl && <img className="w-[50%]" src={imageurl} alt="Selected" />} */}
                            <ImgCrop
                                aspect={crop.aspect}
                                resize={crop.dimensions}
                                showGrid
                                rotationSlider
                                aspectSlider
                                showReset
                                onCrop={handleCrop}
                            >
                                  <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture-card"
                                    accept="image/*"
                                    fileList={Employee_image}
                                    onChange={employeeImage}
                                // onPreview={LogoPreview}
                                >
                                  {  console.log("Base64logoUser_img",AdminUserFormDetails.image , Base64logoUser_img , BASE_URL + `/uploads/adminUsers/${AdminUserFormDetails.image}` )}

                                    {(Employee_image == undefined && Employee_image?.length === 0) && !AdminUserFormDetails.image? (
                                        <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Employee Image </span>
                                    )
                                        :

                                        (Employee_image == undefined || Employee_image?.length === 0) && (

                                            (AdminUserFormDetails.image== null || AdminUserFormDetails.image== "") ?
                                                <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Employee Image </span>

                                                :

                                                <div className="relative group">
                                                    {isBase64(AdminUserFormDetails.image) ?
                                                        <img
                                                            crossOrigin="anonymous"
                                                            src={BASE_URL + `/uploads/adminUsers/${Base64logoUser_img}`}
                                                            alt="Default Logo"
                                                            className="w-full h-auto rounded-[50%]"
                                                        />
                                                        :
                                                        <img
                                                            crossOrigin="anonymous"
                                                            src={BASE_URL + `/uploads/adminUsers/${AdminUserFormDetails.image}`}
                                                            alt="Default Logo"
                                                            className="w-full h-auto rounded-[50%]"
                                                        />

                                                    }

                                                    <div className="absolute inset-0 bg-color_theme bg-opacity-50 rounded-[50%] flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                                                        <MdEdit className="text-white text-lg" />
                                                    </div>
                                                </div>
                                        )

                                    }

                                </Upload>
                                {/* Conditionally render the default image */}

                            </ImgCrop>
                      

                        </div>



                    </div>
                    <div className="border-t-2 flex justify-between border-dashed mt-9 ml-3 ">

                        <div className="mt-3 w-[50%]">

                            <label htmlFor="image" className="block text-md font-semibold text-zinc-700 mt-3 dark:text-stone-400 mb-2">
                                Aadhar No.<span className="text-[#f00]">*</span>
                            </label>
                            <Input
                                name="Aadhar"
                                required
                                value={OtherFormDetail.aadhar_no}
                                onChange={(e) => {
                                    setotherDetail({
                                        ...OtherFormDetail,
                                        aadhar_no: e.target.value,
                                    });
                                }}
                                placeholder="Aadhar"
                                id="Aadhar"
                                type="number"
                                autoComplete="off"
                                className=" focus:outline-0 w-full mt-5 text-sm font-medium rounded-md"
                            />

                        </div>
                        <div>
                            <label htmlFor="image" className="block text-md font-semibold text-zinc-700 mt-3 dark:text-stone-400 mb-2">
                                Aadhar Image 1<span className="text-[#f00]">*</span>
                            </label>

                            {/* {imageurl && <img className="w-[50%]" src={imageurl} alt="Selected" />} */}
                            <ImgCrop
                                aspect={crop.aspect}
                                resize={crop.dimensions}
                                showGrid
                                rotationSlider
                                aspectSlider
                                showReset
                                onCrop={handleCrop}
                            >
                                <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture-circle"
                                    fileList={logofile}
                                    onChange={LogChange}
                                    onPreview={LogoPreview}
                                    className="logo"
                                >

                                    {(logofile == undefined && logofile?.length === 0) && !OtherFormDetail.aadhar_img_1 ? (
                                        <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Aadhar Image 1</span>
                                    )
                                        :

                                        (logofile == undefined || logofile?.length === 0) && (

                                            (OtherFormDetail.aadhar_img_1 == null || OtherFormDetail.aadhar_img_1 == "") ?
                                                <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Aadhar Image 1</span>
                                                :
                                                <div className="relative group">
                                                    {isBase64(OtherFormDetail.aadhar_img_1) ?
                                                        <img
                                                            crossOrigin="anonymous"
                                                            src={BASE_URL + `/uploads/adminUsers/${Base64logoaadhar_img_1}`}
                                                            alt="Default Logo"
                                                            className="w-full h-auto rounded-[50%]"
                                                        />
                                                        :
                                                        <img
                                                            crossOrigin="anonymous"
                                                            src={BASE_URL + `/uploads/adminUsers/${OtherFormDetail.aadhar_img_1}`}
                                                            alt="Default Logo"
                                                            className="w-full h-auto rounded-[50%]"
                                                        />

                                                    }

                                                    <div className="absolute inset-0 bg-color_theme bg-opacity-50 rounded-[50%] flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                                                        <MdEdit className="text-white text-lg" />
                                                    </div>
                                                </div>
                                        )

                                    }

                                </Upload>
                                {/* Conditionally render the default image */}

                            </ImgCrop>

                        </div>
                        <div>

                            <label htmlFor="image" className="block text-md font-semibold text-zinc-700 mt-3 dark:text-stone-400 mb-2">
                                Aadhar Image 2<span className="text-[#f00]">*</span>
                            </label>
                            <ImgCrop
                                aspect={crop.aspect}
                                resize={crop.dimensions}
                                showGrid
                                rotationSlider
                                aspectSlider
                                showReset
                                onCrop={handleCrop}
                            >
                                  <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture-card"
                                    accept="image/*"
                                    fileList={AdharImage2}
                                    onChange={AdharImage2Change}
                                // onPreview={LogoPreview}
                                >

                                    {(AdharImage2 == undefined && AdharImage2?.length === 0) && !OtherFormDetail.aadhar_img_2 ? (
                                        <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Aadhar Image 2</span>
                                    )
                                        :

                                        (AdharImage2 == undefined || AdharImage2?.length === 0) && (

                                            (OtherFormDetail.aadhar_img_2 == null || OtherFormDetail.aadhar_img_2 == "") ?
                                                <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Aadhar Image 2</span>

                                                :

                                                <div className="relative group">
                                                    {isBase64(OtherFormDetail.aadhar_img_2) ?
                                                        <img
                                                            crossOrigin="anonymous"
                                                            src={BASE_URL + `/uploads/adminUsers/${Base64logoaadhar_img_2}`}
                                                            alt="Default Logo"
                                                            className="w-full h-auto rounded-[50%]"
                                                        />
                                                        :
                                                        <img
                                                            crossOrigin="anonymous"
                                                            src={BASE_URL + `/uploads/adminUsers/${OtherFormDetail.aadhar_img_2}`}
                                                            alt="Default Logo"
                                                            className="w-full h-auto rounded-[50%]"
                                                        />

                                                    }

                                                    <div className="absolute inset-0 bg-color_theme bg-opacity-50 rounded-[50%] flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                                                        <MdEdit className="text-white text-lg" />
                                                    </div>
                                                </div>
                                        )

                                    }

                                </Upload>
                                {/* Conditionally render the default image */}

                            </ImgCrop>
                       
                        </div>


                    </div>
                    <div className="border-t-2 flex justify-between border-dashed mt-9 ml-3 ">

                        <div className="mt-3 w-[50%]">
                            <label htmlFor="image" className="block text-md font-semibold text-zinc-700 mt-3 dark:text-stone-400 mb-2">
                                Voter ID No.<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="Voter id"
                                required
                                value={OtherFormDetail.voter_no}
                                onChange={(e) => {
                                    setotherDetail({
                                        ...OtherFormDetail,
                                        voter_no: e.target.value,
                                    });
                                }}
                                placeholder="Voter id"
                                id="Voter id"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full mt-5 text-sm font-medium rounded-md"
                            />

                        </div>
                        <div>
                            <label htmlFor="image" className="block text-md font-semibold text-zinc-700 mt-3 dark:text-stone-400 mb-2">
                                Voter ID Image 1<span className="text-[#f00]">*</span>
                            </label>

                            {/* {imageurl && <img className="w-[50%]" src={imageurl} alt="Selected" />} */}
                            <ImgCrop
                                aspect={crop.aspect}
                                resize={crop.dimensions}
                                showGrid
                                rotationSlider
                                aspectSlider
                                showReset
                                onCrop={handleCrop}
                            >
                                  <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture-card"
                                    accept="image/*"
                                    fileList={VoterId_1}
                                    onChange={voterId_1}
                                // onPreview={LogoPreview}
                                >

                                    {(VoterId_1 == undefined && VoterId_1?.length === 0) && !OtherFormDetail.voter_img_1 ? (
                                        <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Voter ID Image 1</span>
                                    )
                                        :

                                        (VoterId_1 == undefined || VoterId_1?.length === 0) && (

                                            (OtherFormDetail.voter_img_1 == null || OtherFormDetail.voter_img_1 == "") ?
                                                <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Voter ID Image 1</span>

                                                :

                                                <div className="relative group">
                                                    {isBase64(OtherFormDetail.voter_img_1) ?
                                                        <img
                                                            crossOrigin="anonymous"
                                                            src={BASE_URL + `/uploads/adminUsers/${Base64logvoter_img_1}`}
                                                            alt="Default Logo"
                                                            className="w-full h-auto rounded-[50%]"
                                                        />
                                                        :
                                                        <img
                                                            crossOrigin="anonymous"
                                                            src={BASE_URL + `/uploads/adminUsers/${OtherFormDetail.voter_img_1}`}
                                                            alt="Default Logo"
                                                            className="w-full h-auto rounded-[50%]"
                                                        />

                                                    }

                                                    <div className="absolute inset-0 bg-color_theme bg-opacity-50 rounded-[50%] flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                                                        <MdEdit className="text-white text-lg" />
                                                    </div>
                                                </div>
                                        )

                                    }

                                </Upload>
                                {/* Conditionally render the default image */}

                            </ImgCrop>
                        

                        </div>
                        <div>

                            <label htmlFor="image" className="block text-md font-semibold text-zinc-700 mt-3 dark:text-stone-400 mb-2">
                                Voter ID Image 2<span className="text-[#f00]">*</span>
                            </label>

                            {/* {imageurl && <img className="w-[50%]" src={imageurl} alt="Selected" />} */}
                            <ImgCrop
                                aspect={crop.aspect}
                                resize={crop.dimensions}
                                showGrid
                                rotationSlider
                                aspectSlider
                                showReset
                                onCrop={handleCrop}
                            >
                                  <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture-card"
                                    accept="image/*"
                                    fileList={VoterId_2}
                                    onChange={voterId_2}
                                // onPreview={LogoPreview}
                                >

                                    {(VoterId_2 == undefined && VoterId_2?.length === 0) && !OtherFormDetail.voter_img_2 ? (
                                        <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Voter ID Image 2</span>
                                    )
                                        :

                                        (VoterId_2 == undefined || VoterId_2?.length === 0) && (

                                            (OtherFormDetail.voter_img_2 == null || OtherFormDetail.voter_img_2 == "") ?
                                                <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Voter ID Image 2</span>

                                                :

                                                <div className="relative group">
                                                    {isBase64(OtherFormDetail.voter_img_2) ?
                                                        <img
                                                            crossOrigin="anonymous"
                                                            src={BASE_URL + `/uploads/adminUsers/${Base64logvoter_img_2}`}
                                                            alt="Default Logo"
                                                            className="w-full h-auto rounded-[50%]"
                                                        />
                                                        :
                                                        <img
                                                            crossOrigin="anonymous"
                                                            src={BASE_URL + `/uploads/adminUsers/${OtherFormDetail.voter_img_2}`}
                                                            alt="Default Logo"
                                                            className="w-full h-auto rounded-[50%]"
                                                        />

                                                    }

                                                    <div className="absolute inset-0 bg-color_theme bg-opacity-50 rounded-[50%] flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                                                        <MdEdit className="text-white text-lg" />
                                                    </div>
                                                </div>
                                        )

                                    }

                                </Upload>
                                {/* Conditionally render the default image */}

                            </ImgCrop>
                        
                        </div>


                    </div>
                    <div className="border-t-2 flex justify-between border-dashed mt-9 ml-3 ">

                        <div className="mt-3 w-[50%]">
                            <label htmlFor="image" className="block text-md font-semibold text-zinc-700 mt-3 dark:text-stone-400 mb-2">
                                Licence No.<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="Licence"
                                required
                                value={OtherFormDetail.licence_no}
                                onChange={(e) => {
                                    setotherDetail({
                                        ...OtherFormDetail,
                                        licence_no: e.target.value,
                                    });
                                }}
                                placeholder="Licence"
                                id="Licence"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full mt-5 text-sm font-medium rounded-md"

                            />

                        </div>

                        <div>
                            <label htmlFor="image" className="block text-md font-semibold text-zinc-700 mt-3 dark:text-stone-400 mb-2">
                                Licence Image 1<span className="text-[#f00]">*</span>
                            </label>

                            {/* {imageurl && <img className="w-[50%]" src={imageurl} alt="Selected" />} */}
                            <ImgCrop
                                aspect={crop.aspect}
                                resize={crop.dimensions}
                                showGrid
                                rotationSlider
                                aspectSlider
                                showReset
                                onCrop={handleCrop}
                            >
                                  <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture-card"
                                    accept="image/*"
                                    fileList={Licence_1}
                                    onChange={licence_1}
                                // onPreview={LogoPreview}
                                >

                                    {(Licence_1 == undefined && Licence_1?.length === 0) && !OtherFormDetail.voter_img_2 ? (
                                        <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] "> Licence Image 1</span>
                                    )
                                        :

                                        (Licence_1 == undefined || Licence_1?.length === 0) && (

                                            (OtherFormDetail.licence_img_1 == null || OtherFormDetail.licence_img_1 == "") ?
                                                <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] "> Licence Image 1</span>

                                                :

                                                <div className="relative group">
                                                    {isBase64(OtherFormDetail.licence_img_1) ?
                                                        <img
                                                            crossOrigin="anonymous"
                                                            src={BASE_URL + `/uploads/adminUsers/${Base64logolicence_img_1}`}
                                                            alt="Default Logo"
                                                            className="w-full h-auto rounded-[50%]"
                                                        />
                                                        :
                                                        <img
                                                            crossOrigin="anonymous"
                                                            src={BASE_URL + `/uploads/adminUsers/${OtherFormDetail.licence_img_1}`}
                                                            alt="Default Logo"
                                                            className="w-full h-auto rounded-[50%]"
                                                        />

                                                    }

                                                    <div className="absolute inset-0 bg-color_theme bg-opacity-50 rounded-[50%] flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                                                        <MdEdit className="text-white text-lg" />
                                                    </div>
                                                </div>
                                        )

                                    }

                                </Upload>
                                {/* Conditionally render the default image */}

                            </ImgCrop>
                          

                        </div>
                        <div>

                            <label htmlFor="image" className="block text-md font-semibold text-zinc-700 mt-3 dark:text-stone-400 mb-2">
                                Licence Image 2<span className="text-[#f00]">*</span>
                            </label>

                            {/* {imageurl && <img className="w-[50%]" src={imageurl} alt="Selected" />} */}
                            <ImgCrop
                                aspect={crop.aspect}
                                resize={crop.dimensions}
                                showGrid
                                rotationSlider
                                aspectSlider
                                showReset
                                onCrop={handleCrop}
                            >
                                  <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture-card"
                                    accept="image/*"
                                    fileList={Licence_2}
                                    onChange={licence_2}
                                // onPreview={LogoPreview}
                                >

                                    {(Licence_2 == undefined && Licence_2?.length === 0) && !OtherFormDetail.voter_img_2 ? (
                                        <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] "> Licence Image 2</span>
                                    )
                                        :

                                        (Licence_2 == undefined || Licence_2?.length === 0) && (

                                            (OtherFormDetail.licence_img_2 == null || OtherFormDetail.licence_img_2 == "") ?
                                                <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] "> Licence Image 2</span>

                                                :

                                                <div className="relative group">
                                                    {isBase64(OtherFormDetail.licence_img_2) ?
                                                        <img
                                                            crossOrigin="anonymous"
                                                            src={BASE_URL + `/uploads/adminUsers/${Base64logolicence_img_2}`}
                                                            alt="Default Logo"
                                                            className="w-full h-auto rounded-[50%]"
                                                        />
                                                        :
                                                        <img
                                                            crossOrigin="anonymous"
                                                            src={BASE_URL + `/uploads/adminUsers/${OtherFormDetail.licence_img_2}`}
                                                            alt="Default Logo"
                                                            className="w-full h-auto rounded-[50%]"
                                                        />

                                                    }

                                                    <div className="absolute inset-0 bg-color_theme bg-opacity-50 rounded-[50%] flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                                                        <MdEdit className="text-white text-lg" />
                                                    </div>
                                                </div>
                                        )

                                    }

                                </Upload>
                                {/* Conditionally render the default image */}

                            </ImgCrop>
                        </div>


                    </div>
                    <div className="border-t-2 flex gap-[13%] border-dashed mt-9 ml-3 ">

                        <div className="mt-3 w-[50%]">
                            <label htmlFor="image" className="block text-md font-semibold text-zinc-700 mt-3 dark:text-stone-400 mb-2">
                                PAN No.<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="PAN"
                                required
                                value={OtherFormDetail.pan_no}
                                onChange={(e) => {
                                    setotherDetail({
                                        ...OtherFormDetail,
                                        pan_no: e.target.value,
                                    });
                                }}
                                placeholder="Pan No. "
                                id="PAN"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full mt-5 text-sm font-medium rounded-md"

                            />

                        </div>


                        <div>
                            <label htmlFor="image" className="block text-md font-semibold text-zinc-700 mt-3 dark:text-stone-400 mb-2">
                                PAN Image <span className="text-[#f00]">*</span>
                            </label>

                            {/* {imageurl && <img className="w-[50%]" src={imageurl} alt="Selected" />} */}
                            <ImgCrop
                                aspect={crop.aspect}
                                resize={crop.dimensions}
                                showGrid
                                rotationSlider
                                aspectSlider
                                showReset
                                onCrop={handleCrop}
                            >
                                  <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture-card"
                                    accept="image/*"
                                    fileList={PanImage}
                                    onChange={panImage}
                                // onPreview={LogoPreview}
                                >

                                    {(PanImage == undefined && PanImage?.length === 0) && !OtherFormDetail.pan_img_1 ? (
                                        <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">PAN Image</span>
                                    )
                                        :

                                        (PanImage == undefined || PanImage?.length === 0) && (

                                            (OtherFormDetail.pan_img_1 == null || OtherFormDetail.pan_img_1 == "") ?
                                                <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">PAN Image</span>

                                                :

                                                <div className="relative group">
                                                    {isBase64(OtherFormDetail.pan_img_1) ?
                                                        <img
                                                            crossOrigin="anonymous"
                                                            src={BASE_URL + `/uploads/adminUsers/${Base64logopan_img_1}`}
                                                            alt="Default Logo"
                                                            className="w-full h-auto rounded-[50%]"
                                                        />
                                                        :
                                                        <img
                                                            crossOrigin="anonymous"
                                                            src={BASE_URL + `/uploads/adminUsers/${OtherFormDetail.pan_img_1}`}
                                                            alt="Default Logo"
                                                            className="w-full h-auto rounded-[50%]"
                                                        />

                                                    }

                                                    <div className="absolute inset-0 bg-color_theme bg-opacity-50 rounded-[50%] flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                                                        <MdEdit className="text-white text-lg" />
                                                    </div>
                                                </div>
                                        )

                                    }

                                </Upload>
                                {/* Conditionally render the default image */}

                            </ImgCrop>
                          

                        </div>



                    </div>




                    <div className="flex flex-col gap-y-3 mt-9 ml-3 ">

                        <div className="mt-3">
                            <label
                                htmlFor="Account"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Account No.<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="Account"
                                required
                                value={bankDetail.acc_no}
                                onChange={(e) => {
                                    setbankDetail({
                                        ...bankDetail,
                                        acc_no: e.target.value,
                                    });
                                }}
                                placeholder="Account No. "
                                id="Account"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>
                        <div>
                            <label
                                htmlFor="Ifsc"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Ifsc Code<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="Ifsc"
                                required
                                value={bankDetail.bank_ifsc}
                                onChange={(e) => {
                                    setbankDetail({
                                        ...bankDetail,
                                        bank_ifsc: e.target.value,
                                    });
                                }}
                                placeholder="Ifsc No. "
                                id="Ifsc"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>
                        <div>
                            <label
                                htmlFor="Bank"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Bank Name<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="Bank"
                                required
                                value={bankDetail.bank_name}
                                onChange={(e) => {
                                    setbankDetail({
                                        ...bankDetail,
                                        bank_name: e.target.value,
                                    });
                                }}
                                placeholder="Bank name "
                                id="Bank"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>
                        <div>
                            <label
                                htmlFor="Branch"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Branch Address<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="Branch"
                                required
                                value={bankDetail.branch_addr}
                                onChange={(e) => {
                                    setbankDetail({
                                        ...bankDetail,
                                        branch_addr: e.target.value,
                                    });
                                }}
                                placeholder="Branch Address "
                                id="Branch"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>
                        <div className="  mt-3  ">
                            <label htmlFor="image" className="block text-md font-semibold text-zinc-700 mt-3 dark:text-stone-400 mb-2">
                                Bank Passbook Image<span className="text-[#f00]">*</span>
                            </label>

                            {/* {imageurl && <img className="w-[50%]" src={imageurl} alt="Selected" />} */}
                            <ImgCrop
                                aspect={crop.aspect}
                                resize={crop.dimensions}
                                showGrid
                                rotationSlider
                                aspectSlider
                                showReset
                                onCrop={handleCrop}
                            >
                                  <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture-card"
                                    accept="image/*"
                                    fileList={Passbook}
                                    onChange={passbook}
                                // onPreview={LogoPreview}
                                >

                                    {(Passbook == undefined && Passbook?.length === 0) && !bankDetail.passbook_img ? (
                                        <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Bank Passbook Image</span>
                                    )
                                        :

                                        (Passbook == undefined || Passbook?.length === 0) && (

                                            (bankDetail.passbook_img == null || bankDetail.passbook_img == "") ?
                                                <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Bank Passbook Image</span>

                                                :

                                                <div className="relative group">
                                                    {isBase64(bankDetail.passbook_img) ?
                                                        <img
                                                            crossOrigin="anonymous"
                                                            src={BASE_URL + `/uploads/adminUsers/${Base64logoBank_pass_image}`}
                                                            alt="Default Logo"
                                                            className="w-full h-auto rounded-[50%]"
                                                        />
                                                        :
                                                        <img
                                                            crossOrigin="anonymous"
                                                            src={BASE_URL + `/uploads/adminUsers/${bankDetail.passbook_img}`}
                                                            alt="Default Logo"
                                                            className="w-full h-auto rounded-[50%]"
                                                        />

                                                    }

                                                    <div className="absolute inset-0 bg-color_theme bg-opacity-50 rounded-[50%] flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                                                        <MdEdit className="text-white text-lg" />
                                                    </div>
                                                </div>
                                        )

                                    }

                                </Upload>
                                {/* Conditionally render the default image */}

                            </ImgCrop>
                         
                        </div>
                    </div>
                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        {/* <Link
                            // to="../Employeemanagement"
                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                        >
                            <MdOutlineClose className="mr-1" />
                            Cancel
                        </Link> */}
                        {!addloadcity ?
                            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                                <MdSave className="mr-1" />
                                Save Employee
                            </button>
                            :
                            <button
                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                type="button"
                            //  onClick={()=> changeStep("4")}
                            >
                                <svg
                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Loading..
                            </button>
                        }

                    </div>
                </form>
            </div>
        </>
    );
});

