import React, { useState } from "react";
import { RxPencil1 } from "react-icons/rx";
import { Link, useParams } from "react-router-dom";
import { MdAdd, MdOrderingWatermark, MdLocationCity, MdOutlineDelete, MdOutlineGroup, MdRemoveRedEye, MdExpand, MdMoveUp, MdSwipeUp, MdArrowDropUp } from "react-icons/md";
import { observer } from "mobx-react";
import ReactPaginate from 'react-paginate';
import { OrderStore, useOrderStore, OrderStoreContext } from "./store/Orderstore";
import { useAuthStore } from "../Auth/store/Auth";
import { useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
// import { ExpandMore } from "@mui/icons-material";

import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import Moment from "react-moment";

import dem05 from "../assets/images/2.png"


const OrderDetail = () => {
    const { id } = useParams();

    const store = new OrderStore({
        OrderDetail: true,
        id: id
    });
    return (
        <OrderStoreContext.Provider value={store}>
            <Screen />
        </OrderStoreContext.Provider>
    )
}
export default OrderDetail;


const Screen = observer(() => {
    const {
        OrderProduct,
        Ordersummary,
        shipping_addr,
        billing_addr,
        ordernumberlist,
        Citypopup,
        BulkDelete,
        BulkstatusChange,
        ProductOrderLoader,
        // setallOrder,
        // allOrder,
        setPaginationPage,
        setalldeactivate,
        alldeactivate,
        getOrderList,
        getFilterOrder,
        TotalPages,
        setOrderpopup,
        Orderpopup,
        deletepopup,
        setdeletepopup,
        setdelateLoader,
        delateLoader,
        statusChange,
        setstatuspopup,
        getid,
        getstatus,
        deletefunction,
        setchecked,
        checkeddd,
        getToken
    } = useOrderStore();

    const { adminAuthApproved, admin_permissions } = useAuthStore();

    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);

    const { id, amount } = useParams();

    const [currentPage, setCurrentPage] = useState(0);
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [allOrder, setallOrder] = useState([])
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isatend, setisatend] = useState(false);
    const [isAtBeginning, setisAtBeginning] = useState(false);
    const [Imagelength, setImagelength] = useState("");
    const [Imagearray, setImagearray] = useState([]);

    const prevSlide = (image) => {
        setCurrentSlide((prev) => (prev === 0 ? image.length - 1 : prev - 1));
        setisAtBeginning(currentSlide === 0)

    };
    const handleImageError = (event) => {
        // This function will be called when the image fails to load
        event.target.src = dem05; // Replace with the path to your default image
    };

    const nextSlide = (image) => {
        console.log("nextslide", image);
        setCurrentSlide((prev) => (prev === image.length - 1 ? 0 : prev + 1));
        setisatend(currentSlide === image.length - 1)
    };
    // const [checkeddd, setchecked] = useState(false)

    const [photoIndex, setPhotoIndex] = useState("");
    const ITEMS_PER_PAGE = 10;
    const handlePageChange = ({ selected }) => {
        // setPaginationPage(selected);
        // getFilterOrder();
        // setCurrentPage(selected);
    };

    const handleClick = () => {
        const element = document.getElementById("tablescroll");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }
    const startIndex = currentPage * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const Orders = OrderProduct?.slice(startIndex, endIndex);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const openLightbox = (index) => {
        setLightboxIsOpen(true);
    };

    const closeLightbox = () => {
        setLightboxIsOpen(false);
    };
    console.log(billing_addr, shipping_addr);
    return (
        <>
            <div className="flex justify-between">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">Order {id}</p>
                    <p className=" opacity-80 text-sm">Home / Order / {id} </p>

                </div>
                <div>
                    <Link
                        to="../order"
                        className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                        Back
                    </Link>
                </div>
            </div>


            <div className="flex space-x-5">
                <div className="card bg-base-100 w-10/12 shadow-md p-6 mt-8">
                    <div className="overflow-x-auto ">
                        <table id="tablescroll" className="table table-zebra w-full">
                            <thead>
                                <tr>
                                    <th>Product Image</th>
                                    <th>Items summary</th>
                                    <th>Product Model</th>
                                    <th>QTY.</th>
                                    <th>Color</th>
                                    <th>Size</th>
                                    <th>Amount</th>
                                    {/* <th>Action</th> */}
                                </tr>
                            </thead>

                            <tbody className="text-sm relative">
                                {ProductOrderLoader &&
                                    <>
                                        <tr>
                                            <td colSpan={7} className="animate-pulse py-6"></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={7} className="animate-pulse py-6"></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={7} className="animate-pulse py-6"></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={7} className="animate-pulse py-6"></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={7} className="animate-pulse py-6"></td>
                                        </tr>
                                    </>
                                }
                                {
                                    (Orders?.length === 0 ? OrderProduct : Orders)?.map((data, index) => (
                                        <tr key={index} className="capitalize">
                                            {/* <td>

                                            <input
                                                value={data?.id}
                                                onChange={(e) => { setchecked(false); isallcheck(e.target.value); }}
                                                type="checkbox"
                                                name="myCheckbox"
                                            />
                                        </td> */}
                                            {

                                            }
                                            <td
                                                onClick={() => { setImagearray(data?.defaultImageDetail[0]); openLightbox() }}

                                            >
                                                <label
                                                    htmlFor="imagecarousel"
                                                    className=" flex space-x-[-5px] cursor-pointer"
                                                    title="Delete"

                                                >
                                                    {


                                                        <>

                                                            <img

                                                                onError={handleImageError}
                                                                alt="ProductCatalog Image" className="w-[55px] bg-white transition duration-300 hover:opacity-70 rounded-xl border-[2px] p-1 border-[#d7d7d7] cursor-pointer h-[55px]" crossOrigin="anonymous" src={BASE_URL + `/uploads/order/${data?.defaultImageDetail[0]?.image}`} />

                                                        </>

                                                    }





                                                </label>




                                            </td>

                                            <td>
                                                {data?.product_name ?? "N/A"}
                                            </td>
                                            <td>
                                                {data?.product_model ?? "N/A"}
                                            </td>




                                            <td>

                                                {data?.quantity}

                                            </td>


                                            <td>
                                                {data?.productOptionDetail.map((item, index) => (
                                                    item?.option_id == 2 &&
                                                    `${item?.option_value_name ?? "N/A"} ( ${data?.currency == "INR" ? "₹" : "$" } ${item?.price ?? "N/A"})`


                                                ))}


                                            </td>





                                            {data?.productOptionDetail > 0 ?
                                                <td>
                                                    {data?.productOptionDetail.map((item, index) => (

                                                        item?.option_id == 1 &&
                                                        `${item?.option_value_name ?? "N/A"} (   ${data?.currency == "INR" ? "₹" : "$" }  ${item?.price ?? "N/A"})`



                                                    ))}


                                                </td>
                                                :
                                                <td>
                                                    N/A
                                                </td>


                                            }









                                            <td>
                                            {data?.currency == "INR" ? "₹" : "$" }   {data.item_price}

                                            </td>



                                            {/* 
                                            <th >

                                                <div className="flex items-center space-x-3">

                                                    <button title="view detail">
                                                        <Link to={`./product/${data?.id}/${data?.name}`}>

                                                            <MdRemoveRedEye />

                                                        </Link>
                                                    </button>


                                                </div>

                                            </th> */}

                                        </tr>
                                    ))
                                }

                                {ordernumberlist &&
                                    <tr>
                                        <td colSpan={7} className="my-10 text-center">
                                            <MdOutlineGroup className=" text-[#dedede] text-8xl mx-auto" />
                                            <p className=" font-semibold text-lg">No Order Found</p>
                                        </td>
                                    </tr>}
                            </tbody>
                        </table>
                        {/* {!city?.length == 0 && */}

                        {/* <ReactPaginate
                        initialPage={0}
                        pageCount={Math.ceil(TotalPages)}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        onClick={handleClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}

                    /> */}
                        {/* } */}

                        {/* status change model start */}
                        {/* <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status ?
              </h3>
              <div className="modal-action justify-center">
                      <label
                        htmlFor="my-modal-5"
                        className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                        onClick={() => statusChange()}
                      >
                        Yes
                      </label> 
                <label htmlFor="my-modal-5" className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                  No
                </label>
              </div>
            </div>
          </div> */}

                        {lightboxIsOpen &&
                            <>
                                <input type="checkbox" id="imagecarousel" className="modal-toggle" />
                                <div className="modal w-full">
                                    <div className="modal-box relative">
                                        <label

                                            className="btn btn-sm btn-circle absolute right-2 top-2"
                                            onClick={() => { setdelateLoader(false); setCurrentSlide(0); setLightboxIsOpen(false) }}
                                        >
                                            ✕
                                        </label>
                                        <div className="modal-action justify-center">

                                            <div className="carousel w-full">
                                                {/* {Imagearray.map((image, i) => ( */}
                                                <div
                                                    // key={i}
                                                    // id={`slide${i}`}
                                                    className={`carousel-item relative w-full `}
                                                >
                                                    <img
                                                        onError={handleImageError}
                                                        crossOrigin="anonymous" src={BASE_URL + `/uploads/order/${Imagearray.image}`} className="w-full" />
                                                </div>
                                                {Imagearray.length > 1 &&
                                                    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">

                                                        <button disabled={isAtBeginning} onClick={() => { prevSlide(Imagearray); setImagelength(Imagearray); }} className={`btn btn-circle`}>
                                                            ❮
                                                        </button>

                                                        <button disabled={isatend} onClick={() => { nextSlide(Imagearray); setImagelength(Imagearray); }} className={`btn btn-circle`}>
                                                            ❯
                                                        </button>



                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>

                        }
                        {/* status change model end */}
                        {deletepopup &&
                            <>
                                <input type="checkbox" id="deletecity" className="modal-toggle" />
                                <div className="modal">
                                    <div className="modal-box relative">
                                        <label

                                            className="btn btn-sm btn-circle absolute right-2 top-2"
                                            onClick={() => { setdelateLoader(false); setdeletepopup(false) }}
                                        >
                                            ✕
                                        </label>
                                        <h3 className="text-lg font-bold text-center">
                                            Are you sure you want to Delete this Order
                                        </h3>
                                        <div className="modal-action justify-center">
                                            {/* <label
                                            htmlFor="deletecity"
                                            className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                            onClick={() => deletefunction()}
                                        >
                                            Yes
                                        </label> */}
                                            {!delateLoader ?
                                                <label

                                                    className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                                    onClick={() => deletefunction()}
                                                >
                                                    Yes
                                                </label> :
                                                <button
                                                    // type="submit"
                                                    disabled
                                                    className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                                                // onClick={() => {
                                                //   PostMatchscore();
                                                // }}
                                                >
                                                    Yes..
                                                    <svg
                                                        className="animate-spin mr-1 h-5 w-5 text-white"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            className="opacity-25"
                                                            cx="12"
                                                            cy="12"
                                                            r="10"
                                                            stroke="currentColor"
                                                            strokeWidth="4"
                                                        ></circle>
                                                        <path
                                                            className="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                        ></path>
                                                    </svg>
                                                </button>}
                                            <label
                                                onClick={() => setdeletepopup(false)}
                                                className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </>

                        }
                        {Orderpopup &&
                            <>
                                <input type="checkbox" id="my-modal-5" className="modal-toggle" />
                                <div className="modal">
                                    <div className="modal-box relative">
                                        <label

                                            className="btn btn-sm btn-circle absolute right-2 top-2"
                                            onClick={() => setOrderpopup(false)}
                                        >
                                            ✕
                                        </label>
                                        <h3 className="text-lg font-bold text-center">
                                            Are you sure you want to change Status ?
                                        </h3>
                                        <div className="modal-action justify-center">
                                            {!ProductOrderLoader ? <label

                                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                                onClick={() => statusChange()}
                                            >
                                                Yes
                                            </label> :
                                                <button
                                                    // type="submit"
                                                    disabled
                                                    className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                                                // onClick={() => {
                                                //   PostMatchscore();
                                                // }}
                                                >
                                                    Yes..
                                                    <svg
                                                        className="animate-spin mr-1 h-5 w-5 text-white"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            className="opacity-25"
                                                            cx="12"
                                                            cy="12"
                                                            r="10"
                                                            stroke="currentColor"
                                                            strokeWidth="4"
                                                        ></circle>
                                                        <path
                                                            className="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                        ></path>
                                                    </svg>
                                                </button>}

                                            <label
                                                onClick={() => setOrderpopup(false)}
                                                // htmlFor="voidstatus"
                                                className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                                            >
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </>

                        }
                    </div>
                </div>
                <div className="card bg-base-100 w-3/12 shadow-md p-6 mt-8">
                    <div className="flex justify-center">
                        <p className=" text-xl pb-3 font-medium border-b-[1px] border-dashed">Order Summary</p>
                    </div>
                    <div className="flex  justify-between mt-4">
                        <div>
                            <p className="font-semibold">Added On:</p>
                        </div>
                        <div>
                            <Moment
                                format={
                                    "DD/MM/YYYY"
                                }
                            >
                                {Ordersummary.order_date_time}
                            </Moment>
                        </div>
                    </div>
                    <div className="flex justify-between mt-2">
                        <div>
                            <p className="font-semibold">Order Status:</p>
                        </div>
                        <div>
                            <p>{Ordersummary?.order_status}</p>
                        </div>
                    </div>
                    <div className="flex justify-between mt-2">
                        <div>
                            <p className="font-semibold">Cart Total:</p>
                        </div>
                        <div>
                            <p>     {Ordersummary?.currency == "INR" ? "₹" : "$" }  {amount}</p>
                        </div>
                    </div>



                </div>


            </div>
            <div className="flex   space-x-3 " >
                <div className="mt-9 w-1/4">
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<MdArrowDropUp />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >

                            Shiipping Address
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="flex  justify-between mt-4">
                                <div>
                                    <p className="font-semibold">Contact Name:</p>
                                </div>
                                <div>

                                    {shipping_addr?.addr_name}

                                </div>
                            </div>
                            <div className="flex  justify-between mt-4">
                                <div>
                                    <p className="font-semibold">Address 1:</p>
                                </div>
                                <div>

                                    {shipping_addr?.addr_address1}

                                </div>
                            </div>
                            <div className="flex  justify-between mt-4">
                                <div>
                                    <p className="font-semibold">Address 2:</p>
                                </div>
                                <div>

                                    {shipping_addr?.addr_address2 == "" ? "n/a" : shipping_addr?.addr_address2}

                                </div>
                            </div>
                            <div className="flex  justify-between mt-4">
                                <div>
                                    <p className="font-semibold">Country:</p>
                                </div>
                                <div>

                                    {shipping_addr?.addr_country_name == "" ? "n/a" : shipping_addr?.addr_country_name}

                                </div>
                            </div>
                            <div className="flex  justify-between mt-4">
                                <div>
                                    <p className="font-semibold">State:</p>
                                </div>
                                <div>

                                    {shipping_addr?.addr_state_name == "" ? "n/a" : shipping_addr?.addr_state_name}

                                </div>
                            </div>
                            <div className="flex  justify-between mt-4">
                                <div>
                                    <p className="font-semibold">City:</p>
                                </div>
                                <div>

                                    {shipping_addr?.addr_city == "" ? "n/a" : shipping_addr?.addr_city}

                                </div>
                            </div>
                            <div className="flex  justify-between mt-4">
                                <div>
                                    <p className="font-semibold">Zipcode:</p>
                                </div>
                                <div>

                                    {shipping_addr?.addr_zip == "" ? "n/a" : shipping_addr?.addr_zip}

                                </div>
                            </div>


                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="mt-9 w-1/4">
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<MdArrowDropUp />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >

                            Billing Address
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="flex  justify-between mt-4">
                                <div>
                                    <p className="font-semibold">Contact Name:</p>
                                </div>
                                <div>

                                    {billing_addr?.addr_name}

                                </div>
                            </div>
                            <div className="flex  justify-between mt-4">
                                <div>
                                    <p className="font-semibold">Address 1:</p>
                                </div>
                                <div>

                                    {billing_addr?.addr_address1}

                                </div>
                            </div>
                            <div className="flex  justify-between mt-4">
                                <div>
                                    <p className="font-semibold">Address 2:</p>
                                </div>
                                <div>

                                    {billing_addr?.addr_address2 == "" ? "n/a" : billing_addr?.addr_address2}

                                </div>
                            </div>
                            <div className="flex  justify-between mt-4">
                                <div>
                                    <p className="font-semibold">Country:</p>
                                </div>
                                <div>

                                    {billing_addr?.addr_country_name == "" ? "n/a" : billing_addr?.addr_country_name}

                                </div>
                            </div>
                            <div className="flex  justify-between mt-4">
                                <div>
                                    <p className="font-semibold">State:</p>
                                </div>
                                <div>

                                    {billing_addr?.addr_state_name == "" ? "n/a" : billing_addr?.addr_state_name}

                                </div>
                            </div>
                            <div className="flex  justify-between mt-4">
                                <div>
                                    <p className="font-semibold">City:</p>
                                </div>
                                <div>

                                    {billing_addr?.addr_city == "" ? "n/a" : billing_addr?.addr_city}

                                </div>
                            </div>
                            <div className="flex  justify-between mt-4">
                                <div>
                                    <p className="font-semibold">Zipcode:</p>
                                </div>
                                <div>

                                    {billing_addr?.addr_zip == "" ? "n/a" : billing_addr?.addr_zip}

                                </div>
                            </div>


                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>


        </>
    );
});

