import { observer } from "mobx-react-lite";
import React from "react";
import { useEffect } from "react";
import { MdFlag, MdRemoveRedEye ,MdAdd} from "react-icons/md";
import { RxPencil1 } from "react-icons/rx";
import { Link, useParams } from "react-router-dom";
import Filter from "./DivisonFilter/Filter";
import {
  useDivisionStore,
  DivisionStore,
  DivisionStoreContext,
} from "./store/DivisionStore";
import { useAuthStore } from "../Auth/store/Auth";
const PlayerList = () => {
  const store = new DivisionStore({
    isloading: true,
    playerlist: false,
    Divison: true,
  });
  return (
    <DivisionStoreContext.Provider value={store}>
      <Screen />
    </DivisionStoreContext.Provider>
  );
};
export default PlayerList;

const Screen = observer(() => {
  const { playerlist, loadDivision, nolist, getPlayerList,getToken} =
    useDivisionStore();
    const { adminAuthApproved } = useAuthStore();
  const { id } = useParams();

  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }

  }, [adminAuthApproved]);

  useEffect(() => {
    // if (saved) {
    //   navigate("../leaguemanagement")
    // }
    getPlayerList(id);
  }, []);

  const leagueName= playerlist[0]?.league?.name

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">
            Player List Of {leagueName}{" "}

             <span className="text-sm font-bold">
                 {playerlist?.map((data, index) => data?.code)}
            </span>{" "}
          </p>
          {/* <p className=" opacity-80 text-sm">Home / Division/ Player List</p> */}
        </div>
        <div>
        <Link to="../divisionmanagement" className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]">Back</Link>
      </div>
      </div>
      {/* <Filter /> */}
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Partner</th>
                <th>Level</th>
                <th>City</th>
                {/* <th>View Players</th> */}
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {loadDivision && (
                <>
                  <tr>
                    <td colSpan={5} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={5} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={5} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={5} className="animate-pulse py-6"></td>
                  </tr>
                </>
              )}
              {playerlist[0]?.players.map((data, index) => (
                <tr key={index} className="capitalize">
                  <td>{index + 1}</td>
                  <td>
                  <div className="flex items-center space-x-3">
                      <div>
                        <div className="font-bold capitalize">
                      {data?.user?.firstName + " " + data?.user?.lastName}
                        </div>
                        <div className="text-sm">{data?.user?.email}</div>
                      </div>
                    </div>
                  </td>
               
                 
                  <td>
                  <div className="flex items-center space-x-3">
                      <div>
                        <div className="font-bold capitalize">
                        {data?.mate !== null ? data?.mate?.firstName +" "+ data?.mate?.lastName : "-" }
                        </div>
                        <div className="text-sm">{data?.mate?.email}</div>
                      </div>
                    </div>
              
                  </td>
                  <td>
                    {playerlist[0]?.level?.name
                      ? playerlist[0]?.level?.name.toFixed(1) ?? "N/A"
                      : "N/A"}
                  </td>
                  <td>
                    {playerlist[0]?.city?.city_name
                      ? playerlist[0]?.city?.city_name ?? "N/A"
                      : "N/A"}
                  </td>
                </tr>
              ))}
              {nolist && (
                <tr>
                  <td colSpan={5} className="my-10 text-center">
                    <MdFlag className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">No Player Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
});
