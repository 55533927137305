import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  MdOutlineClose,
  MdOutlineCheck,
  MdOutlineRefresh,
  MdOutlineSearch,
} from "react-icons/md";
import { Input, Button } from "react-daisyui";
// import { useCityStore } from "../store/CityStore";
import { observer } from "mobx-react";
import { useProductOptionsStore } from "../Store/ProductOptionsStore";

const Filter = observer(() => {
  const {
    getProductOptionname,
    SetfilterSearch,
    getFilterProductOptions,
    getProductOptionList,
    allclear,
    ProductOptionname,
  } = useProductOptionsStore();
  return (
    <>
      <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
        <form
          onSubmit={(e) => {
            getFilterProductOptions();
            e.preventDefault();
          }}
          className=" flex flex-row justify-between  "

        >
          <div className="w-6/12">
            <div>
        
              <input
                name="ProductOptionname"
                onChange={(e) => SetfilterSearch(e.target.value)}
                placeholder="Search By Product  name "
                id="ProductOptionname"
                type="text"
                autoComplete="off"
                className="input focus:outline-0 w-full text-sm font-medium rounded-md capitalize focus:outline-offset-0 input-bordered"
              />
            </div>
          
          </div>
          <div >
            <button
              type="reset"
              onClick={() => {
                allclear();
                // getProductOptionList();
              }}
              className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
            >
              <MdOutlineRefresh className="mr-1" />
              Reset
            </button>
            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
              <MdOutlineSearch className="mr-1" />
              Search
            </button>
          </div>
        </form>
      </div>
    </>
  );
});
export default Filter;
