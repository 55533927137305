import React, { useState } from "react";
import { RxPencil1 } from "react-icons/rx";
import { Link, useParams } from "react-router-dom";
import { MdAdd, MdProductTagingWatermark, MdLocationCity, MdOutlineDelete, MdOutlineGroup, MdCancel } from "react-icons/md";
import { observer } from "mobx-react";
import Filter from "./ProductTagFilter/Filter";
import ReactPaginate from 'react-paginate';
import { ProductTagStore, useProductTagStore, ProductTagStoreContext } from "./Store/ProductTagStore";
import { useAuthStore } from "../Auth/store/Auth";
import { useEffect } from "react";
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import { toast, ToastContainer } from "react-toastify";

const ProductTagProduct = () => {
    const { id } = useParams();

    const store = new ProductTagStore({
        Productlist: true,
        id: id
    });
    return (
        <ProductTagStoreContext.Provider value={store}>
            <Screen />
        </ProductTagStoreContext.Provider>
    )
}
export default ProductTagProduct;


const Screen = observer(() => {
    const {
        ProductCatalog,
        ProductTag,
        nolist,
        ProductTagMapList,
        ProductTagLoader,
        ProductProductTagLoader,
        ProductCatalogLoader,
        Productlist,
        DuplicateProduct,
        savedfun,
        deleteProductProductTag,
        ProductTagMapping,
        addProductProductTag,
        ProductTagpopup,
        deletepopup,
        setdeletepopup,
        setdelateLoader,
        delateLoader,
        statusChange,
        deletefunction,
        getToken
    } = useProductTagStore();

    const { adminAuthApproved, admin_permissions } = useAuthStore();
    const { id, name } = useParams();
    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);

    const [searchQueriesList, setSearchQueriesList] = useState(Array(ProductCatalog.length).fill(''));
    const [filteredItemsList, setFilteredItemsList] = useState(Array(ProductCatalog.length).fill([]));
    const [selectedOptionsList, setSelectedOptionsList] = useState(Array(ProductCatalog.length).fill([]));
    const [showList, setShowList] = useState(Array(ProductCatalog.length).fill(false));
    const [isListVisible, setListVisibility] = useState(false);
    const [value, setValue] = useState("Select option...");
    const [Query, setQuery] = useState("");

    const [categoryvalue, setcategoryvalue] = useState("Select option...");
    console.log("producttag", ProductTag);
    const [focusedProductIndex, setFocusedProductIndex] = useState(null);



    const handleBlur = (index) => {
        // Set the list visibility to false when the input loses focus
        setShowList((prev) => {
            const updatedShowList = [...prev];
            updatedShowList[index] = false;
            return updatedShowList;
        });

        // Additional code...
    };
    const handleSelect = (item, option_id, tag_id, index) => {
        console.log("itemmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm", item);
        ProductTagMapping(option_id, tag_id)
            .then(() => {
                setSelectedOptionsList((prevOptionsList) => {
                    const updatedOptionsList = [...prevOptionsList];

                    // Check if prevOptionsList[index] is falsy, and initialize it with an empty array
                    updatedOptionsList[index] = prevOptionsList[index] ? [...prevOptionsList[index], item] : [item];

                    return updatedOptionsList;
                });
            })
            .catch(error => {
                console.log("itemmmmmmmmmmmmmm", item);
                // Handle the error if needed
                console.error("Error in addProductProductTag:", error);
            });


        // Additional code...
        // setSearchQuery('');
        setFilteredItemsList([]);
    };
    const handleRemove = (index, selectedIndex, option_id, tag_id, slug) => {
        console.log("index, selectedIndex, option_id, tag_id", index, selectedIndex, option_id, tag_id);
        slug == "edit" ?

            setSelectedOptionsList((prevOptionsList) => {
                const updatedOptionsList = [...prevOptionsList];
                const updatedOptions = [...updatedOptionsList[index]];

                // Remove the selected option at the given index
                updatedOptions.splice(selectedIndex, 1);

                // Update the selected options for the corresponding index
                updatedOptionsList[index] = updatedOptions;

                // Call your delete function here if needed
                deletefunction(option_id, tag_id);

                return [...updatedOptionsList]; // Use the spread operator to create a new array reference
            })
            :
            deletefunction(option_id, tag_id);

    };

    const handleFocus = (index) => {
        // Set the list visibility to true when the input is focused
        setShowList((prev) => {
            const updatedShowList = [...prev];
            updatedShowList[index] = true;
            return updatedShowList;
        });

        // Set the focused product index
        setFocusedProductIndex(index);

        // Initial filtering to show the entire list
        setFilteredItemsList((prev) => {
            const updatedFilteredItemsList = [...prev];
            updatedFilteredItemsList[index] = ProductTag.map(item => item);
            return updatedFilteredItemsList;
        });

        // Set the search query to the corresponding index
        setSearchQueriesList((prev) => {
            const updatedSearchQueriesList = [...prev];
            updatedSearchQueriesList[index] = '';
            return updatedSearchQueriesList;
        });

        // Set the initial selected options for the corresponding index
        // setSelectedOptionsList((prev) => {
        //     const updatedSelectedOptions = [...prev];
        //     updatedSelectedOptions[index] = [];
        //     return updatedSelectedOptions;
        // });
    };
    const handleSearch = (query, index) => {
        const filtered = ProductTag.map(item => item).filter((item) =>
            item.tag_name.toLowerCase().includes(query.toLowerCase())
        );
        setQuery(query);  // Update the Query state with the input value

        setFilteredItemsList((prev) => {
            const updatedFilteredItemsList = [...prev];
            updatedFilteredItemsList[index] = filtered;
            return updatedFilteredItemsList;
        });

        setSearchQueriesList((prev) => {
            const updatedSearchQueriesList = [...prev];
            updatedSearchQueriesList[index] = query;
            return updatedSearchQueriesList;
        });

        setShowList((prev) => {
            const updatedShowList = [...prev];
            updatedShowList[index] = true;
            return updatedShowList;
        });
    };
    const handleAddTag = (query) => {
        addProductProductTag(query);
        // Additional code...
        // setSearchQuery('');
        setFilteredItemsList([]);
    };
    { console.log("optionnnnnnnnnnnnnnnnnnnnnnnnnnnnnn", typeof (ProductTagMapList)) }

    return (
        <>
            <div className="flex justify-between">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">{name} ProductTag </p>
                    <p className=" opacity-80 text-sm">Home / ProductTag / Product list </p>
                </div>
                {/* <div>
                    <Link
                        to="../ProductTags"
                        className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                        Back
                    </Link>
                </div> */}
            </div>
            <Filter />

            <div className="card flex space-x-3 bg-base-100 shadow-md p-6 mt-8">
                <div className="overflow-x-auto ">

                    <div className="flex  mb-4">
                        <div className="mb-4 w-1/2">
                            <p className="text-xl font-semibold">Product Name </p>
                        </div>
                        <div className="mb-4 w-1/2">
                            <p className="text-xl  font-semibold">Product tags </p>
                        </div>
                    </div>
                    {(ProductCatalogLoader && ProductProductTagLoader) &&
                        <>
                            <svg
                                className="animate-spin  mr-0 ml-[45%] mb-[3%] h-[5%] w-[5%] text-color_theme"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                        </>
                    }
                    <table id="tablescroll" className="table  w-full">
                        {/* <thead>
                            <tr>
                                <th></th>
                                <th></th>/

                            </tr>
                        </thead> */}

                        <tbody className="text-sm relative">


                            {
                                ProductCatalog?.map((option, index) => (
                                    <tr key={index} className="capitalize">
                                        {console.log("selectedOptionsList[index] ", selectedOptionsList[index])}

                                        {/* <td>

                                            <input
                                                value={data?.id}
                                                onChange={(e) => { setchecked(false); isallcheck(e.target.value); }}
                                                type="checkbox"
                                                name="myCheckbox"
                                            />
                                        </td> */}


                                        <td>
                                            {option.product ?? "N/A"}
                                        </td>
                                        <td>
                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    onFocus={() => handleFocus(index)}
                                                    onBlur={handleBlur}
                                                    value={searchQueriesList[index]}
                                                    onChange={(e) => handleSearch(e.target.value, index)}
                                                    className="p-3 pl-8 rounded w-full border border-gray-200 focus:bg-white focus:outline-none"
                                                    placeholder="Type to search..."
                                                />
                                                <svg
                                                    className="w-4 h-4 absolute left-2.5 top-3.5"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                                    />
                                                </svg>
                                                {filteredItemsList[index]?.length === 0 && (
                                                    <button onClick={() => handleAddTag(Query)} className="p-3 bg-color_theme text-white flex justify-end mt-2">
                                                        Add Tag
                                                    </button>
                                                )}
                                            </div>
                                            <div className="flex flex-wrap mt-1 gap-2">
                                                {selectedOptionsList[index]?.map((options, selectedIndex) => (
                                                    <div key={selectedIndex} className="bg-blue-100 p-1 rounded flex items-center">
                                                        {options.tag_name}
                                                        <button
                                                            type="button"
                                                            className="ml-2 text-red-500 hover:text-red-700"
                                                            onClick={() => handleRemove(index, selectedIndex, option?.record_id, options.id, "edit")}
                                                        >
                                                            <MdCancel />
                                                        </button>
                                                    </div>
                                                ))}

                                                {Object.keys(ProductTagMapList)?.map((mapIndex, tagindex) => {
                                                    console.log("indexxxxxxxxxxxxxxxxxxxxxxxxxxxx", tagindex, index)
                                                    if (tagindex == index) {
                                                        return ProductTagMapList[mapIndex]?.tags.map((tags, tagIndex) => (
                                                            <div key={tagIndex} className="bg-blue-100 p-1 rounded flex items-center">
                                                                {tags?.tag_name}
                                                                <button
                                                                    type="button"
                                                                    className="ml-2 text-red-500 hover:text-red-700"
                                                                    onClick={() => handleRemove(index, tagIndex, option?.record_id, tags?.tag_id, "default")}
                                                                >
                                                                    <MdCancel />
                                                                </button>
                                                            </div>
                                                        ));
                                                    }
                                                    return null;
                                                })}

                                            </div>

                                            {showList[index] && (
                                                <div className="list-container mt-2 border border-gray-100 overflow-y-auto max-h-40">
                                                    <ul className="bg-white">
                                                        {filteredItemsList[index]?.map((item, filterIndex) => (
                                                            <li
                                                                key={filterIndex}
                                                                onClick={() => handleSelect(item || item, option?.record_id, item.id, index)}
                                                                className="pl-8 pr-2 py-1 border-b-2 border-gray-100 relative cursor-pointer hover:bg-yellow-50 hover:text-gray-900"
                                                            >
                                                                {`${item.tag_name || item.tag_name}`}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </td>

                                    </tr>
                                ))
                            }


                            {nolist &&
                                <tr>
                                    <td colSpan={5} className="my-10 text-center">
                                        <MdOutlineGroup className=" text-[#dedede] text-8xl mx-auto" />
                                        <p className=" font-semibold text-lg">No Products Found</p>
                                        <Link
                                            to="/productcatalog/addproductcatalog"
                                            className="btn btn-sm mt-4 bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                                        >
                                            <MdAdd />
                                            Add Product
                                        </Link>
                                    </td>
                                </tr>}
                        </tbody>
                    </table>



                </div>

            </div>


        </>
    );
});

