import React, { useCallback, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdImageAspectRatio, MdImage } from "react-icons/md";
import { Input, Button, Select, Textarea } from "react-daisyui";
// import { CityStore, CityStoreContext } from "./store/CityStore";
// import { AdminUseStore, useAdminUsedStore, AdminUserStoreContext } from "./store/AdminUsersStore";
import { useWebsiteStore, WebsiteStore, WebsiteStoreContext } from "./store/WebsiteStore";
import { observer } from "mobx-react";
import { useAuthStore } from "../Auth/store/Auth";
import { useState } from "react";
import { RxEyeClosed, RxEyeOpen } from "react-icons/rx";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";

const EditNewPage = observer(() => {
    const { id } = useParams();

    return (
        <WebsiteStoreContext.Provider
            value={
                new WebsiteStore({
                    getOtherPageDetails: true,
                    id: id
                    // Editloading: true,
                })
            }
        >
            <Screen />
        </WebsiteStoreContext.Provider>
    );
});
export default EditNewPage;
const Screen = observer(() => {
    const { setnamevalue, getToken, EditWebsitePage ,updateOtherPageDetails ,SetDetailPageList , DetailPageList , OtherPage ,setdisplay, getusername, setemail, setalert, Confirmpassw, passwordChanged, errPassword, saved, EditloadWebsite,page_data ,setpage_data, setpagename, setmeta, setmeta_description } = useWebsiteStore();
    const { adminAuthApproved } = useAuthStore();
    const { id } = useParams();
    

    // const [crop, setCrop] = useState({ aspect: 16 / 9, dimensions: { width: 480, height: 270 } });
    // const { register, handleSubmit, errors } = useForm();

    const onSubmit = (data) => {
        // Handle form submission logic here
        console.log(data);
    };
    const onChange = 
        (newContent) => {
            // const strippedContent = newContent.replace(/<[^>]*>/g, '');
            setpage_data(newContent);
        }
       
    

    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);
    const navigate = useNavigate();


    useEffect(() => {
        if (saved) {
            navigate(-1);
        }
    }, [saved]);

   

    const handleSubmit = (e) => {

        e.preventDefault();
        updateOtherPageDetails(id);
        // Perform API call or any other logic using MobX store data
        // Example: EditCity();
    };



    return (
        <>
            <div className="flex justify-between">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold"> Edit Website pages </p>
                    <p className=" opacity-80 text-sm">Home / Website / Edit page </p>
                </div>
                <div>

                    <Link
                        onClick={() => navigate(-1)}
                        className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                        Back
                    </Link>
                </div>
            </div>
            <div className="card bg-base-100 shadow-md p-6 mt-8">
                <form onSubmit={handleSubmit}>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">

                        <div>
                            <label
                                htmlFor="name"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Page name<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="Pagename"
                                required
                                placeholder="Pagename"
                                value={DetailPageList.page_name }
                                onChange={(e) => {
                                    SetDetailPageList({
                                        ...DetailPageList,
                                        page_name : e.target.value,
                                    });
                                }}
                                id="Pagename"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>
                        <div>
                            <label
                                htmlFor="name"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Meta title<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="Meta"
                                required
                                placeholder="Meta"
                                value={DetailPageList.meta_title}
                                onChange={(e) => {
                                    SetDetailPageList({
                                        ...DetailPageList,
                                        meta_title : e.target.value,
                                    });
                                }}
                                id="Meta"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>
                        <div>
                            <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Description<span className="text-[#f00]">&nbsp;*</span></label>
                            <Textarea
                                name="Description"
                                required
                                placeholder="Description....."
                                value={DetailPageList.meta_description}
                                onChange={(e) => {
                                    SetDetailPageList({
                                        ...DetailPageList,
                                        meta_description : e.target.value,
                                    });
                                }}
                                id="Description"
                                type="text"
                                rows={1}
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>



                    </div>

                    <div className="mb-5">
                        <label
                            htmlFor="Product"
                            className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                        >
                            Description
                        </label>
                        <JoditEditor
                            // ref={editor}
                            value={page_data}
                            tabIndex={5}
                            onChange={onChange}
                        />
                    </div>

                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <Link
                            onClick={() => navigate(-1)}

                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                        >
                            <MdOutlineClose className="mr-1" />
                            Cancel
                        </Link>
                        {!EditloadWebsite ?
                            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                                <MdOutlineCheck className="mr-1" />
                                Edit New Page
                            </button>
                            :
                            <button
                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                type="button"
                            //  onClick={()=> changeStep("4")}
                            >
                                <svg
                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Loading..
                            </button>
                        }

                    </div>
                </form>
            </div>
        </>
    );
});

