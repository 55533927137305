import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdImageAspectRatio, MdImage, MdSave } from "react-icons/md";
import { Input, Button, Select, Textarea } from "react-daisyui";
// import { CityStore, CityStoreContext } from "./store/CityStore";
import { WebsiteStore, WebsiteStoreContext, useWebsiteStore } from "./store/WebsiteStore";
import { observer } from "mobx-react";
import { useAuthStore } from "../Auth/store/Auth";
import { useState } from "react";
import ImgCrop from "antd-img-crop";
import { Upload } from 'antd';
import axios from "axios";
const SEO_URL = process.env.REACT_APP_SEO_URL;

const ContactPage = observer(() => {

    return (
        <WebsiteStoreContext.Provider
            value={
                new WebsiteStore({
                    isloading: true,
                   
                })
            }
        >
            <Screen />
        </WebsiteStoreContext.Provider>
    );
});
export default ContactPage;
const Screen = observer(() => {
    const { getstatus, setaddWebsitename, setContactPageContent, updateContactPageList, WebsiteLoadercirlce ,ContactPageListLoader ,ContactPageContent ,addWebsite, setCrop, setBannerDimiensions, getlogoimage, getbanner_image, crop, logoimage, errorMessages, addWebsitename, saved, getseourl, seoUrl, addloadcity, getToken } = useWebsiteStore();
    const { adminAuthApproved } = useAuthStore();
    const [logofile, setlogofile] = useState();
    const [bannerfile, setbannerfile] = useState();
    const [croppedImage, setCroppedImage] = useState(null);
    // const [crop, setCrop] = useState({ aspect: 16 / 9, dimensions: { width: 480, height: 270 } });
    // const { register, handleSubmit, errors } = useForm();



    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);
    const navigate = useNavigate();


    useEffect(() => {
        if (saved) {
            navigate("../contactpage");
        }
    }, [saved]);



    const handleSubmit = (e) => {
        e.preventDefault();
        updateContactPageList();
        // Perform API call or any other logic using MobX store data
        // Example: addCity();
    };


console.log(ContactPageContent);

    return (
        <>
            <div className="flex justify-between">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">Contact Page</p>
                    <p className=" opacity-80 text-sm">Home / Contact Page</p>
                </div>

            </div>
            {WebsiteLoadercirlce ?
                <>
                    <svg
                        className="animate-spin  mr-0 ml-[45%] mt-[15%] h-[5%] w-[5%] text-color_theme"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                </>
                :
                <div className="card bg-base-100 shadow-md p-6 mt-8">
                <form onSubmit={handleSubmit}>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                        <div>
                            <label
                                htmlFor="email"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Email id<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="email"
                                required
                                value={ContactPageContent?.email }
                                onChange={(e) => {
                                    setContactPageContent({
                                        ...ContactPageContent,
                                        email : e.target.value,
                                    });
                                }}
                                placeholder="email"
                                id="email"
                                type="email"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>
                        <div>
                            <label
                                htmlFor="mobile"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                mobile id<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="mobile"
                                required
                                value={ContactPageContent?.contact }
                                onChange={(e) => {
                                    setContactPageContent({
                                        ...ContactPageContent,
                                        contact : e.target.value,
                                    });
                                }}
                                placeholder="mobile"
                                id="mobile"
                                min={10}
                                // max={10}
                                type="number"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>
                        <div>
                            <label htmlFor="Fees" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Address<span className="text-[#f00]">&nbsp;*</span></label>
                            <Textarea
                                name="Address"
                                required
                                placeholder="Address....."
                                value={ContactPageContent?.address }
                                onChange={(e) => {
                                    setContactPageContent({
                                        ...ContactPageContent,
                                        address : e.target.value,
                                    });
                                }}
                                id="Address"
                                type="text"
                                rows={1}
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                    </div>


                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        {/* <Link
                            // to="../Websitemanagement"
                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                        >
                            <MdOutlineClose className="mr-1" />
                            Cancel
                        </Link> */}
                        {!ContactPageListLoader ?
                            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                                <MdSave className="mr-1" />
                                Save 
                            </button>
                            :
                            <button
                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                type="button"
                            //  onClick={()=> changeStep("4")}
                            >
                                <svg
                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Loading..
                            </button>
                        }

                    </div>
                </form>
            </div>
            }
        
        </>
    );
});

