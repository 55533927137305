import { createContext, useContext } from "react";
import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";
const SEO_URL = process.env.REACT_APP_SEO_URL;





export class BannerStore {

    Token = null;
    Banner = [];
    type = "";
    Bannertype = [];
    filterSearch = "";
    PaginationPage = "1";
    TotalPages = "";
    BannerDimiensions = ""
    crop = {};
    errLoadBanner = "";
    LoadBanner = false;
    delateLoader = false;
    nolist = false;
    Bannerpopup = false;
    deletepopup = false;
    Bannername = "";
    status = "1";
    statesid = "";
    Bannerid = [];
    seoUrl = "";
    statuspopup = false;
    addBannername = "";
    imageurl = "";
    logoimage = "";
    bannerimage = "";
    Base64logoImage = "";
    Base64BannerImage = "";
    logofile = [];
    allBanner = [];
    alldeactivate = 'false';
    isBannerUpdated = "false";
    isLogoUpdated = "false"
    checkeddd = '';
    BannerFormDetails = {
        "title": "",
        "section_name":"",
        "url": "",
        "section_id": "",
        "image_name": "",
        "is_active": ""
    };
    saved = false;

    constructor(isloading = false) {
        makeAutoObservable(this, {
            addBannername: observable,
            status: observable,
            errorMessages: observable,
            setaddBannername: action,
            getstatus: action,
            resetErrors: action,
        });

        this.Token = localStorage.getItem("Admintoken");
        if (isloading.isloading) {
            this.getBannerList();
            //   this.getStateList();
            return
        }
        if (isloading.addloading) {
            this.getBannerTypeList();
        }
        if (isloading.Bannereditloading) {
            this.getBannerform(isloading.id);
            this.getBannerTypeList();

            return
        }
    }
    //All Clear 
    allclear = () => {
        this.filterSearch = ""
        this.getFilterBanner();
        //   this.status="";
        //   this.statesid="";
    }
    setimage = (text, type) => {
        this.imageurl = URL.createObjectURL(text[0]);
    }

    setalldeactivate = (text) => {
        console.log(text);
        this.alldeactivate = !this.alldeactivate;
    };

    getid = (text) => {
        this.Bannerid.push(text);
    };



    // status popup
    setstatuspopup = () => {
        this.statuspopup = !this.statuspopup;
    };


    setallBanner = (text) => {
        this.allBanner = text;
    }

    // get status 
    getstatus = (text) => {
        this.status = text;
    };

    //get city name
    getBannername = (text) => {
        this.Bannername = text;
    };

    setaddBannername = (text) => {
        this.addBannername = text;
    };
    setCrop = (text) => {
        this.crop = text;
    };

    setBannerDimiensions = (text) => {
        this.BannerDimiensions = text;
    };

    setlogofile = (e) => {
        this.logofile = e;
        console.log(this.logofile);
    };

    setBannerpopup = (e) => {
        this.Bannerpopup = e;
    };
    setdeletepopup = (e) => {
        this.deletepopup = e;
    };
    setdelateLoader = (e) => {
        this.delateLoader = e;
    }
    getToken = (e) => {
        this.Token = e;
    }
    gettype = (e) => {
        this.type = e;
    }
    getseourl = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.seoUrl = text;
    };
    getlogoimage = (text) => {
        console.log("checking in the Banner", text);
        this.logoimage = text;
    };
    getbanner_image = (text) => {
        console.log(text);
        this.bannerimage = text;
    };
    SetfilterSearch = (text) => {
        this.filterSearch = text;
    };
    setPaginationPage = (text) => {
        this.PaginationPage = text;
    };
    SetTotalPages = (text) => {
        this.TotalPages = text;
    };
    setchecked = (text) => {
        this.checkeddd = text;
    };
    setisBannerUpdated = (text) => {
        this.isBannerUpdated = text;
    };
    setisLogoUpdated = (text) => {
        this.isLogoUpdated = text;
    };

    isBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };

    setBannerFormDetails = (BannerDetail) => {
        this.BannerFormDetails = {
            ...this.BannerFormDetails,
            title: BannerDetail.title || "", // Use default value if name is undefined
            url: BannerDetail.url || "", // Use default value if seo_url is undefined
            // logo_image: this.isBase64( this.BannerFormDetails.logo_image) ? this.logofile.length == 0 ? BannerDetail.logo_image    : this.BannerFormDetails.logo_image  : this.logofile.length !== 0 ? this.BannerFormDetails.logo_image :  BannerDetail.logo_image   || "",
            // banner_image: this.isBase64(BannerDetail.banner_image) ? this.BannerFormDetails.banner_image : BannerDetail.banner_image || "",
            image_name: BannerDetail.image_name || "",
            section_id: BannerDetail.section_id || "",
            section_name: BannerDetail.section_name || "",

            is_active: BannerDetail.is_active
        };
    };
    // setBannerFormDetails = (text) => {
    //     this.BannerFormDetails = text;
    //     console.log("setBannerFormDetails", this.BannerFormDetails.Bannername);
    // };
    base64toFile(base64Data, fileName, mimeType) {
        if (base64Data) {
            const byteCharacters = atob(base64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters?.length; offset += 512) {
                const slice = byteCharacters?.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const file = new File(byteArrays, fileName, { type: mimeType });

            return file;
        }

    }

    // get Banner list
    getBannerList = () => {
        runInAction(() => {
            this.BannerLoader = true;
            this.errLoadBanner = "";
            this.saved = false;
            this.nolist = false;
            this.Banner = [];
        });
        getMethodData(`/api/v1/admin/banner/get-list`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.BannerLoader = false;
                        this.errLoadBanner = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.BannerLoader = false;
                        this.errLoadBanner = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Banner = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadBanner = false;
                    this.errLoadBanner = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.BannerLoader = false;
                    if (!error.response) {
                        this.errLoadBanner = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadBanner = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    // get Banner type list
    getBannerTypeList = () => {
        runInAction(() => {
            this.BannerLoader = true;
            this.errLoadBanner = "";
            this.saved = false;
            this.nolist = false;
            this.Bannertype = [];
        });
        getMethodData(`/api/v1/admin/sections/auto-complete`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.BannerLoader = false;
                        this.errLoadBanner = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.BannerLoader = false;
                        this.errLoadBanner = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Bannertype = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadBanner = false;
                    this.errLoadBanner = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.BannerLoader = false;
                    if (!error.response) {
                        this.errLoadBanner = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadBanner = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    //Filter city
    // filter League court

    getFilterBanner = () => {
        runInAction(() => {
            this.LoadBanner = true;
            this.errLoadBanner = "";
            this.saved = false;
            this.nolist = false;
            this.getBannerList = [];
        });
        postMethodData(`/api/v1/admin/Banner/search?page=${this.PaginationPage}`, {
            keyword: this.filterSearch
        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.loadleague = false;
                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.loadcity = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Banner = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.loadleague = false;
                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    // add new Banner
    addBanner = () => {
        runInAction(() => {
            this.addloadBanner = true;
            this.errLoadBanner = "";
        });
        const formData = new FormData();

        if (this.bannerimage) {
            const Bannerbase64String = this.bannerimage.split(',')[1];
            const BannerFile = this.base64toFile(Bannerbase64String, 'logo.png', 'image/png');
            formData.append('banner_image', BannerFile);
        }
        else {
            formData.append('banner_image', null);
        }

        formData.append('title', this.addBannername);
        formData.append('url', `${SEO_URL}${this.seoUrl}`);
        formData.append('section_id', this.type);
        formData.append('status', this.status);



        postMethodData("/api/v1/admin/banner/add", formData, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.addloadBanner = false;
                    this.errLoadBanner = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.addloadBanner = false;
                    this.errLoadBanner = resp?.data?.message;
                    // toast.success(resp.data.message);
                    this.getBannerList();
                    this.saved = true;
                })
                return
            }
            runInAction(() => {
                this.addloadBanner = false;
                this.errLoadBanner = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadBanner = false;
                    if (!error.response) {
                        this.errLoadBanner = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadBanner = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    //Active and inactive
    //Active and inactive
    statusChange = () => {
        runInAction(() => {
            this.SectionLoader = true;
            this.Sectionpopup = true;

            this.errLoadSection = "";
        });
        postMethodData(`/api/v1/admin/banner/update-status`, {

            "record_id": this.Bannerid,
            "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.Sectionid = [];
                        this.Bannerid = [];

                        this.SectionLoader = false;
                        this.Bannerpopup = false;
                        this.errLoadSection = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.SectionLoader = false;
                        this.Bannerpopup = false;
                        this.errLoadSection = resp?.data?.message;
                        this.getBannerList();
                        this.Sectionid = [];
                        this.Bannerid = [];
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.SectionLoader = false;
                    this.Bannerpopup = false;

                    this.errLoadSection = resp?.data?.message;
                })
            })
            .catch((error) => {
                this.Bannerid = [];

                runInAction(() => {
                    if (!error.response) {
                        this.SectionLoader = false;
                        this.Bannerpopup = false;
                        this.Sectionid = [];
                        this.errLoadSection = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.SectionLoader = false;
                    this.Bannerpopup = false;
                    this.Sectionid = [];


                    this.errLoadSection = error?.response?.data?.message;
                })
                return;
            })
    };
    // Bulk Active and inactive
    BulkstatusChange = (allBanner) => {
        runInAction(() => {
            this.BannerLoader = true;
            this.Bannerpopup = true;

            this.errLoadBanner = "";
        });

        postMethodData(`/api/v1/admin/banner/update-status`, {

            "branchId": JSON.stringify(allBanner),
            "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.BannerLoader = false;
                        this.Bannerpopup = false;
                        this.errLoadBanner = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.BannerLoader = false;
                        this.Bannerpopup = false;

                        this.errLoadBanner = resp?.data?.message;
                        this.getFilterBanner();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.BannerLoader = false;
                    this.Bannerpopup = false;

                    this.errLoadBanner = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.BannerLoader = false;
                        this.Bannerpopup = false;

                        this.errLoadBanner = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.BannerLoader = false;
                    this.Bannerpopup = false;

                    this.errLoadBanner = error?.response?.data?.message;
                })
                return;
            })
    };

    //Delete method
    deletefunction = () => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errLoadBanner = "";
        });
        postMethodData(`/api/v1/admin/banner/delete`, {

            "record_id": this.Bannerid,

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.Bannerid = [];

                    this.LoadBanner = false;
                    this.setdelateLoader(true);
                    this.deletepopup = true;
                    this.errLoadBanner = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadBanner = false;
                    this.Bannerid = [];

                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    this.errLoadBanner = resp?.data?.message;
                    this.getBannerList();
                    toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadBanner = false;
                this.errLoadBanner = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    this.Bannerid=[];

                    if (!error.response) {
                        this.LoadBanner = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadBanner = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadBanner = false;
                    this.errLoadBanner = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }
    // Bulk Delete 
    BulkDelete = (allBanner) => {
        runInAction(() => {
            this.BannerLoader = true;
            this.Bannerpopup = true;

            this.errLoadBanner = "";
        });

        postMethodData(`/api/v1/admin/Banner/multi-delete`, {

            "BannerId": JSON.stringify(allBanner),
            // "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.BannerLoader = false;
                        this.Bannerpopup = false;
                        this.errLoadBanner = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.BannerLoader = false;
                        this.Bannerpopup = false;

                        this.errLoadBanner = resp?.data?.message;
                        this.getFilterBanner();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.BannerLoader = false;
                    this.Bannerpopup = false;

                    this.errLoadBanner = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.BannerLoader = false;
                        this.Bannerpopup = false;

                        this.errLoadBanner = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.BannerLoader = false;
                    this.Bannerpopup = false;

                    this.errLoadBanner = error?.response?.data?.message;
                })
                return;
            })
    };

    //get Banner by id
    getBannerform = (id) => {
        runInAction(() => {
            this.BannerLoader = true;
            this.Bannerpopup = true;

            this.errLoadBanner = "";
        });
        postMethodData(`/api/v1/admin/banner/form`, {

            "record_id": id,
            // "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.BannerLoader = false;
                        this.Bannerpopup = false;
                        this.errLoadBanner = resp?.data?.message;
                        // toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        console.log(resp.data.data);
                        this.setBannerFormDetails(resp.data.data);
                        this.Base64BannerImage = resp.data.data.image_name;
                        this.BannerLoader = false;
                        this.Bannerpopup = false;
                        this.Bannername = resp.data.data.title;
                        this.type = resp.data.data.section_id;
                        this.errLoadBanner = resp?.data?.message;
                    })
                    return;
                }
                runInAction(() => {
                    this.BannerLoader = false;
                    this.Bannerpopup = false;

                    this.errLoadBanner = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.BannerLoader = false;
                        this.Bannerpopup = false;

                        this.errLoadBanner = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.BannerLoader = false;
                    this.Bannerpopup = false;

                    this.errLoadBanner = error?.response?.data?.message;
                })
                return;
            })
    };



    //update method
    updateBanner = (id) => {
        runInAction(() => {
            this.addloadBanner = true;
            this.errLoadBanner = "";
        });
        console.log(this.Base64BannerImage, this.bannerimage);

        const formData = new FormData();
        if (!this.BannerFormDetails.url || !this.BannerFormDetails.url.startsWith("https://")) {
            toast.error("Please provide a valid URL starting with 'https://'");
            this.addloadBanner = false;
            return;
        }
        else {
            this.addloadBanner = true;

        }
        if (this.bannerimage) {
            const Bannerbase64String = this.bannerimage.split(',')[1];
            const BannerFile = this.base64toFile(Bannerbase64String, 'logo.png', 'image/png');
            formData.append('banner_image', BannerFile);
        }
        else {
            formData.append('banner_image', this.Base64BannerImage);
        }

        formData.append('title', this.BannerFormDetails.title);
        formData.append('url', `${this.BannerFormDetails.url}`);
        formData.append('section_id', this.BannerFormDetails.section_id == "" ? null : this.BannerFormDetails.section_id  );


        formData.append('status', this.BannerFormDetails.is_active);
        formData.append('record_id', id);

        // formData.append('isBannerUpdated', false  );
        // formData.append('isLogoUpdated', true  );


        putMethod(`/api/v1/admin/banner/edit`,
            formData,
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.success(resp.data.message);
                        // this.getCity();
                        this.getBannerList();
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.addloadcity = false;
                    this.erraddcity = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.addloadcity = false;
                        this.erraddcity = error?.message;
                        toast.error(error?.message);
                    }
                    if (error.response && error.response.data && error.response.data.formErrors) {
                        const formErrors = error.response.data.formErrors;
                        Object.keys(formErrors).forEach((key) => {
                            const errorMessage = formErrors[key];
                            toast.error(`${key}: ${errorMessage}`);
                        });
                    }
                    this.addloadcity = false;
                    this.erraddcity = error?.response?.data?.message;
                })
                return;
            })
    };
}




export const BannerStoreContext = createContext(new BannerStore());
export const useBannerStore = () => useContext(BannerStoreContext);
