import React from "react";
import {
  MdHome,
  MdMoney,
  MdOutlinedFlag,
  MdSportsHandball,
  MdSportsTennis,
  MdDelete,
  MdPriceCheck,
  MdOutlineGridOn,
  MdBuildCircle,
  MdPageview,
  MdFirstPage,
  MdFindInPage,
  MdRequestPage,
  MdRestorePage,
  MdOutlineGroupOff,
  MdSportsBasketball,
  MdLogoDev,
  MdNewspaper
} from "react-icons/md";
import { RxCalendar, RxDashboard, RxFigmaLogo, RxPerson, RxSketchLogo, RxTransparencyGrid } from "react-icons/rx";
import { observer } from "mobx-react";
import { HomeStore, HomeStoreContext, useHomeStore } from "../Store/HomeStore";
import { Link } from "react-router-dom";
const CardDashbord = () => {
  const store = new HomeStore({
    isloading: true,
    editloading: false,
  });
  return (
    <HomeStoreContext.Provider value={store}>
      <Screen />
    </HomeStoreContext.Provider>
  );
};
export default CardDashbord;

const Screen = observer(() => {
  const {
    current_leagues,
    matches_this_week,
    tolal_income,
    tolal_leagues,
    tolal_matches,
    tolal_players,
    tolal_seasons,
    tolal_users,
    deleteuser_count,
    total_tieBreakerCount,
    approval_pending_home_courts,
  } = useHomeStore();
  return (
    <>
      <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 gap-8">
      <Link>
        <div className="card cursor-pointer bg-base-100 shadow-md p-6 group card-dash ">
          <div className="flex  ">
            <div className="w-9/12">
              <p className="text-base font-semibold ">Request Order</p>
              <p className="text-xl font-medium  opacity-80">
              {total_tieBreakerCount ? total_tieBreakerCount : 0 }
              </p>
            </div>
            <div className="w-3/12 flex">
              <div className=" bg-color_theme group-hover:bg-[#fff] aspect-[1] w-full m-auto flex items-center justify-center text-3xl rounded-lg">
                <MdRestorePage />
              </div>
            </div>
          </div>
        </div>
        </Link>
        <Link >
        <div  className="card cursor-pointer bg-base-100 shadow-md p-6 group card-dash ">
          <div className="flex  ">
            <div className="w-9/12">
              <p className="text-base font-semibold ">Subscription Order</p>

              <p className="text-xl font-medium  opacity-80">
                {/* {approval_pending_home_courts} */}
                0
              </p>
            </div>
            <div className="w-3/12 flex">
              <div className=" bg-color_theme group-hover:bg-[#fff] aspect-[1] w-full m-auto flex items-center justify-center text-3xl rounded-lg">
                <MdHome />
              </div>
            </div>
          </div>
        </div>
        </Link>

      <Link >
        <div  className="card cursor-pointer bg-base-100 shadow-md p-6 group card-dash ">
          <div className="flex  ">
            <div className="w-9/12">
              <p className="text-base font-semibold ">Order status</p>
           
              <p className="text-xl font-medium  opacity-80">
                0
              {/* {deleteuser_count} */}
              </p>
            </div>
            <div className="w-3/12 flex">
              <div className=" bg-color_theme group-hover:bg-[#fff] aspect-[1] w-full m-auto flex items-center justify-center text-3xl rounded-lg">
                <MdNewspaper/>
              </div>
            </div>
          </div>
        </div>
        </Link>
        
      </div>
      <div className="grid xl:grid-cols-4 mt-5 lg:grid-cols-2 md:grid-cols-2 gap-8">
        <Link >
        <div className="card cursor-pointer bg-base-100 shadow-md p-6 group card-dash ">
          <div className="flex ">
            <div className="w-9/12">
              <p className="text-xl font-semibold">0</p>
              <p className="text-base font-medium opacity-80">Total Users</p>
            </div>
            <div className="w-3/12 flex">
              <div className=" bg-color_theme group-hover:bg-[#fff] aspect-[1] w-full m-auto flex items-center justify-center text-3xl rounded-lg">
                <RxPerson />
              </div>
            </div>
          </div>
        </div>
        </Link>
    
        <Link >

        <div className="card cursor-pointer bg-base-100 shadow-md p-6 group card-dash ">
          <div className="flex ">
            <div className="w-9/12">
              <p className="text-xl font-semibold">0</p>
              <p className="text-base font-medium opacity-80">Reward Users</p>
            </div>
            <div className="w-3/12 flex">
              <div className=" bg-color_theme group-hover:bg-[#fff] aspect-[1] w-full m-auto flex items-center justify-center text-3xl rounded-lg">
                <MdPriceCheck />
              </div>
            </div>
          </div>
        </div>
        </Link>
        <Link>
        <div className="card bg-base-100 shadow-md p-6 group card-dash ">
          <div className="flex ">
            <div className="w-9/12">
              <p className="text-xl font-semibold">0</p>
              <p className="text-base font-medium opacity-80">Deleted User</p>
            </div>
            <div className="w-3/12 flex">
              <div className=" bg-color_theme group-hover:bg-[#fff] aspect-[1] w-full m-auto flex items-center justify-center text-3xl rounded-lg">
                <MdDelete />
              </div>
            </div>
          </div>
        </div>
        </Link>

        <Link   >
        <div className="card bg-base-100 shadow-md p-6 group card-dash ">
          <div className="flex ">
            <div className="w-9/12">
              <p className="text-xl font-semibold">0</p>
              <p className="text-base font-medium opacity-80">Brand Requests</p>
            </div>
            <div className="w-3/12 flex">
              <div className=" bg-color_theme group-hover:bg-[#fff] aspect-[1] w-full m-auto flex items-center justify-center text-3xl rounded-lg">
                <RxSketchLogo/>
              </div>
            </div>
          </div>
        </div>
        </Link>
        <Link  >
        <div className="card bg-base-100 shadow-md p-6 group card-dash ">
          <div className="flex ">
            <div className="w-9/12">
              <p className="text-xl font-semibold">0</p>
              <p className="text-base font-medium opacity-80">Category Requests</p>
            </div>
            <div className="w-3/12 flex">
              <div className=" bg-color_theme group-hover:bg-[#fff] aspect-[1] w-full m-auto flex items-center justify-center text-3xl rounded-lg">
                <MdOutlinedFlag />
              </div>
            </div>
          </div>
        </div>
        </Link>
       
        <Link   >

        <div className="card bg-base-100 shadow-md p-6 group card-dash ">
          <div className="flex ">
            <div className="w-9/12">
              <p className="text-xl font-semibold">0</p>
              <p className="text-base font-medium opacity-80">
              Order Return Req.
              </p>
            </div>
            <div className="w-3/12 flex">
              <div className=" bg-color_theme group-hover:bg-[#fff] aspect-[1] w-full m-auto flex items-center justify-center text-3xl rounded-lg">
                <RxDashboard />
              </div>
            </div>
          </div>
        </div>
        </Link>

        <div className="card bg-base-100 shadow-md p-6 group card-dash ">
          <div className="flex ">
            <div className="w-9/12">
              <p className="text-xl font-semibold">0</p>
              <p className="text-base whitespace-nowrap font-medium opacity-80">
              Master User Req.
              </p>
            </div>
            <div className="w-3/12 flex">
              <div className=" bg-color_theme group-hover:bg-[#fff] aspect-[1] w-full m-auto flex items-center justify-center text-3xl rounded-lg">
                <MdOutlineGridOn />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="card bg-base-100 shadow-md p-6 group card-dash ">
          <div className="flex ">
            <div className="w-9/12">
              <p className="text-xl font-semibold">
                {approval_pending_home_courts}
              </p>
              <p className="text-base font-medium opacity-80">
                Pending Home Courts
              </p>
            </div>
            <div className="w-3/12 flex">
              <div className=" bg-color_theme group-hover:bg-[#fff] aspect-[1] w-full m-auto flex items-center justify-center text-3xl rounded-lg">
                <MdHome />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
});
