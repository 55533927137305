import React, { useCallback, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdImageAspectRatio, MdImage, MdCancel, MdPlusOne, MdHdrPlus, MdAdd, MdEdit, MdSave } from "react-icons/md";
import { Input, Button, Select } from "react-daisyui";
import SearchableDropdown from "../common/SearchableDropdown/SearchableDropdown";
// import { CityStore, CityStoreContext } from "./store/CityStore";
// import { AdminUseStore, useAdminUsedStore, AdminUserStoreContext } from "./store/AdminUsersStore";
import { ProductCatalogStore, useProductCatalogStore, ProductCatalogStoreContext } from "./Store/ProductCatalogStore";
import { observer } from "mobx-react";
import { useAuthStore } from "../Auth/store/Auth";
import { useState } from "react";
import JoditEditor from "jodit-react";
import ImgCrop from "antd-img-crop";
import { Upload } from 'antd';
import debounce from 'lodash/debounce';
import { autorun } from "mobx";

const EditPrductCatalog = observer(() => {
    const { id } = useParams();

    return (
        <ProductCatalogStoreContext.Provider
            value={
                new ProductCatalogStore({
                    loadeditcitybol: false,
                    editProductCataloglist: true,
                    id: id
                })
            }
        >
            <Screen />
        </ProductCatalogStoreContext.Provider>
    );
});
export default EditPrductCatalog;

const Screen = observer(() => {
    const { addloadProductCatalog, productDetail, cod_available, product_approved, product_featured, defaultImageDetail, productOptionDetail, optionArray, optionDetail, productSpecificationDetail, code_for_all_variant, is_active, productName, productIdentifier, prodcutwarranty, product_code, warranty_type, minimumprice, YouTubevideo, model, optionslist, min_selling_price_usd, setmin_selling_price_usd, setis_active, getToken, getcontent, setproduct_code, getlogoimage, deleteSpecification, addProductImage, deleteImagefunction, getProductValuesList, setproducttype, setproductIdentifier, setproductName, setproduct_featured, setproduct_approved, setcod_available, setmodel, setminimumprice, setprodcutwarranty, setwarranty_type, setYouTubevideo, logoimage, setcode_for_all_variant, UpdateProductCatalog, uploaded_file, ProductOptions, ProductValues, content, specificationDetail, setdisplay, getusername, setemail, setalert, Confirmpassw, passwordChanged, errPassword, saved, All_categories, Brand, shop } = useProductCatalogStore();
    const { adminAuthApproved } = useAuthStore();


    const { id } = useParams();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    // const [logoimage, setlogoimage] = useState();
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [showList, setShowList] = useState(false);
    const [isListVisible, setListVisibility] = useState(false);
    const [addedItems, setAddedItems] = useState([]);
    const [isFixed, setIsFixed] = useState(true);
    const [activeTab, setActiveTab] = useState('basic'); // Set the default active tab
    const basicDetailsRef = useRef(null);
    const variantsRef = useRef(null);
    const media = useRef(null);
    const specifications = useRef(null);
    const [specificationsList, setSpecificationsList] = useState([]);
    const nameRef = useRef(null);
    const valueRef = useRef(null);
    const groupRef = useRef(null);
    const [isparentID, setisparentID] = useState('');
    const [price, setprice] = useState('');
    const [price_type, setprice_type] = useState('');
    const [quantity, setquantity] = useState('');
    const [selectedOptionsInPreviousDropdowns, setSelectedOptionsInPreviousDropdowns] = useState([]);
    const [initiallySelectedOptions, setInitiallySelectedOptions] = useState([]);



    const [logofile, setlogofile] = useState([]);
    useEffect(() => {
        // Define a function to update logofile based on the store data
        const updateLogofile = () => {
            if (specificationDetail && specificationDetail.length > 0) {
                // Set logofile state with default images
                setSpecificationsList(specificationDetail);
            }
        };

        // Call updateLogofile when the store data changes
        const disposer = autorun(updateLogofile);

        // Cleanup function
        return () => {
            disposer(); // Dispose the autorun when the component unmounts
        };
    }, [specificationDetail]); // Run this effect whenever the store changes
    useEffect(() => {
        // Define a function to update logofile based on the store data
        const updateLogofile = () => {
            if (defaultImageDetail && defaultImageDetail.length > 0) {
                // Set logofile state with default images
                setlogofile(defaultImageDetail);
            }
        };

        // Call updateLogofile when the store data changes
        const disposer = autorun(updateLogofile);

        // Cleanup function
        return () => {
            disposer(); // Dispose the autorun when the component unmounts
        };
    }, [defaultImageDetail]); // Run this effect whenever the store changes
    const [selectedValues, setSelectedValues] = useState(Array(logofile?.length).fill(''));
    console.log("optionArray==============================>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", optionArray);
    useEffect(() => {
        // Define a function to update logofile based on the store data
        const updateLogofile = () => {
            if (optionDetail && optionDetail.length > 0) {
                // Set logofile state with default images
                setSelectedValues(optionDetail);
            }
        };

        // Call updateLogofile when the store data changes
        const disposer = autorun(updateLogofile);

        // Cleanup function
        return () => {
            disposer(); // Dispose the autorun when the component unmounts
        };
    }, [optionDetail]); // Run this effect whenever the store changes

    // const handleSelectChange = (index, value) => {
    //   // Update the selected value for the specific select field
    //   const newSelectedValues = [...selectedValues];
    //   newSelectedValues[index] = value;
    //   setSelectedValues(newSelectedValues);
    // };
    /////////////////////////////////////
    const [inputFields, setInputFields] = useState([
        { option_id: '', data: [{ option_value_id: [], price_inr: '', price_usd: '', quantity: '', pricetype: '', }] }
    ]);
    const [filteredItemsList, setFilteredItemsList] = useState(inputFields.map(() => []));

    const [hideShowStates, setHideShowStates] = useState(inputFields.map(() => false));
    const [isOpenStates, setIsOpenStates] = useState(inputFields.map(() => false));
    const [isShowList, setisShowList] = useState(inputFields.map(() => false));
    const [categoryvalue, setcategoryvalue] = useState(inputFields.map(() => "Select option..."));

    const [selectedOptionsList, setSelectedOptionsList] = useState(inputFields.map(() => []));
    const handleAddFields = () => {
        const newField = {
            option_id: '',
            data: [{ option_value_id: '', optionvaluename: '', price_inr: '', price_usd: '', quantity: '', pricetype: '', type: '' }]
        };
        const newFilteredItemsList = [...filteredItemsList];
        newFilteredItemsList.push([]);
        setFilteredItemsList(newFilteredItemsList);
        setInputFields([...inputFields, newField]);
        setHideShowStates([...hideShowStates, false]);
        setIsOpenStates([...isOpenStates, false]);
        setisShowList([...isShowList, false]);
        setcategoryvalue([...categoryvalue, "Select option..."]);
        setSelectedOptionsList([...selectedOptionsList, []]);
        setInitiallySelectedOptions([...initiallySelectedOptions, false]); // Initialize for the new field
    };

    const handleRemoveFields = (index) => {
        const newInputFields = [...inputFields];
        const newHideShowStates = [...hideShowStates];
        const newIsOpenStates = [...isOpenStates];
        const newIsOpenstates = [...isShowList];
        const newIsCategorystates = [...categoryvalue];
        const newSelectedOptionsList = [...selectedOptionsList];

        newInputFields.splice(index, 1);
        newHideShowStates.splice(index, 1);
        newIsOpenStates.splice(index, 1);
        newSelectedOptionsList.splice(index, 1);
        newIsCategorystates.splice(index, 1);


        setInputFields(newInputFields);
        setHideShowStates(newHideShowStates);
        setIsOpenStates(newIsOpenStates);
        setisShowList(newIsOpenstates);
        setcategoryvalue(newIsCategorystates);
        setSelectedOptionsList(newSelectedOptionsList);
    };
    const onChange = useCallback(
        (newContent) => {
            // const strippedContent = newContent.replace(/<[^>]*>/g, '');
            getcontent(newContent);
        },
        [content]
    );
    useEffect(() => {
        // Define a function to update logofile based on the store data
        const updateLogofile = () => {
            if (optionslist && optionslist.length > 0) {
                // Set logofile state with default images
                setInputFields(optionslist);
            }
        };

        // Call updateLogofile when the store data changes
        const disposer = autorun(updateLogofile);

        // Cleanup function
        return () => {
            disposer(); // Dispose the autorun when the component unmounts
        };
    }, [optionslist]); // Run this effect whenever the store changes

    const handleChangeInput = (options, event, index) => {
        setQuery(() => "");
        setIsOpen((isOpen) => !isOpen);
        const newInputFields = [...inputFields];

        if (!newInputFields[index]) {
            // Handle the case where the array element at index does not exist
            return;
        }

        if (options === "option_id") {

            newInputFields[index][options] = event.id;
            if (index === 0) {
                setisparentID(event.id)
            }
            setcategoryvalue(prevCategoryValue => {
                const newCategoryValue = [...prevCategoryValue];
                newCategoryValue[index] = event.option_name;
                return newCategoryValue;
            });

        } else if (options === "option_value_id") {
            if (!newInputFields[index]["data"]) {
                // If "data" array is not initialized, initialize it with an empty array
                newInputFields[index]["data"] = [];
            }

            const values = Array.isArray(event) ? event : [event];
            newInputFields[index]["data"] = values.map(value => ({
                option_value_id: value.id,
                optionvaluename: value.option_value_name,
                price: '',
                quantity: '',
                type: ''

            }));
        }
        else if (options === "price_inr" || options === "price_usd" || options === "quantity" || options === "pricetype" || options === "type") {

            if (!newInputFields[index]["data"]) {
                // If "data" array is not initialized, initialize it with an empty array
                newInputFields[index]["data"] = [];
            }

            newInputFields[index]["data"].forEach(item => {
                item[options] = event.target.value;

                if (options === "price_inr") {
                    setprice(event.target.value)
                }
                if (options === "price_usd") {
                    setprice(event.target.value)
                }
                if (options === "quantity") {
                    setquantity(event.target.value)
                }
                if (options === "pricetype") {
                    setprice_type(event.target.value)
                }
            });
        }
        // else if (options === "pricetype") {
        //     if (!newInputFields[index]["data"]) {
        //         // If "data" array is not initialized, initialize it with an empty array
        //         newInputFields[index]["data"] = [];
        //     }

        //     newInputFields[index]["data"].forEach(item => {
        //         item[options] = event.target.value;
        //     });
        // }

        setInputFields(newInputFields);
    };
    /////////////////////////////////////
    // Add refs for other tabs as needed
    const handleTabClick = (tab) => {
        setActiveTab(tab);
        // Scroll to the corresponding div based on the tab
        switch (tab) {
            case 'basic':
                scrollToRef(basicDetailsRef);
                break;
            case 'Variants':
                scrollToRef(variantsRef);
                break;
            case 'Media':
                scrollToRef(media);
                break;
            case 'Specifications':
                scrollToRef(specifications);
                break;
            // Add cases for other tabs
            default:
                break;
        }
    };

    // const handleAdd = () => {
    //     // Add a new item to the list
    //     setAddedItems([...addedItems, { /* Whatever data you want to add */ }]);
    // };

    // const handleDelete = (index) => {
    //     // Remove the item at the specified index
    //     const updatedItems = [...addedItems];
    //     updatedItems.splice(index, 1);
    //     setAddedItems(updatedItems);
    // };




    // const [crop, setCrop] = useState({ aspect: 16 / 9, dimensions: { width: 480, height: 270 } });
    // const { register, handleSubmit, errors } = useForm();


    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);
    const navigate = useNavigate();


    useEffect(() => {
        if (saved) {
            navigate(-1);
        }
    }, [saved]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY || document.documentElement.scrollTop;

            // Adjust this value to set the scroll position at which the fixed element becomes relative
            const togglePositionHeight = 200;

            // Toggle between fixed and relative based on scroll position
            setIsFixed(scrollPosition <= togglePositionHeight);
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    const handleSubmit = (e) => {
        e.preventDefault();
        UpdateProductCatalog(inputFields, specificationsList, selectedValues, id);
        // Perform API call or any other logic using MobX store data
        // Example: addCity();
    };

    const getBase64 = file => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });
    };



    const DemoShops = [{ name: "Raj Shop" }]

    const handleFocus = (indexs) => {
        // Set the list visibility to true when the input is focused
        setListVisibility(true);

        // Exclude options that have already been selected in previous dropdowns
        const availableOptions = ProductValues.filter(item => !selectedOptionsInPreviousDropdowns.includes(item?.option_value_name));
        const newFilteredItemsList = [...filteredItemsList];
        newFilteredItemsList[indexs] = availableOptions;
        setFilteredItemsList(newFilteredItemsList);

        const newIsShowList = [...isShowList];
        newIsShowList[indexs] = true; // Set to true when focused
        setisShowList(newIsShowList);

        // Initial filtering to show the entire list
    };

    const handleSearch = (query, indexs) => {
        // Get the list of removed options for this dropdown
        const removedOptions = selectedOptionsList[indexs]._removedOptions || [];

        // Filter the available options based on the search query
        const filtered = ProductValues.filter((item) =>
            item?.option_value_name.toLowerCase().includes(query?.toLowerCase())
        );

        // Include removed options in the filtered list
        const filteredWithRemoved = [...removedOptions, ...filtered];
        const availableOptions = ProductValues.filter(item => !selectedOptionsInPreviousDropdowns.includes(item?.option_value_name));
        const newFilteredItemsList = [...filteredItemsList];
        newFilteredItemsList[indexs] = filteredWithRemoved;
        setFilteredItemsList(newFilteredItemsList);

        setFilteredItems(filteredWithRemoved);
        setSearchQuery(query);

        // Show the list
        const newIsShowList = [...isShowList];
        newIsShowList[indexs] = true;
        setisShowList(newIsShowList);
    };


    const handleBlur = (indexs) => {
        // Set the list visibility to false when the input loses focus
        setListVisibility(false);
        // setFilteredItems(ProductValues.map(item => item.option_value_name));
        // setShowList(true);
        const newIsShowList = [...isShowList];
        newIsShowList[indexs] = true; // Set to false when blurred
        setisShowList(newIsShowList);
    };



    const handleSelect = (item, indexs) => {
        const newSelectedOptionsList = [...selectedOptionsList];
        const newSelectedOptionsListID = [...selectedOptionsList];

        // Replace the selected option instead of adding it
        newSelectedOptionsListID[indexs] = [item];
        newSelectedOptionsList[indexs] = [item?.option_value_name];

        setSelectedOptionsList(newSelectedOptionsList);

        // Update the selected options for the specific dropdown
        setSelectedOptionsInPreviousDropdowns(prevSelectedOptions => {
            const updatedOptions = [...prevSelectedOptions];
            updatedOptions[indexs] = item?.option_value_name;
            return updatedOptions;
        });

        // Assuming item is an object and you want to add the whole object to selectedOptions
        // Check if indexs is zero before updating setSelectedOptions
        if (indexs === 0) {
            setSelectedOptions([item]);
        }

        handleChangeInput("option_value_id", newSelectedOptionsListID[indexs], indexs);

        setSearchQuery('');

        // Update available options by excluding selected options
        const availableOptions = ProductValues.filter(item => !newSelectedOptionsList.flat().includes(item?.option_value_name));

        // Update the filteredItemsList for the specific dropdown
        const newFilteredItemsList = [...filteredItemsList];
        newFilteredItemsList[indexs] = availableOptions;
        setFilteredItemsList(newFilteredItemsList);

        const newIsShowList = [...isShowList];
        newIsShowList[indexs] = false;
        setisShowList(newIsShowList);
        setShowList(false);
    };
    const handleRemove = (index, optionindex) => {
        const newSelectedOptionsList = [...selectedOptionsList];
        const removedOption = newSelectedOptionsList[index][optionindex];

        // Remove the selected option from the list of selected options in previous dropdowns
        setSelectedOptionsInPreviousDropdowns(prevSelectedOptions => prevSelectedOptions.filter(option => option !== removedOption));

        newSelectedOptionsList[index].splice(optionindex, 1);
        setSelectedOptionsList(newSelectedOptionsList);
        handleChangeInput("option_value_id", newSelectedOptionsList[index], index);
    };

    // const handleSelectChange = async (index, value) => {
    //     const newSelectedValues = [...selectedValues];

    //     // Convert the image to base64
    //     if (logofile[index]) {
    //       try {
    //         const base64Image = await getBase64(logofile[index].originFileObj);
    //         newSelectedValues[index] = { value_id: value, option_id: selectedOptions[index].id, data: [base64Image] };
    //         setSelectedValues(newSelectedValues);
    //       } catch (error) {
    //         console.error('Error converting image to base64:', error);
    //       }
    //     }
    //   };
  
    const [processedImages, setProcessedImages] = useState([]);
    const [processedLoader, setProcessedLoader] = useState(false);


    const LogChange = debounce(async ({ fileList: newFileList }) => {
        setProcessedLoader(false);
        setlogofile(newFileList.map(file => ({ ...file, status: 'done' })));

        for (const file of newFileList) {

            const imageName = file?.originFileObj;

            // Check if the image has already been processed
            if (imageName && !processedImages.includes(imageName)) {

                setProcessedImages(prevProcessedImages => [...prevProcessedImages, imageName]);

                // Your existing processing logic goes here
                const isImageAlreadyAdded = selectedValues.some(value => value?.uploaded_file?.includes(imageName));

                if (!isImageAlreadyAdded) {

                    const base64Image = await getBase64(file?.originFileObj);
                    const image = await addProductImage(file?.originFileObj);
                    getlogoimage(image);


                    const lastObjectIndex = selectedValues.length - 1;
                    const lastObject = selectedValues[lastObjectIndex];
                    const newObject = {
                        option_value_id: null,
                        option_id: null,
                        is_variant_image: "0",
                        uploaded_file: [image],
                        price_type: null,
                        price: null,
                        quantity: null,
                        type: null,
                    };

                    setSelectedValues(prevValues => [
                        ...prevValues,
                        lastObject && lastObject.option_value_id === null ? newObject : newObject,
                    ]);

                }
            }
        }

    }, 3000); // 300 milliseconds debounce time




    const handleImageDelete = (file) => {
        const indexToDelete = logofile?.findIndex((f) => f.uid === file.uid);

        if (indexToDelete !== -1) {
            // Extract the uploaded_file value from the deleted file
            const deletedImageName = selectedValues[indexToDelete]?.uploaded_file;
            // Remove the deleted file from selectedValues
            const newSelectedValues = selectedValues.filter((_, index) => index !== indexToDelete);
            setSelectedValues(newSelectedValues);

            // Pass the value based on is_variant_image key
            const isVariantImage = productOptionDetail[indexToDelete]?.is_variant_image === 1 ? 1 : 0;
            deleteImagefunction(deletedImageName, isVariantImage);

            // Also, update the logofile state to remove the deleted file
            const newLogofile = [...logofile];
            newLogofile.splice(indexToDelete, 1);
            setlogofile(newLogofile);
        }
    };

    const handleSelectChange = async (index, value, selectedData, image) => {
        console.log("optionArray[index - 1]?.option_value_id ",value);
        const newSelectedValues = [...selectedValues];
        const id = selectedData.option_value_id;
        const type = selectedData.type;
        const quantity = selectedData.quantity;
        const price = selectedData.price;
        const pricetype = selectedData.pricetype;
        const option_id = selectedData.option_id;
        if (logofile[index]) {
            try {
                const file = logofile[index].originFileObj;
                const Logobase64String = logofile[index].thumbUrl?.split(',')[1];

                // If no object with the same name and length 1 uploaded_file, update the existing object or create a new one
                const optionIndex = newSelectedValues.findIndex(
                    (item) => item.option_value_id === id
                );


                // if (optionIndex !== -1) {
                //     // If option_id exists, update the existing object
                //     console.log("first",newSelectedValues[2].uploaded_file[0] , optionIndex);
                //     newSelectedValues[2].uploaded_file.push(image);
                //     newSelectedValues[optionIndex].price_type = pricetype;
                //     newSelectedValues[optionIndex].price = price;
                //     newSelectedValues[optionIndex].quantity = quantity;
                //     newSelectedValues[optionIndex].type = type;
                // } else {
                //     // If option_id doesn't exist, create a new object
                //     console.log("second");

                newSelectedValues[index] = {
                    option_value_id: id == undefined ? null : id,
                    // option_id: value !== "" ? 2 == "" ? option_id : isparentID : null,
                    option_id: 3,
                    is_variant_image: value === "" ? "0" : "1",
                    // price_type: value !== "" ? pricetype : null,
                    price: value !== "" ? price : null,
                    quantity: value !== "" ? quantity : null,
                    price_type: value !== "" ? type : null,
                };
                newSelectedValues[index].uploaded_file = [image]

                // }

                setSelectedValues(newSelectedValues);



            } catch (error) {
                console.error('Error converting image to File:', error);
            }
        }
    };
    const [editIndex, setEditIndex] = useState(null);

    const handleEdit = (index) => {
        setEditIndex(index);
    };

    const handleSave = (index) => {
        setEditIndex(null);
    }

    const handleSubmittable = () => {

        const newSpecification = {
            id: null,
            name: nameRef.current.value,
            value: valueRef.current.value,
            group: groupRef.current.value,
        };

        setSpecificationsList([...specificationsList, newSpecification]);

        // Clear input fields after adding a specification
        nameRef.current.value = '';
        valueRef.current.value = '';
        groupRef.current.value = '';
    };

    const cleartable = () => {
        nameRef.current.value = '';
        valueRef.current.value = '';
        groupRef.current.value = '';
    }


    const handleDeletetable = (index, idd) => {
        const updatedList = [...specificationsList];
        updatedList.splice(index, 1);
        if (idd) {
            deleteSpecification(idd, id)
        }
        setSpecificationsList(updatedList);
    };
    const scrollToRef = (ref) => {
        if (ref && ref.current) {
            const yOffset = -90; // Adjust this value as needed
            const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };
    const [query, setQuery] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [HideShow, setHideShow] = useState(false);
    const [value, setValue] = useState("Select option...");
    const inputRef = useRef(null);
    const { setparentId } = useProductCatalogStore();

    useEffect(() => {
        document.addEventListener("click", toggle);
        return () => document.removeEventListener("click", toggle);
    }, []);

    const selectOption = (option, index) => {
        setQuery(() => "");
        setValue(option['option_name']);
        // handleChangeInput(index, event)
        getProductValuesList(option.id)
        setparentId(option?.id)
        setIsOpen((isOpen) => !isOpen);
    };

    function toggle(e) {
        setIsOpen(e && e.target === inputRef.current);
    }


    const getDisplayValue = () => {
        if (query) return query;
        if (value) return value;

        return "";
    };

    const filter = (options) => {
        return options.filter(
            (option) => option['option_name'].toLowerCase().indexOf(query.toLowerCase()) > -1
        );
    };

    return (
        <>
            <div className="flex justify-between">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">Admin Edit Product </p>
                    <p className=" opacity-80 text-sm">Home / Product Catalog / Edit Product  </p>
                </div>
                <div>

                    <Link
                        onClick={() => navigate(-1)}
                        className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                        Back
                    </Link>
                </div>
            </div>
            <div className="card relative flex    bg-base-100 shadow-md p-6 mt-8">


                <ol className={`${!isFixed && " bottom-10"} z-10  w-3/12 left-[19%]   fixed   text-white   dark:text-gray-400`}>
                    <li
                        ref={basicDetailsRef}
                        className={`mb-1 p-5  cursor-pointer rounded ms-6 hover:bg-color_theme hover:text-white bg-[#f7f7f7] ${activeTab === 'basic' ? 'bg-color_theme text-white ' : " text-black"}`}
                        onClick={() => { setActiveTab('basic'); handleTabClick('basic') }}
                    >

                        <h3 className="font-medium   leading-tight mb-3">Basic Details</h3>
                        <p className="text-sm"> Manage the product's basic information.</p>
                    </li>
                    <li
                        ref={variantsRef}
                        className={`mb-1 p-5  cursor-pointer rounded ms-6 hover:bg-color_theme hover:text-white bg-[#f7f7f7] ${activeTab === 'Variants' ? 'bg-color_theme text-white ' : " text-black"}`}
                        onClick={() => { setActiveTab('Variants'); handleTabClick('Variants') }}
                    >

                        <h3 className="font-medium leading-tight  mb-3 ">Variants & options</h3>
                        <p className="text-sm"> Customize the product variants, including size, color, etc.</p>
                    </li>
                    <li
                        ref={media}

                        className={`mb-1 p-5  cursor-pointer rounded ms-6 hover:bg-color_theme hover:text-white bg-[#f7f7f7] ${activeTab === 'Media' ? 'bg-color_theme text-white ' : " text-black"}`}
                        onClick={() => { setActiveTab('Media'); handleTabClick('Media') }}
                    >

                        <h3 className="font-medium leading-tight  mb-3 ">Media</h3>
                        <p className="text-sm"> Manage your product's image gallery. </p>
                    </li>
                    <li
                        ref={specifications}

                        className={`mb-1 p-5 cursor-pointer rounded ms-6 hover:bg-color_theme hover:text-white bg-[#f7f7f7] ${activeTab === 'Specifications' ? 'bg-color_theme text-white ' : " text-black"}`}
                        onClick={() => { setActiveTab('Specifications'); handleTabClick('Specifications') }}
                    >

                        <h3 className="font-medium leading-tight  mb-3 ">Specifications</h3>
                        <p className="text-sm"> Manage the product-related specifications. </p>
                    </li>
                </ol>
                <div >
                    <form className="flex space-x-1 md:ml-[30%] lg:ml-[33%]" onSubmit={handleSubmit}>

                        <div
                            ref={basicDetailsRef}

                            className=" flex w-7/12 justify-center  space-y-5 flex-col">

                            <div className="  border-[1px] p-2 bg-[#f7f7f7] rounded border-gray-300 " >
                                <div
                                    className="flex flex-col mb-3 ">
                                    <label className="font-semibold text-xl">Basic details  </label>
                                    <span className="text-sm  mt-1" >     Manage the product's basic indivation. </span>
                                </div>
                                <div className="border-b-[1px] border-[gray] mt-5 mb-5"></div>
                                <div className="grid lg:grid-cols-2 mb-5 md:grid-cols-2 md:gap-8 gap-4">
                                    <div>
                                        <label
                                            htmlFor="name"
                                            className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2 "
                                        >
                                            Product type<span className="text-[#f00]">&nbsp;*</span>
                                        </label>
                                        <div className="flex  space-x-[1px]">
                                            <label className=" flex bg-slate-300 rounded text-center justify-center items-center px-3 p-1 space-x-1">
                                                <span className="text-sm">Physical </span>
                                                <input
                                                    name="name"
                                                    required
                                                    defaultChecked
                                                    placeholder="name"
                                                    value="physical"
                                                    onChange={(e) => { setproducttype(e.target.value); }}
                                                    id="name"
                                                    type="radio"
                                                    autoComplete="off"
                                                    className=" focus:outline-0 font-medium rounded-md"
                                                />
                                            </label>

                                            <label className=" flex text-center px-3 bg-slate-300 rounded justify-center items-center p-1 space-x-1">


                                                <span className="text-sm">Digital </span>
                                                <input
                                                    name="name"
                                                    required
                                                    placeholder="name"
                                                    value="digital"
                                                    onChange={(e) => { setproducttype(e.target.value); }}
                                                    id="name"
                                                    type="radio"
                                                    autoComplete="off"
                                                    className=" focus:outline-0 font-medium rounded-md"
                                                />
                                            </label>
                                        </div>


                                    </div>
                                    {/* <div>
                                        <label
                                            htmlFor="Categoryidentifier"
                                            className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                        >
                                            Parent category<span className="text-[#f00]">&nbsp;*</span>
                                        </label>
                                        <SearchableDropdown List={shop} listingOff={"shop"} />


                                    </div> */}
                                </div>
                                {/* <div className="mb-5">
                                    <label
                                        htmlFor="Product"
                                        className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                    >
                                        Product identifier<span className="text-[#f00]">&nbsp;*</span>
                                    </label>
                                    <Input
                                        name="Product"
                                        required
                                        disabled
                                        placeholder="Product"
                                        value={productIdentifier}
                                        onChange={(e) => { setproductIdentifier(e.target.value); }}
                                        id="Product"
                                        type="text"
                                        autoComplete="off"
                                        className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                    />
                                    <span className="text-[gray] text-xs">Enter a unique identifier associated with the product name.</span>
                                </div> */}
                                <div>
                                    <label
                                        htmlFor="Product"
                                        className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                    >
                                        Product name<span className="text-[#f00]">&nbsp;*</span>
                                    </label>
                                    <Input
                                        name="Product"
                                        required
                                        placeholder="Product name"
                                        value={productName}
                                        onChange={(e) => { setproductName(e.target.value); }}
                                        id="Product"
                                        type="text"
                                        autoComplete="off"
                                        className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                    />
                                    <span className="text-[gray] text-xs">Name of the product as it will be displayed on the front end.</span>
                                </div>
                                <div className="mb-5">
                                    <label
                                        htmlFor="Product"
                                        className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                    >
                                        Product identifier<span className="text-[#f00]">&nbsp;*</span>
                                    </label>
                                    <Input
                                        name="Product"
                                        required
                                        disabled
                                        placeholder="Product"
                                        value={productIdentifier}
                                        onChange={(e) => { setproductIdentifier(e.target.value); }}
                                        id="Product"
                                        type="text"
                                        autoComplete="off"
                                        className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                    />
                                    <span className="text-[gray] text-xs">Enter a unique identifier associated with the product name.</span>
                                </div>
                                <div className="grid lg:grid-cols-2 mb-5 md:grid-cols-2 md:gap-8 gap-4">
                                    <div>
                                        <label
                                            htmlFor="Categoryidentifier"
                                            className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                        >
                                            Brand<span className="text-[#f00]">&nbsp;*</span>
                                        </label>
                                        <SearchableDropdown List={Brand} listingOff={"brand"} />

                                    </div>
                                    <div>
                                        <label
                                            htmlFor="Categoryidentifier"
                                            className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                        >
                                            Category<span className="text-[#f00]">&nbsp;*</span>
                                        </label>
                                        <SearchableDropdown List={All_categories} listingOff={"categories"} />


                                    </div>
                                </div>

                                <div className="grid lg:grid-cols-2 mb-5 md:grid-cols-2 md:gap-8 gap-4">

                                    <div>
                                        <label
                                            htmlFor="Categoryidentifier"
                                            className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                        >
                                            Minimum selling price [$]<span className="text-[#f00]">&nbsp;*</span>
                                        </label>
                                        <Input
                                            name="price"
                                            required
                                            placeholder="price"
                                            min={1}
                                            value={min_selling_price_usd}

                                            onChange={(e) => { setmin_selling_price_usd(e.target.value); }}
                                            id="price"
                                            type="number"
                                            autoComplete="off"
                                            className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                        />

                                    </div>
                                    <div>
                                        <label
                                            htmlFor="Categoryidentifier"
                                            className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                        >
                                            Minimum selling price [₹]<span className="text-[#f00]">&nbsp;*</span>
                                        </label>
                                        <Input
                                            name="price"
                                            required
                                            placeholder="price"
                                            min={1}
                                            value={minimumprice}
                                            onChange={(e) => { setminimumprice(e.target.value); }}
                                            id="price"
                                            type="number"
                                            autoComplete="off"
                                            className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                        />

                                    </div>
                                </div>
                                <div className="grid lg:grid-cols-2 mb-5 md:grid-cols-2 md:gap-8 gap-4">
                                    <div>
                                        <label
                                            htmlFor="Categoryidentifier"
                                            className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                        >
                                            Product warranty<span className="text-[#f00]">&nbsp;*</span>
                                        </label>
                                        <div className="relative">
                                            <Input
                                                name="warranty"
                                                required
                                                value={prodcutwarranty == "null" ? "" : prodcutwarranty}
                                                onChange={(e) => { setprodcutwarranty(e.target.value); }}
                                                id="warranty"
                                                type="number"
                                                autoComplete="off"
                                                className=" focus:outline-0 w=7/12 text-sm font-medium rounded-md"
                                            />
                                            <div className="mb-5 text-xs absolute bottom-0 top-0 right-[-15px]">
                                                <select
                                                    id="Status"
                                                    value={warranty_type == "null" ? "" : warranty_type}
                                                    onChange={(e) => setwarranty_type(e.target.value)}
                                                    name="Status"
                                                    className={`select  focus:outline-0  text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                                                >
                                                    <option value="days">Days</option>
                                                    <option value="months">Months</option>
                                                    <option value="years">Years</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                    <div>
                                        <label
                                            htmlFor="Categoryidentifier"
                                            className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                        >
                                            YouTube video URL<span className="text-[#f00]">&nbsp;*</span>
                                        </label>
                                        <Input
                                            name="URL"
                                            required
                                            value={YouTubevideo}
                                            onChange={(e) => setYouTubevideo(e.target.value)}
                                            id="URL"
                                            type="text"
                                            autoComplete="off"
                                            className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                        />

                                    </div>
                                </div>
                                <div className="grid lg:grid-cols-2 mb-5 md:grid-cols-2 md:gap-8 gap-4">
                                    <div>
                                        <label
                                            htmlFor="Categoryidentifier"
                                            className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                        >
                                            Model#<span className="text-[#f00]">&nbsp;*</span>
                                        </label>
                                        <Input
                                            name="Model"
                                            placeholder="Model"
                                            value={model}
                                            onChange={(e) => { setmodel(e.target.value); }}
                                            id="Model"
                                            type="text"
                                            autoComplete="off"
                                            className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                        />
                                    </div>

                                </div>
                                <div className="mb-5">
                                    <label
                                        htmlFor="Product"
                                        className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                    >
                                        Description
                                    </label>
                                    <JoditEditor
                                        // ref={editor}
                                        value={content}
                                        tabIndex={5}
                                        sanitizeHTML={true} // Set to true to remove HTML tags
                                        onChange={onChange}
                                    />
                                </div>


                            </div>
                            <div


                                className="  border-[1px] p-2 bg-[#f7f7f7] rounded border-gray-300 " >
                                <div
                                    ref={variantsRef}
                                    className="flex flex-col mb-5 ">
                                    <label className="font-semibold text-xl">Variants & options </label>
                                    <span className="text-sm  mt-1" > Customize the product variants, including size, color, etc. </span>
                                </div>

                                {productOptionDetail.length == 0 ?
                                    inputFields.map((item, indexs) => (
                                        <div key={indexs} className="grid lg:grid-cols-1 mb-5 md:grid-cols-2 md:gap-8 gap-4">
                                            <div className="grid lg:grid-cols-1 mb-5 md:grid-cols-2 md:gap-8 gap-4">
                                                <div className="border-b-[1px] border-[#c6c6c6]  "></div>

                                                <div className="flex flex-col space-y-3  ">
                                                    <div className="flex justify-center  items-center  space-x-2 ">
                                                        <div className="w-5/12 " >

                                                            <div className="dropdowncategory">
                                                                <div className="control">
                                                                    <div className="selected-value">
                                                                        <div
                                                                            onClick={() => {
                                                                                const newHideShowStates = [...hideShowStates];
                                                                                newHideShowStates[indexs] = !newHideShowStates[indexs];
                                                                                setHideShowStates(newHideShowStates);
                                                                            }

                                                                            }

                                                                            className=" p-3 border-[1px] border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md">
                                                                            {categoryvalue[indexs]}
                                                                        </div>

                                                                        {hideShowStates[indexs] && (
                                                                            <div class="relative">
                                                                                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                                                                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                                                                    </svg>
                                                                                </div>
                                                                                <input
                                                                                    ref={inputRef}
                                                                                    class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                                                    type="text"
                                                                                    placeholder="Search..."
                                                                                    // value={getDisplayValue()}
                                                                                    name="searchTerm"
                                                                                    onChange={(e) => {
                                                                                        setQuery(e.target.value);
                                                                                        // setValue(null);
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        // Toggle the corresponding hideShow state
                                                                                        const newIsOpenStates = [...isOpenStates];
                                                                                        newIsOpenStates[indexs] = !newIsOpenStates[indexs];
                                                                                        setIsOpenStates(newIsOpenStates);

                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )}


                                                                    </div>

                                                                    <div className={`arrow ${isOpenStates[indexs] ? "open" : ""}`}></div>
                                                                </div>

                                                                <div className={`options ${isOpenStates[indexs] ? "open" : ""}`}>
                                                                    {filter(ProductOptions).map((option, index) => (
                                                                        <div
                                                                            name="option_id"
                                                                            onClick={() => {
                                                                                selectOption(option, index);
                                                                                // Toggle the corresponding hideShow state
                                                                                const newIsOpenStates = [...isOpenStates];
                                                                                newIsOpenStates[indexs] = !newIsOpenStates[indexs];
                                                                                setIsOpenStates(newIsOpenStates);
                                                                                handleChangeInput("option_id", option, indexs)

                                                                                // setisShowList();

                                                                            }}
                                                                            value={option.option_name}
                                                                            className={`option ${option["option_name"] === value ? "selected" : ""}`}
                                                                            key={`${"id"}-${index}`}
                                                                        >
                                                                            {option.option_name}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div className="w-5/12 " >
                                                            <div className="relative">
                                                                <input
                                                                    type="text"
                                                                    onFocus={() => {
                                                                        handleFocus(indexs)
                                                                    }}
                                                                    onBlur={() => {
                                                                        handleBlur(indexs)
                                                                    }}
                                                                    value={searchQuery}
                                                                    onChange={(e) => handleSearch(e.target.value, indexs)}
                                                                    className="p-3 pl-8 rounded  w-full border border-gray-200  focus:bg-white focus:outline-none "
                                                                    placeholder="Type to search..."
                                                                />
                                                                <svg
                                                                    className="w-4 h-4 absolute left-2.5 top-3.5"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke="currentColor"
                                                                >
                                                                    <path
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        stroke-width="2"
                                                                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                                                    />
                                                                </svg>

                                                            </div>
                                                            <div className="flex flex-wrap mt-1 gap-2">
                                                                {selectedOptionsList[indexs]?.map((option, optionIndex) => (
                                                                    <div
                                                                        key={optionIndex}
                                                                        className="bg-blue-100 p-1 rounded flex items-center"
                                                                    >
                                                                        {option}
                                                                        <button
                                                                            type="button"
                                                                            className="ml-2 text-red-500 hover:text-red-700"
                                                                            onClick={() => handleRemove(indexs, optionIndex)}
                                                                        >
                                                                            <MdCancel />
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {isShowList[indexs] && (
                                                                <ul className="bg-white border border-gray-100 w-full mt-2">
                                                                    {filteredItemsList[indexs]
                                                                        .filter(item => !selectedOptionsInPreviousDropdowns?.includes(item?.option_value_name))
                                                                        .map((item, index) => (
                                                                            <li
                                                                                key={indexs}
                                                                                onClick={() => {
                                                                                    handleSelect(item, indexs);
                                                                                }}
                                                                                className="pl-8 pr-2 py-1 border-b-2 border-gray-100 relative cursor-pointer hover:bg-yellow-50 hover:text-gray-900"
                                                                            >
                                                                                {/* You can customize the display of each item as needed */}
                                                                                {item?.option_value_name}
                                                                            </li>
                                                                        ))}
                                                                </ul>
                                                            )}

                                                        </div>

                                                        <div


                                                            className="flex space-x-1">
                                                            {indexs > 0 && (
                                                                <div className="rounded-full cursor-pointer text-center flex justify-center items-center w-6 h-6 bg-slate-300 p-1" onClick={() => handleRemoveFields(indexs)}>
                                                                    <MdCancel />
                                                                </div>
                                                            )}
                                                            <div className="rounded-full cursor-pointer text-center flex justify-center items-center w-6 h-6 bg-slate-300 p-1" onClick={handleAddFields}>
                                                                <MdAdd />
                                                            </div>


                                                        </div>
                                                    </div>
                                                    <div className=" flex ml-[11px] space-x-3 ">
                                                        <input
                                                            type="number"
                                                            name="price"
                                                            required={selectedOptionsList[indexs]?.length > 0}
                                                            value={item.price_inr}
                                                            onChange={(event) => handleChangeInput("price_inr", event, indexs)}
                                                            placeholder="price in inr"
                                                            className=" p-3 border-[1px] border-[#e5e7eb] focus:outline-0  w-5/12 text-sm font-medium rounded-md"

                                                        />
                                                        <input
                                                            type="number"
                                                            name="price"
                                                            required={selectedOptionsList[indexs]?.length > 0}
                                                            value={item.price_usd}
                                                            onChange={(event) => handleChangeInput("price_usd", event, indexs)}
                                                            placeholder="price in usd"
                                                            className=" p-3 border-[1px] border-[#e5e7eb] focus:outline-0  w-5/12 text-sm font-medium rounded-md"

                                                        />

                                                    </div>

                                                    <div className=" flex ml-[11px] space-x-3 ">


                                                        <select
                                                            id="Status"
                                                            required={selectedOptionsList[indexs]?.length > 0}
                                                            // value={selectedOption}
                                                            onChange={(event) => handleChangeInput("pricetype", event, indexs)}
                                                            name="pricetype"
                                                            className={`select  focus:outline-0 w-5/12  text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                                                        >
                                                            <option value=""> Price Type</option>
                                                            <option value="inc">Increment</option>
                                                            <option value="dec">Decrement</option>
                                                        </select>




                                                        <select
                                                            id="Status"
                                                            // value={selectedOption}
                                                            onChange={(event) => handleChangeInput("type", event, indexs)}
                                                            name="type"
                                                            className={`select  focus:outline-0 w-5/12  text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                                                        >
                                                            <option value="">Type</option>
                                                            <option value="radio">radio</option>
                                                            <option value="select">select</option>
                                                            <option value="checkbox">checkbox</option>
                                                            <option value="text">text</option>
                                                            <option value="textarea">textarea</option>
                                                            <option value="file">file</option>
                                                            <option value="date">date</option>
                                                            <option value="time">time</option>
                                                            <option value="date&time">date & time</option>


                                                        </select>


                                                    </div>
                                                    <div className=" flex ml-[11px] space-x-3 ">
                                                        <input
                                                            type="text"
                                                            name="quantity"
                                                            value={item.quantity}
                                                            onChange={(event) => handleChangeInput("quantity", event, indexs)}
                                                            placeholder="quantity"
                                                            className=" p-3 border-[1px]  border-[#e5e7eb] focus:outline-0 w-5/12 text-sm font-medium rounded-md"

                                                        />


                                                    </div>


                                                </div>


                                            </div>

                                        </div>
                                    ))
                                    :
                                    <>

                                        {productOptionDetail.map((item, indexs) => (
                                            <div key={indexs} className="grid lg:grid-cols-1 mb-5 md:grid-cols-2 md:gap-8 gap-4">
                                                <div className="grid lg:grid-cols-1 mb-5 md:grid-cols-2 md:gap-8 gap-4">
                                                    <div className="border-b-[1px] border-[#c6c6c6]  "></div>
                                                    <span className="text-[white] text-sm p-3 bg-[#fd397a] rounded-sm " > If the inventory has already been added, you cannot bind more options to this product.</span>
                                                    <div className="flex flex-col space-y-3  ">
                                                        <div className="flex justify-center  items-center  space-x-2 ">
                                                            <div className="w-5/12 " >

                                                                <div className="dropdowncategory">
                                                                    <div className="control">
                                                                        <div className="selected-value">
                                                                            <div
                                                                                onClick={() => {
                                                                                    const newHideShowStates = [...hideShowStates];
                                                                                    newHideShowStates[indexs] = !newHideShowStates[indexs];
                                                                                    setHideShowStates(newHideShowStates);
                                                                                }

                                                                                }

                                                                                className=" p-3 border-[1px] border-[#e5e7eb] focus:outline-0 w-full text-sm font-medium rounded-md">
                                                                                {item?.option_name}
                                                                            </div>

                                                                            {hideShowStates[indexs] && (
                                                                                <div class="relative">
                                                                                    <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                                                                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                                                                        </svg>
                                                                                    </div>
                                                                                    <input
                                                                                        ref={inputRef}
                                                                                        class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                                                        type="text"
                                                                                        placeholder="Search..."
                                                                                        // value={getDisplayValue()}
                                                                                        name="searchTerm"
                                                                                        onChange={(e) => {
                                                                                            setQuery(e.target.value);
                                                                                            // setValue(null);
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            // Toggle the corresponding hideShow state
                                                                                            const newIsOpenStates = [...isOpenStates];
                                                                                            newIsOpenStates[indexs] = !newIsOpenStates[indexs];
                                                                                            setIsOpenStates(newIsOpenStates);

                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            )}


                                                                        </div>

                                                                        <div className={`arrow ${isOpenStates[indexs] ? "open" : ""}`}></div>
                                                                    </div>

                                                                    <div className={`options ${isOpenStates[indexs] ? "open" : ""}`}>
                                                                        {filter(ProductOptions).map((option, index) => (
                                                                            <div
                                                                                name="option_id"
                                                                                onClick={() => {
                                                                                    selectOption(option, index);
                                                                                    // Toggle the corresponding hideShow state
                                                                                    const newIsOpenStates = [...isOpenStates];
                                                                                    newIsOpenStates[indexs] = !newIsOpenStates[indexs];
                                                                                    setIsOpenStates(newIsOpenStates);
                                                                                    handleChangeInput("option_id", option, indexs)

                                                                                    // setisShowList();

                                                                                }}
                                                                                value={option.option_name}
                                                                                className={`option ${option["option_name"] === value ? "selected" : ""}`}
                                                                                key={`${"id"}-${index}`}
                                                                            >
                                                                                {option.option_name}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div className="w-5/12 " >

                                                                <div className="flex flex-wrap mt-1 gap-2">

                                                                    <div
                                                                        key={indexs
                                                                        }
                                                                        className="bg-blue-100 p-1 rounded flex items-center"
                                                                    >
                                                                        {item?.option_value_name}
                                                                        <button
                                                                            type="button"
                                                                            className="ml-2 text-red-500 hover:text-red-700"
                                                                        >
                                                                            <MdCancel />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                {/* {isShowList[indexs] && ( */}
                                                                {/* <ul className="bg-white border border-gray-100 w-full mt-2">

                                                                <li

                                                                    className="pl-8 pr-2 py-1 border-b-2 border-gray-100 relative cursor-pointer hover:bg-yellow-50 hover:text-gray-900"
                                                                >
                                                                  
                                                                    {item?.option_value_name}
                                                                </li>
                                                            </ul> */}
                                                                {/* )} */}

                                                            </div>

                                                            {/* <div


                                                            className="flex space-x-1">
                                                            {indexs > 0 && (
                                                                <div className="rounded-full cursor-pointer text-center flex justify-center items-center w-6 h-6 bg-slate-300 p-1" onClick={() => handleRemoveFields(indexs)}>
                                                                    <MdCancel />
                                                                </div>
                                                            )}
                                                            <div className="rounded-full cursor-pointer text-center flex justify-center items-center w-6 h-6 bg-slate-300 p-1" onClick={handleAddFields}>
                                                                <MdAdd />
                                                            </div>


                                                        </div> */}
                                                        </div>
                                                        <div className=" flex ml-[11px] space-x-3 ">
                                                      
                                                             <div  className=" p-3 border-[1px] border-[#e5e7eb] focus:outline-0  w-5/12 text-sm font-medium rounded-md">
                                                             ₹  {item.price_inr}
                                                            </div>
                                                           {/* <input
                                                                type="number"
                                                                name="price"
                                                                required={selectedOptionsList[indexs].length > 0}
                                                                value={item.price_usd}
                                                                disabled
                                                                onChange={(event) => handleChangeInput("price_usd", event, indexs)}
                                                                placeholder="price in usd"
                                                                className=" p-3 border-[1px] border-[#e5e7eb] focus:outline-0  w-5/12 text-sm font-medium rounded-md"

                                                            /> */}


                                                            <div  className=" p-3 border-[1px] border-[#e5e7eb] focus:outline-0  w-5/12 text-sm font-medium rounded-md">
                                                            $  {item.price_usd}
                                                            </div>
                                                           {/* <input
                                                                type="number"
                                                                name="price"
                                                                required={selectedOptionsList[indexs].length > 0}
                                                                value={item.price_usd}
                                                                disabled
                                                                onChange={(event) => handleChangeInput("price_usd", event, indexs)}
                                                                placeholder="price in usd"
                                                                className=" p-3 border-[1px] border-[#e5e7eb] focus:outline-0  w-5/12 text-sm font-medium rounded-md"

                                                            /> */}

                                                        </div>
                                                      
                                                        <div className=" flex ml-[11px] space-x-3 ">


                                                            <select
                                                                id="Status"
                                                                required={selectedOptionsList[indexs]?.length > 0}
                                                                value={item?.price_type}
                                                                disabled
                                                                onChange={(event) => handleChangeInput("pricetype", event, indexs)}
                                                                name="pricetype"
                                                                className={`select  focus:outline-0 w-5/12  text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                                                            >
                                                                <option value=""> Price Type</option>
                                                                <option value="inc">Increment</option>
                                                                <option value="dec">Decrement</option>
                                                            </select>




                                                            <select
                                                                id="Status"
                                                                value={item?.option_type}
                                                                disabled
                                                                // onChange={(event) => handleChangeInput("type", event, indexs)}
                                                                name="type"
                                                                className={`select  focus:outline-0 w-5/12  text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                                                            >
                                                                <option value="">Type</option>
                                                                <option value="radio">radio</option>
                                                                <option value="select">select</option>
                                                                <option value="checkbox">checkbox</option>
                                                                <option value="text">text</option>
                                                                <option value="textarea">textarea</option>
                                                                <option value="file">file</option>
                                                                <option value="date">date</option>
                                                                <option value="time">time</option>
                                                                <option value="date&time">date & time</option>


                                                            </select>


                                                        </div>
                                                        <div className=" flex ml-[11px] space-x-3 ">
                                                        <input
                                                                type="text"
                                                                name="quantity"
                                                                value={item?.quantity}
                                                                onChange={(event) => handleChangeInput("quantity", event, indexs)}
                                                                placeholder="quantity"
                                                                className=" p-3 border-[1px]  border-[#e5e7eb] focus:outline-0 w-5/12 text-sm font-medium rounded-md"

                                                            />
                                                                </div>
                                                    </div>


                                                </div>

                                            </div>
                                        ))}
                                    </>


                                }

                                {/* <div className="border-b border-dashed -[1px] border-[#c6c6c6] mb-5  "></div>

                                <div className="flex justify-center items-center space-y-9 mb-5">
                                    <span className="text-sm">Product has the same EAN/UPC code for all variants</span>

                                    <div className="flex justify-center mb-3 items-center space-x-[1px]">
                                        <label className=" flex bg-slate-300 rounded text-center justify-center items-center px-3 p-1 space-x-1">
                                            <span>Yes </span>
                                            <input
                                                name="code"
                                                required
                                                placeholder="code"
                                                checked={code_for_all_variant == "1"}
                                                onChange={(e) => { setcode_for_all_variant(e.target.value); }}
                                                id="code"
                                                type="radio"
                                                autoComplete="off"
                                                className=" focus:outline-0 font-medium rounded-md"
                                            />
                                        </label>

                                        <label className=" flex text-center px-3 bg-slate-300 rounded justify-center items-center p-1 space-x-1">


                                            <span>No </span>
                                            <input
                                                name="code"
                                                required
                                                placeholder="code"
                                                checked={code_for_all_variant == "0"}
                                                onChange={(e) => { setcode_for_all_variant(e.target.value); }}
                                                id="code"
                                                type="radio"
                                                autoComplete="off"
                                                className=" focus:outline-0 font-medium rounded-md"
                                            />
                                        </label>
                                    </div>


                                </div>
                                <div className="border-b border-dashed -[1px] border-[#c6c6c6] mb-5  "></div>
                                <div className="flex space-x-3 items-center justify-center">
                                    <span


                                        className="text-sm">
                                        All variants

                                    </span>
                                    <Input
                                        name="URL"
                                        required
                                        value={product_code}
                                        onChange={(e) => setproduct_code(e.target.value)}
                                        id="URL"
                                        type="text"
                                        autoComplete="off"
                                        className=" focus:outline-0 w-7/12 text-sm font-medium rounded-md"
                                    />
                                </div> */}
                                {/* 
                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <Link
                            to="../brandmanagement"
                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                        >
                            <MdOutlineClose className="mr-1" />
                            Cancel
                        </Link>
                        {!addloadProductOptions ?
                            <button className="btn btn-md bg-color_theme p-2_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                                <MdOutlineCheck className="mr-1" />
                                Add Option Value
                            </button>
                            :
                            <button
                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                type="button"
                            //  onClick={()=> changeStep("4")}
                            >
                                <svg
                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Loading..
                            </button>
                        }

                    </div> */}
                            </div>
                            <span className="text-[white] text-sm p-3 bg-[#fd397a] rounded-sm " > If the inventory has already been added, you cannot bind more options to this product.</span>
                            <div className="  border-[1px] p-2 relative bg-[#f7f7f7] rounded border-gray-300 " >
                         

                                <div
                                    ref={media}

                                    className="flex flex-col mb-5 ">
                                    <label className="font-semibold text-xl">Media</label>
                                    <span className="text-sm  mt-1" >Manage your product's image gallery.</span>
                                </div>
                                {
                                    processedLoader &&
                                    <div className="p-3  w-full   absolute z-50 flex justify-center left-0 bottom-0 top-[28%] ">

                                        <svg
                                            className="animate-spin mr-1 h-12 w-12 text-[#c6c6c6]"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle className="opacity-75" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path
                                                className="opacity-75  flex justify-center"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                            ></path>
                                        </svg>


                                    </div>
                                }

                                <div className="text-center z-0 mb-5">
                                    <ImgCrop
                                        showGrid
                                        rotationSlider
                                        aspectSlider
                                        showReset
                                    >
                                        <Upload
                                            listType="picture-circle"
                                            accept="image/*"
                                            fileList={logofile}
                                            onChange={({ fileList }) => { LogChange({ fileList, actionType: 'upload' }); setProcessedLoader(true) }}
                                            onRemove={(file) => handleImageDelete(file)}
                                        >
                                            <span className="btn btn-md bg-color_theme text-[#000] text-xs hover:text-color_theme hover:bg-[#000]">Upload </span>
                                        </Upload>
                                    </ImgCrop>
                                    <div className="border-dashed border-t-[2px] my-5 "></div>

                                    <div className="mb-5 w-full flex space-x-1 justify-center flex-wrap items-center">
                                        {logofile?.map((file, index) => (
                                            <div key={index} className="mb-3 flex items-center space-x-2 justify-center">

                                                {index === 0 ? (
                                                    <>
                                                        <img
                                                            crossOrigin="true" src={file?.thumbUrl} alt={`Image ${index}`} className="w-10 h-10 object-cover" />
                                                        <span

                                                            className="text-sm p-3 bg-white rounded-md border-[1.7px]">Default Image</span>
                                                    </>
                                                ) : (
                                                    <>
                                                      
                                                        <img src={file.thumbUrl} alt={`Image ${index}`} className="w-10 h-10 object-cover" />
                                                        {optionArray?.length > 0 ? (
                                                            <select
                                                                id={`Status-${index}`}
                                                                name={`pricetype-${index}`}
                                                                disabled
                                                                defaultValue={optionArray[index - 1]?.option_value_id || ""} // Set the default value
                                                                onChange={(e) => handleSelectChange(index, e.target.value, e.target.options[e.target.selectedIndex].dataset, selectedValues[index]?.uploaded_file[0])}
                                                                className={`select focus:outline-0 text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                                                            >
                                                                {optionArray[index - 1]?.is_variant_image == 1 ? (
                                                                    <>
                                                                        {optionArray.filter(options => options?.option_id === 3).map((options) => (
                                                                            <option
                                                                                key={options?.option_value_id}
                                                                                value={options?.option_value_id}
                                                                                data-option_id={options?.option_id}
                                                                                data-option_value_id={options?.option_value_id}
                                                                                data-type={options?.price_type}
                                                                                data-quantity={options?.quantity}
                                                                                data-price={options?.price}
                                                                                data-pricetype={options?.pricetype}
                                                                            >
                                                                                {options?.option_value_name}
                                                                            </option>
                                                                        ))}
                                                                        <option value="">All Variants</option>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <option value="">All Variants</option>
                                                                        {optionArray.map((options) => (
                                                                            <option
                                                                                key={options?.option_value_id}
                                                                                value={options?.option_value_id}
                                                                                data-option_id={options?.option_id}
                                                                                data-option_value_id={options?.option_value_id}
                                                                                data-type={options?.price_type}
                                                                                data-quantity={options?.quantity}
                                                                                data-price={options?.price}
                                                                                data-pricetype={options?.pricetype}
                                                                            >
                                                                                {options?.option_value_name}
                                                                            </option>
                                                                        ))}
                                                                    </>
                                                                )}
                                                            </select>
                                                        )
                                                            :
                                                            <span>
                                                                Loading Options...</span>
                                                        }
                                                    </>
                                                )}
                                            </div>
                                        ))}


                                    </div>
                                </div>



                            </div>
                            <div className=" border-[1px] p-2 overflow-x-auto  bg-[#f7f7f7] rounded border-gray-300 ">
                                <div
                                    ref={specifications}

                                    className="flex flex-col mb-5 ">
                                    <label className="font-semibold text-xl">Specifications</label>
                                    <span className="text-sm  mt-1" >Manage the product-related specifications..</span>
                                </div>
                                <div className="grid lg:grid-cols-2 mb-5 md:grid-cols-2 md:gap-8 gap-4">
                                    <div>
                                        <label
                                            htmlFor="Categoryidentifier"
                                            className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                        >
                                            Name
                                        </label>
                                        <Input
                                            name="Model"
                                            ref={nameRef}

                                            id="Model"
                                            type="text"
                                            autoComplete="off"

                                            className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                        />
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="Categoryidentifier"
                                            className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                        >
                                            Value
                                        </label>
                                        <Input
                                            name="price"
                                            ref={valueRef}
                                            id="price"
                                            type="text"
                                            autoComplete="off"
                                            className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                        />

                                    </div>
                                </div>
                                <div className="grid lg:grid-cols-2 mb-5 md:grid-cols-2 md:gap-8 gap-4">
                                    <div>
                                        <label
                                            htmlFor="Categoryidentifier"
                                            className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                                        >
                                            Group
                                        </label>
                                        <Input
                                            name="Model"
                                            ref={groupRef}

                                            id="Model"
                                            type="text"
                                            autoComplete="off"
                                            className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                                        />
                                    </div>
                                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                                        <span onClick={handleSubmittable} className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">
                                            <MdAdd className="mr-1" />
                                            Add
                                        </span>
                                        <span
                                            className="btn btn-md bg-[#000] text-color_theme ml-4 text-sm hover:bg-[#000]"
                                            onClick={cleartable}
                                        >
                                            <MdOutlineClose className="mr-1" />
                                            Clear
                                        </span>



                                    </div>
                                </div>
                                {specificationsList.length > 0 &&
                                    <div className="overflow-x-auto">
                                        <table className="mt-4 w-full">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Name</th>
                                                    <th>Value</th>
                                                    <th>Group</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {specificationsList.map((specification, index) => (
                                                    <tr className="text-center text-sm " key={index}>
                                                        {editIndex === index ? (
                                                            <>
                                                                <td>


                                                                    <Input
                                                                        type="text"
                                                                        value={specification.name}
                                                                        className=" focus:outline-0 w-full text-sm font-medium rounded-md"

                                                                        onChange={(e) => {
                                                                            const updatedSpecification = { ...specification, name: e.target.value };
                                                                            const updatedList = [...specificationsList];
                                                                            updatedList[index] = updatedSpecification;
                                                                            setSpecificationsList(updatedList);
                                                                        }}
                                                                    />

                                                                </td>
                                                                <td>

                                                                    <Input
                                                                        type="text"
                                                                        value={specification.value}
                                                                        className=" focus:outline-0 w-full text-sm font-medium rounded-md"

                                                                        onChange={(e) => {
                                                                            const updatedSpecification = { ...specification, value: e.target.value };
                                                                            const updatedList = [...specificationsList];
                                                                            updatedList[index] = updatedSpecification;
                                                                            setSpecificationsList(updatedList);
                                                                        }}
                                                                    />


                                                                </td>

                                                                <td >

                                                                    <Input
                                                                        type="text"
                                                                        value={specification.group}
                                                                        className=" focus:outline-0 w-full text-sm font-medium rounded-md"

                                                                        onChange={(e) => {
                                                                            const updatedSpecification = { ...specification, group: e.target.value };
                                                                            const updatedList = [...specificationsList];
                                                                            updatedList[index] = updatedSpecification;
                                                                            setSpecificationsList(updatedList);
                                                                        }}
                                                                    />







                                                                </td>
                                                                <td>
                                                                    <span onClick={() => handleSave(index)} className="btn btn-xs bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">
                                                                        <MdSave className="mr-1" />

                                                                    </span>
                                                                </td>
                                                            </>




                                                        ) : (
                                                            <>
                                                                <td className="mr-1">{index + 1})</td>
                                                                <td style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>{specification.name || specification.spec_name}</td>
                                                                <td style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>{specification.value || specification.spec_value}</td>
                                                                <td style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>{specification.group || specification.spec_group}</td>
                                                                {/* <td>{specification.name || specification.spec_name}</td>
                                                            <td>{specification.value || specification.spec_value}</td>
                                                            <td>{specification.group || specification.spec_group}</td> */}
                                                                <td>
                                                                    <div className="flex justify-center space-x-1">
                                                                        <div
                                                                            className="btn btn-xs bg-[#000] text-color_theme hover:bg-[#000]"
                                                                            onClick={() => handleDeletetable(index, specification?.id)}
                                                                        >
                                                                            <MdOutlineClose className="mr-1" />

                                                                        </div>
                                                                        <div
                                                                            onClick={() => handleEdit(index)}
                                                                            className="btn btn-xs bg-[#000] text-color_theme hover:bg-[#000]"
                                                                        >
                                                                            <MdEdit className="mr-1" />

                                                                        </div>
                                                                    </div>
                                                                </td>

                                                            </>
                                                        )}
                                                    </tr>
                                                ))}
                                            </tbody>

                                        </table>
                                    </div>

                                }


                            </div>

                        </div>
                        <div className="w-5/12" >
                            <div className="grid lg:grid-cols-1 mb-5 md:grid-cols-1 md:gap-8 gap-4">


                                <div className="flex p-2 bg-[#f7f7f7] rounded border-gray-300  flex-col ">
                                    {!addloadProductCatalog ?
                                        <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                                            Update
                                        </button>
                                        :
                                        <button
                                            className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                            type="button"
                                        //  onClick={()=> changeStep("4")}
                                        >
                                            <svg
                                                className="animate-spin mr-1 h-5 w-5 text-white"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path
                                                    className="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                ></path>
                                            </svg>
                                            Loading..
                                        </button>
                                    }


                                    <div className="mt-3">
                                        <label class="relative bg-[#f1f1f1]  justify-between text-[#837575] w-full border border-[#7E7777] rounded-lg px-2 py-3 focus:outline-none inline-flex items-center mb-5 cursor-pointer">

                                            <span class=" text-sm  font-medium text-gray-900 dark:text-gray-300"> Activate it</span>
                                            <div className="relative">
                                                <input
                                                    checked={is_active == "1"}
                                                    onChange={(e) => { setis_active(e.target.value); }}
                                                    type="checkbox" className="sr-only peer" />
                                                <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                                            </div>
                                        </label>


                                    </div>
                                    <div className="mt-2">
                                        <label class="relative bg-[#f1f1f1]  justify-between text-[#837575] w-full border border-[#7E7777] rounded-lg px-2 py-3 focus:outline-none inline-flex items-center mb-5 cursor-pointer">

                                            <span class=" text-sm  font-medium text-gray-900 dark:text-gray-300"> Approval status</span>
                                            <div className="relative">
                                                <input value="1"
                                                    checked={product_approved == "1"}
                                                    onChange={(e) => { setproduct_approved(e.target.value); }}
                                                    type="checkbox" className="sr-only peer" />
                                                <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                                            </div>
                                        </label>


                                    </div>
                                    {/* <div className="mt-2">
                                    <label class="relative bg-[#f1f1f1]  justify-between text-[#837575] w-full border border-[#7E7777] rounded-lg px-2 py-3 focus:outline-none inline-flex items-center mb-5 cursor-pointer">

                                        <span class=" text-sm  font-medium text-gray-900 dark:text-gray-300">Update other languages data</span>
                                        <div className="relative">
                                            <input value="1"
                                                // onClick={(e) => setalert() } 
                                                type="checkbox" className="sr-only peer" />
                                            <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                                        </div>
                                    </label>


                                </div> */}

                                </div>
                                <div className="flex p-2 bg-[#f7f7f7] rounded border-gray-300  flex-col ">


                                    <div className="mt-3">
                                        <label class="relative bg-[#f1f1f1]  justify-between text-[#837575] w-full border border-[#7E7777] rounded-lg px-2 py-3 focus:outline-none inline-flex items-center mb-5 cursor-pointer">

                                            <p className=" text-sm flex flex-col font-medium text-gray-900 dark:text-gray-300">
                                                <span>Mark as featured </span>
                                                <span className="text-xs text-gray-400 ">
                                                    Mark this product as a featured product, and it will be displayed under the featured product list on the front end.
                                                </span>
                                            </p>
                                            <div className="relative">
                                                <input
                                                    checked={product_featured == "1"}
                                                    onChange={(e) => { setproduct_featured(e.target.value); }}
                                                    type="checkbox" className="sr-only peer" />
                                                <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                                            </div>
                                        </label>


                                    </div>

                                    <div className="mt-2">
                                        <label class="relative bg-[#f1f1f1]  justify-between text-[#837575] w-full border border-[#7E7777] rounded-lg px-2 py-3 focus:outline-none inline-flex items-center mb-5 cursor-pointer">

                                            <p className=" text-sm flex flex-col font-medium text-gray-900 dark:text-gray-300">
                                                <span>Available for cash on delivery (COD)</span>
                                                <span className="text-xs text-gray-400 ">
                                                    Activate this if the product is available for COD.
                                                    This feature is only allowed if the fulfillment method is Shipping.
                                                </span>
                                            </p>
                                            <div className="relative">
                                                <input
                                                    checked={cod_available == "1"}
                                                    onChange={(e) => { setcod_available(e.target.value); }}
                                                    type="checkbox" className="sr-only peer" />
                                                <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                                            </div>
                                        </label>


                                    </div>

                                </div>



                            </div>
                        </div>
                    </form>


                </div>

            </div>
        </>
    );
});

