import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdImageAspectRatio, MdImage, MdEdit } from "react-icons/md";
import { Input, Button, Select } from "react-daisyui";
// import { CityStore, CityStoreContext } from "./store/CityStore";
import { BrandStore, BrandStoreContext, useBrandStore } from "./store/BrandStore";
import { observer } from "mobx-react";
import { useAuthStore } from "../Auth/store/Auth";
import { useState } from "react";
import ImgCrop from "antd-img-crop";
import { Upload } from 'antd';
import axios from "axios";
const SEO_URL = process.env.REACT_APP_SEO_URL;

const EditBrand = observer(() => {
    const { id } = useParams();
    return (
        <BrandStoreContext.Provider
            value={
                new BrandStore({
                    loadeditcitybol: false,
                    brandeditloading: true,
                    id: id,
                })
            }
        >
            <Screen />
        </BrandStoreContext.Provider>
    );
});
export default EditBrand;

const Screen = observer(() => {

    const { getstatus, setaddbrandname, addbrand, setCrop, setBannerDimiensions, getlogoimage, getbanner_image, setBrandFormDetails, updateBrand, setlogofile, Base64logoImage, Base64BannerImage, logofile, BrandFormDetails, crop, logoimage, errorMessages, addbrandname, saved, getseourl, seoUrl, addloadcity, getToken } = useBrandStore();
    const { adminAuthApproved } = useAuthStore();
    // const [logofile, setlogofile] = useState();
    const [bannerfile, setbannerfile] = useState();
    const [croppedImage, setCroppedImage] = useState(null);
    const [checkbase64, setcheckbase64] = useState(false);

    const BASE_URL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);


    console.log("BrandFormDetails", BrandFormDetails);

    const { id } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        if (saved) {
            navigate("../brandmanagement");
        }
    }, [saved]);


    const getLogoSrcFromFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
    };

    const getBase64 = file => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });
    };





    const LogoPreview = async (file) => {
        const src = file.url || (await getLogoSrcFromFile(file));
        const imgWindow = window.open(src);
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        if (imgWindow) {
            const image = new Image();
            image.src = src;
            imgWindow.document.write(image.outerHTML);
        } else {
            window.location.href = src;
        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        updateBrand(id)
        // Perform API call or any other logic using MobX store data
        // Example: addCity();
    };



    const getBannerSrcFromFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
    };

    const LogChange = async ({ fileList: newFileList }) => {
        // Allow only the first file in the list
        const file = newFileList[0];
        // Get the base64 representation of the file
        if (file) {
            const base64Image = await getBase64(file.originFileObj);
            const files = base64Image;
            setBrandFormDetails({
                ...BrandFormDetails,
                logo_image: files,
            })
            getlogoimage(files);

        }

        // Update the logofile state with the newFileList
        setlogofile(newFileList.slice(0, 1));

    };


    const BannerChange = async ({ fileList: newFileList }) => {
        // Allow only the first file in the list
        const file = newFileList[0];
        if (file) {
            const base64Image = await getBase64(file.originFileObj);
            const files = base64Image;
            setBrandFormDetails({
                ...BrandFormDetails,
                banner_image: files,
            })
            getbanner_image(files);
        }
        setbannerfile(newFileList.slice(0, 1));
    };

    const BannerPreview = async (file) => {
        const src = file.url || (await getBannerSrcFromFile(file));
        const imgWindow = window.open(src);

        if (imgWindow) {
            const image = new Image();
            image.src = src;
            imgWindow.document.write(image.outerHTML);
        } else {
            window.location.href = src;
        }
    };
    const [selectedOption, setSelectedOption] = useState("Desktop"); // Default value for Desktop

    const getbannerstatus = (value) => {
        setSelectedOption(value);
    };

    const getAspectByOption = () => {
        switch (selectedOption) {
            case 'Desktop': // Desktop
                return 16 / 9; // Example aspect ratio for Desktop
            case 'Ipad': // iPad or Mobile
                return 4 / 3; // Example aspect ratio for iPad or Mobile
            case 'Mobile': // iPad or Mobile
                return 9 / 16; // Example aspect ratio for iPad or Mobile
        }
    };
    
    const handleAspectRatioChange = (aspect) => {
        let dimensions;

        switch (aspect) {
            case 16 / 9:
                dimensions = { width: 480, height: 270 };
                break;
            case 9 / 1:
                dimensions = { width: 500, height: 500 };
                break;
            // Add more cases for other aspect ratios
            default:
                dimensions = { width: 200, height: 200 };
        }

        setCrop({ aspect, dimensions });
    };

    const getDimensionsByOption = () => {
        let dimensions
        switch (selectedOption) {
            case 'Desktop':
                dimensions = { width: 2000, height: 500 };
            case 'Ipad':
                dimensions = { width: 1024, height: 576 };
            case 'Mobile':
                dimensions = { width: 640, height: 360 };
            default:
                dimensions = { width: 200, height: 200 };
        }
        setBannerDimiensions({ dimensions })
    };




    const handleCrop = croppedImageUrl => {
        setCroppedImage(croppedImageUrl);
    };
    const isBase64 = (str) => {
        const base64Regex = /^(data:image\/([a-zA-Z]+);base64,)/;
        return base64Regex.test(str);
    };
    console.log("checking", isBase64(BrandFormDetails.banner_image))
    return (
        <>
            <div className="flex justify-between">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">Add Brand</p>
                    <p className=" opacity-80 text-sm">Home /brand/ Edit</p>
                </div>
                <div>
                    <Link
                        to="../brandmanagement"
                        className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                        Back
                    </Link>
                </div>
            </div>
            <div className="card bg-base-100 shadow-md p-6 mt-8">
                <form onSubmit={handleSubmit} >
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                        <div>
                            <label
                                htmlFor="Brandname"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Brand name<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="Brandname"
                                required
                                value={BrandFormDetails.name}
                                onChange={(e) => {
                                    setBrandFormDetails({
                                        ...BrandFormDetails,
                                        name: e.target.value,
                                    });
                                    getseourl(e.target.value);
                                }}
                                placeholder="Brand Name"
                                id="Brandname"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>
                        <div>
                            <label
                                htmlFor="seo"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Brand SEO-friendly URL<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="seo"
                                required
                                value={
                                    BrandFormDetails.seo_url
                                }
                                onChange={(e) =>

                                    setBrandFormDetails({
                                        ...BrandFormDetails,
                                        seo_url: e.target.value,
                                    })

                                }

                                placeholder="SEO-friendly URL"
                                id="seo"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>

                        <div>
                            <label
                                htmlFor="Status"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Brand Status
                            </label>
                            <select
                                id="Status"
                                value={BrandFormDetails?.is_active}
                                onChange={(e) => setBrandFormDetails({
                                    ...BrandFormDetails,
                                    is_active: e.target.value,
                                })}
                                name="Status"
                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                            >
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </select>
                        </div>


                    </div>
                    <div className="border-t-2  border-dashed mt-9 ml-3 ">
                        <label htmlFor="image" className="block text-md font-semibold text-zinc-700 mt-3 dark:text-stone-400 mb-2">
                            Logo<span className="text-[#f00]">*</span>
                        </label>
                        <div className="flex mb-2 space-x-2">
                            <label className={`max-w-[120px] text-center w-[100%] border-color_theme border-[1px] cursor-pointer p-2 rounded-md ${crop.aspect === 16 / 9 ? 'active' : ''}`}>
                                <input
                                    type="radio"
                                    name="aspectRatio"
                                    value="16:9"
                                    className="btn absolute w-[1px] h-[1px] opacity-0 btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000]"
                                    checked={crop.aspect === 16 / 9}
                                    onChange={() => handleAspectRatioChange(16 / 9)}
                                />
                                16:9
                            </label>
                            <label className={`max-w-[120px] text-center w-[100%] border-color_theme border-[1px] cursor-pointer p-2 rounded-md ${crop.aspect === 1 / 1 ? 'active' : ''}`}>
                                <input
                                    type="radio"
                                    name="aspectRatio"
                                    value="1:1"
                                    className="btn absolute w-[1px] h-[1px] opacity-0 btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000]"
                                    checked={crop.aspect === 1 / 1}
                                    onChange={() => handleAspectRatioChange(1 / 1)}
                                />
                                1:1
                            </label>
                        </div>
                        {/* {imageurl && <img className="w-[50%]" src={imageurl} alt="Selected" />} */}
                        <ImgCrop
                            aspect={crop.aspect}
                            resize={crop.dimensions}
                            showGrid
                            rotationSlider
                            aspectSlider
                            showReset
                            onCrop={handleCrop}
                        >
                            <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-circle"
                                fileList={logofile}
                                onChange={LogChange}
                                onPreview={LogoPreview}
                                className="logo"
                            >

                                {(logofile == undefined && logofile?.length === 0) && !BrandFormDetails.logo_image ? (
                                    <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Upload Logo</span>
                                )
                                    :

                                    (logofile == undefined || logofile?.length === 0) && (

                                        (BrandFormDetails.logo_image == null || BrandFormDetails.logo_image == "") ?
                                            <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Upload Logo</span>
                                            :
                                            <div className="relative group">
                                                {isBase64(BrandFormDetails.logo_image) ?
                                                    <img
                                                        crossOrigin="anonymous"
                                                        src={BASE_URL + `/uploads/brand/${Base64logoImage}`}
                                                        alt="Default Logo"
                                                        className="w-full h-auto rounded-[50%]"
                                                    />
                                                    :
                                                    <img
                                                        crossOrigin="anonymous"
                                                        src={BASE_URL + `/uploads/brand/${BrandFormDetails.logo_image}`}
                                                        alt="Default Logo"
                                                        className="w-full h-auto rounded-[50%]"
                                                    />

                                                }

                                                <div className="absolute inset-0 bg-color_theme bg-opacity-50 rounded-[50%] flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                                                    <MdEdit className="text-white text-lg" />
                                                </div>
                                            </div>
                                    )

                                }

                            </Upload>
                            {/* Conditionally render the default image */}

                        </ImgCrop>



                    </div>
                    <div className="border-t-2 grid md:grid-cols-2 md:gap-8 gap-4 mt-3 border-dashed  ">


                        <div>
                            <label htmlFor="image" className="block text-xl font-semibold mt-3 text-zinc-700 dark:text-stone-400 mb-2">
                                Banner<span className="text-[#f00]">*</span>
                            </label>
                            <div className="mb-5">
                                <label htmlFor="image" className="block text-sm font-semibold mt-3  text-zinc-700 dark:text-stone-400 mb-4">
                                    Display for
                                </label>
                                <select
                                    id="Status"
                                    // value={selectedOption}
                                    onChange={(e) => getbannerstatus(e.target.value)}
                                    name="Status"
                                    className={`select  focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                                >
                                    <option value="Desktop">Desktop</option>
                                    <option value="Ipad">Ipad</option>
                                    <option value="Mobile">Mobile</option>
                                </select>
                            </div>
                            <div className="ml-3">
                                <ImgCrop
                                    aspect={getAspectByOption()} // Set aspect ratio dynamically
                                    resize={getDimensionsByOption()}
                                    showGrid
                                    rotationSlider
                                    // aspectSlider
                                    showReset
                                >
                                    <Upload
                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture"
                                        fileList={bannerfile}
                                        onChange={BannerChange}
                                        onPreview={BannerPreview}
                                        className="banner"
                                    >


                                        {(bannerfile == undefined && bannerfile?.length === 0) && !BrandFormDetails.banner_image ? (
                                            <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Upload Banner</span>
                                        )
                                            :

                                            (bannerfile == undefined || bannerfile?.length === 0) && (
                                                // console.log("checking")
                                                (BrandFormDetails.banner_image == null || BrandFormDetails.banner_image == "") ?
                                                    <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Upload Banner</span>
                                                    :

                                                        isBase64(BrandFormDetails.banner_image) ?
                                                        Base64BannerImage == null ?
                                                                <span className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ">Upload Banner</span>
                                                                :
                                                    <div className="relative group">

                                                                <img
                                                                    crossOrigin="anonymous"
                                                                    src={BASE_URL + `/uploads/brand/${Base64BannerImage}`}
                                                                    alt="Default Logo"
                                                                    className="w-full h-auto "
                                                                />
                                                                
                                                        <div className="absolute inset-0 bg-color_theme bg-opacity-50  flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                                                            <MdEdit className="text-white text-2xl" />
                                                        </div>
                                                    </div>

                                                            :
                                                    <div className="relative group">

                                                            <img
                                                                crossOrigin="anonymous"
                                                                src={BASE_URL + `/uploads/brand/${BrandFormDetails.banner_image}`}
                                                                alt="Default Logo"
                                                                className="w-full h-auto "
                                                            />
                                                            
                                                        <div className="absolute inset-0 bg-color_theme bg-opacity-50  flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                                                            <MdEdit className="text-white text-2xl" />
                                                        </div>
                                                    </div>

                                                        

                                            )

                                        }



                                    </Upload>
                                </ImgCrop>
                            </div>

                        </div>









                    </div>
                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <Link
                            to="../brandmanagement"
                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                        >
                            <MdOutlineClose className="mr-1" />
                            Cancel
                        </Link>
                        {!addloadcity ?
                            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                                <MdOutlineCheck className="mr-1" />
                                Update
                            </button>
                            :
                            <button
                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                type="button"
                            //  onClick={()=> changeStep("4")}
                            >
                                <svg
                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Loading..
                            </button>
                        }

                    </div>
                </form >
            </div >
        </>
    );
});
