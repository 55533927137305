/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { getMethodData, putMethod } from "../ApiMethods/api";
import { observer } from "mobx-react";
import {
  SettingStore,
  useSettingStore,
  UserSettingContext,
} from "./SettingStore/SettingStore";
import { Link } from "react-router-dom";
import { MdAdd, MdCancel, MdEdit } from "react-icons/md";
import { ButtonGroup, Input } from "react-daisyui";
import { useCallback } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";

const Setting = () => {
  const store = new SettingStore({
    isloading: true,
    editloading: false,
  });
  return (
    <UserSettingContext.Provider value={store}>
      <Screen />
    </UserSettingContext.Provider>
  );
};
export default Setting;
const Screen = observer(() => {
  const {
    setting,
    getSetting,
    getImagekey,
    imageurl,
    buttonCheck,
    editbtncheck,
    newcheck,
    disabled,
    disabledCheck,
    disabledon,
    Getkey,
    getvalue,
    getnewfeilds,
    newfields,
  } = useSettingStore();
  const BASE_URL = process.env?.REACT_APP_BASE_URL;
  const Token = localStorage.getItem("Admintoken");
  const [formData, setFormData] = useState({});
  const [settingLoader, setsettingLoader] = useState(false);

  useEffect(() => {
    getImagekey();
  }, []);

  function onsubmit(e) {
    e.preventDefault();
    setsettingLoader(true)
    const formDataObj = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataObj.append(key, value);
    });

    
    putMethod(`/api/admin/setting`, formDataObj, Token)
      .then((res) => {
        if (res?.data?.status) {
          setsettingLoader(false)
          disabledon();
          newcheck();
          getSetting();
          toast.success("Settings are updated successfully.");
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        setsettingLoader(true)

      });
  }
  return (
    <div>
      {newfields ? (
        <>
          <form
            onSubmit={(e) => {
              onsubmit(e);
              e.preventDefault();
            }}
          >
            <div className="flex justify-between">
              <div className=" mb-4">
                <p className=" text-2xl font-semibold">Settings</p>
              </div>
            </div>

            <div className="card bg-base-100 shadow-md p-6 mt-8">
              <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                {setting.map((data, index) => (
                  <>
                    <div>
                      <label
                        htmlFor="SeasonName"
                        className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                      >
                        {data.label}
                      </label>
                      {data?.input_type == "text" ? (
                        <>
                          <input
                            name={data?.key}
                            key={data?.key}
                            onChange={(e) => {
                              const value = e.target.value;
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                [data?.key]: value,
                              }));
                            }}
                            id={data?._id}
                            defaultValue={data?.value}
                            type={data?.input_type}
                            required
                            autoComplete="off"
                            className="input settingUpdateInput focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                          />
                        </>
                      ) : (
                        <>
                          <input
                            // name="SeasonName"
                            name={data?.key}
                            key={data?.key}
                            onChange={(e) => {
                              const file = e.target.files[0];
                              // getimage(file)
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                [data?.key]: file,
                              }));
                            }}
                            id={data?._id}
                            type={data?.input_type}
                            autoComplete="off"
                            className=" input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                          />
                          <img
                            crossOrigin="anonymous"
                            className="w-full bg-[#f2f2f2] p-2"
                            src={BASE_URL + `/image/${imageurl}`}
                          />
                        </>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className="flex justify-between">
              <div className=" mb-4"></div>
              {editbtncheck ? (
                <>
                  <div className="mt-3 flex space-x-3 ">
                    {!settingLoader ?    <button
                      type="submit"
                      className="btn  btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                      Update
                    </button>:  <button 
                           className="btn btn-sm bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                            type="button"
                          //  onClick={()=> changeStep("4")}
                          >
                          <svg
                              className="animate-spin mr-1 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              >
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                          </svg>
                            Loading..
                          </button> }
                 
                    
                    <div
                      onClick={(e) => {
                        newcheck();
                        disabledon();
                      }}
                      className=""
                    >
                      <button className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                        Cancel
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="mt-3">
                  <button
                    onClick={(e) => {
                      buttonCheck();
                      disabledCheck();
                      getnewfeilds();
                    }}
                    className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                  >
                    <MdEdit />
                    Edit
                  </button>
                </div>
              )}
            </div>
          </form>
        </>
      ) : (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="flex justify-between">
              <div className=" mb-4">
                <p className=" text-2xl font-semibold">Settings</p>
              </div>
              {/* <div>
          <Link to="./addsetting" className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"><MdAdd/>Add Setting</Link>
        </div> */}
            </div>

            <div className="card bg-base-100 shadow-md p-6 mt-8">
              <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                {setting.map((data, index) => (
                  <>
                    <div>
                      <label
                        key={index}
                        htmlFor="SeasonName"
                        className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                      >
                        {data.label}
                      </label>
                      {data?.input_type == "text" ? (
                        <>
                          <input
                            name="SeasonName"
                            key={data?.key}
                            placeholder="Season Name"
                            disabled={true}
                            onChange={(e) => {
                              getvalue(e.target.value);
                              Getkey(e.target.value);
                            }}
                            id={data?._id}
                            defaultValue={data?.value}
                            type={data?.input_type}
                            required
                            autoComplete="off"
                            className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                          />
                        </>
                      ) : (
                        <>
                          <input
                            name={data?.key}
                            disabled={true}
                            key={data?.key}
                            onChange={(e) => {
                              const file = e.target.files[0];
                              // getimage(file)
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                [data?.key]: file,
                              }));
                            }}
                            id={data?._id}
                            type={data?.input_type}
                            autoComplete="off"
                            className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                          />
                          <img
                            crossOrigin="anonymous"
                            className="w-full bg-[#f2f2f2] p-2"
                            src={BASE_URL + `/image/${imageurl}`}
                          />
                        </>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className="flex justify-between">
              <div className=" mb-4"></div>
              {editbtncheck ? (
                <>
                  <div className="mt-3 flex space-x-3 ">
                    <button className="btn  btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                      <MdEdit />
                      Update
                    </button>
                    <div>
                      <button
                        onClick={(e) => {
                          newcheck();
                          disabledon();
                        }}
                        className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                      >
                        <MdCancel />
                        Cancel
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="mt-3">
                  <button
                    onClick={(e) => {
                      buttonCheck();
                      disabledCheck();
                      getnewfeilds();
                    }}
                    className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                  >
                    <MdEdit />
                    Edit
                  </button>
                </div>
              )}
            </div>
          </form>
        </>
      )}
    </div>
  );
});
