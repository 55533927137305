import React, { useEffect, useRef, useState } from "react";
import { MdAdd, MdOutlineLocationOn } from "react-icons/md";
import { RxEyeOpen, RxPencil1 } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import Moment from "react-moment";
import Filter from "./SeasonFilter/Filter";
import {
  useSeasonStore,
  SeasonStore,
  SeasonStoreContext,
} from "./store/SeasonStore";
import { useLeagueStore } from "../LeagueManagement/store/LeagueStore";
import { useAuthStore } from "../Auth/store/Auth";
const SeasonManagement = () => {
  const store = new SeasonStore({
    isloading: true,
    editloading: false,
    slectcity: true,
  });
  return (
    <SeasonStoreContext.Provider value={store}>
      <Screen />
    </SeasonStoreContext.Provider>
  );
};
export default SeasonManagement;

const Screen = observer(() => {
  const navigate = useNavigate();
  const {
    season,
    SeasonLoader,
    Seasonpopup,
    loadSeason,
    loadlist,
    nolist,
    setseasonpopup,
    getseasonid,
    getstatus,
    statusChange,
    addLeague,
    addleagueload,
    modalclose,
    getToken
  } = useSeasonStore();
  const { getFilterLeaguecourt } = useLeagueStore();
  const { adminAuthApproved } = useAuthStore();
  
  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }
  
  }, [adminAuthApproved]);

  const [isChecked, setIsChecked] = useState(false);
  const labelRef = useRef(null);

  useEffect(()=>{
    if(!addleagueload){
      setIsChecked(false)
    }
  },[addleagueload])

  function currentLeague() {
    navigate("../leaguemanagement");
  }
  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Season Management</p>
          {/* <p className=" opacity-80 text-sm">Home / Season</p> */}
        </div>
        <div>
          <Link
            to="./addseason"
            className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
          >
            <MdAdd />
            Add Season
          </Link>
        </div>
      </div>
      <Filter />
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th>#</th>
                <th>Season Name</th>
                <th>City</th>
                <th>Year</th>
                <th>Start date</th>
                <th>End date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {loadlist && (
                <>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                </>
              )}
              {season.map((data, index) => (
                <tr key={index} className="capitalize">
                  <td>{index + 1}</td>
                  <td>{data?.name ?? "N/A"}</td>
                  <td>{data?.city ? data?.city.city_name ?? "N/A" : "N/A"}</td>
                  <td>{data?.year === "" ? "N/A" : data?.year}</td>
                  <td>
                    {<Moment format="MM/DD/YYYY">{data?.start_date}</Moment> ??
                      "N/A"}
                  </td>
                  <td>
                    {<Moment format="MM/DD/YYYY">{data?.end_date}</Moment> ??
                      "N/A"}
                  </td>
                  <th>
                    {!data?.is_league_created ?
                     <label
                      title="Change Status"
                      htmlFor="courtstatus"
                      className={`btn capitalize ${
                        data?.isActive
                          ? "bg-color_theme hover:bg-color_theme"
                          : "bg-warning hover:bg-warning"
                      }  text-[#000] border-hidden  btn-xs`}
                      onClick={(e) => {
                        setseasonpopup(true)
                        getseasonid(data?._id);
                        getstatus(data?.isActive);
                      }}
                    >
                      {data?.isActive ? "Active" : "Inactive"}
                    </label>
                    :
                      <label
                      title="Change Status"
                      // htmlFor="courtstatus"
                      className={`btn capitalize cursor-not-allowed ${
                        data?.isActive
                          ? "bg-color_theme hover:bg-color_theme"
                          : "bg-warning hover:bg-warning"
                      }  text-[#000] border-hidden  btn-xs`}
                      onClick={(e) => {
                        getseasonid(data?._id);
                        getstatus(data?.isActive);
                      }}
                    >
                      {data?.isActive ? "Active" : "Inactive"}
                    </label>
                     }
                   
                  </th>
                  <td className="text-base">
                    <div className="flex items-center space-x-4">
                      {/* <button title="Edit">
                        <Link to={`/seasonmanagement/${data?._id}`}>
                          <RxPencil1 />
                        </Link>
                      </button> */}
                     
                      {data?.is_league_created ? (
                        <Link
                          
                          onClick={()=>  setIsChecked(true)}
                          title="View League"
                          htmlFor="Viewleague"
                          to={`../leaguemanagement/season/${data?._id}`}
                          className={`btn hover:bg-warning capitalize ${"bg-warning"}  text-[#000] border-hidden  btn-xs`}
                          // onClick={(e) => {
                          //   getFilterLeaguecourt();
                          // }}
                        >
                          Views League
                        </Link>
                      ) : (
                        <>
                        {data?.isActive && 
                        <label
                        title="Create League"
                        htmlFor="leaguecreated"
                        className={`btn capitalize ${"bg-color_theme"} hover:bg-color_theme text-[#000] border-hidden  btn-xs`}
                        onClick={(e) => {
                          setIsChecked(true)
                          getseasonid(data?._id);
                        }}
                      >
                        Add League
                      </label>
                        }
                        
                     
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
              {nolist && (
                <tr>
                  <td colSpan={8} className="my-10 text-center">
                    <MdOutlineLocationOn className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">No Season Found</p>
                    <Link
                      to="./addseason"
                      className="btn btn-sm mt-4 bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                      <MdAdd />
                      Add Season
                    </Link>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {Seasonpopup && 
        <>
          <input type="checkbox" id="courtstatus" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
         
                className="btn btn-sm btn-circle absolute right-2 top-2"
                onClick={()=>setseasonpopup(false)}
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status ?
              </h3>
              <div className="modal-action justify-center">
                {!SeasonLoader ?        <label
           
                  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                  onClick={() => statusChange()}
                >
                  Yes
                </label> :   
                <button
                            // type="submit"
                            disabled
                            className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                            // onClick={() => {
                            //   PostMatchscore();
                            // }}
                          >
                            Yes..
                            <svg
                              className="animate-spin mr-1 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </button> }
         
                          <label
            onClick={()=>setseasonpopup(false)}
              // htmlFor="voidstatus"
              className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
            >
              No
            </label>
              </div>
            </div>
          </div>
        
        </>
       
}
          <input checked={isChecked} type="checkbox" id="leaguecreated" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="leaguecreated"
                className="btn btn-sm btn-circle absolute right-2 top-2"
                onClick={()=> setIsChecked(false)}
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to create League.
              </h3>
              <div className="modal-action justify-center">
              {!addleagueload
                        ?
                <label
                  // htmlFor="leaguecreated"
                  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                  onClick={() => addLeague()}
                >
                  Yes
                </label>
                   :
                   <label 
                      className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                      //  type="button"
                      //  htmlFor="leaguecreated"        
                     >
                     <svg
                         className="animate-spin h-5 w-5 text-white"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 24 24"
                         >
                         <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                         <path
                             className="opacity-75"
                             fill="currentColor"
                             d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                         ></path>
                     </svg>
                       Yes
                     </label>
                   }

                <label
                  htmlFor="leaguecreated"
                  className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                  onClick={()=> setIsChecked(false)}
                >
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
