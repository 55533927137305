import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Input } from "react-daisyui";
import { MdOutlineCheck, MdOutlineClose } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { usePlacesWidget } from "react-google-autocomplete";
import {
  useHomecourtStore,
  HomecourtStore,
  HomecourtStoreContext,
} from "./store/HomecourtStore";
import axios from "axios";
import { useAuthStore } from "../Auth/store/Auth";

const AddHomeCourtManagement = observer(() => {
  return (
    <HomecourtStoreContext.Provider
      value={
        new HomecourtStore({
          loadeditcitybol: false,
          editloading: false,
          slectcity: true,
        })
      }
    >
      <Screen />
    </HomecourtStoreContext.Provider>
  );
});

export default AddHomeCourtManagement;

const Screen = observer(() => {
  const {
    getApikey,
    apikeyss,
    name,
    mylatitude,
    mylongitude,
    addHomecourt,
    getcityname,
    getzipcode,
    getname,
    getstatus,
    status,
    saved,
    citydrop,
    getaddress,
    address,
    getlatitude,
    getlongitude,
    getAddress,
    addloadhomecourt,
    getCourtNickname,
    getToken
  } = useHomecourtStore();
  const { adminAuthApproved } = useAuthStore();
  const navigate = useNavigate();
   
  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }
  
  }, [adminAuthApproved]);

  useEffect(() => {
    if (saved) {
      navigate("../homecourtmanagement");
    }
  }, [saved]);


  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [myaddres, setaddress] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [addFirstname, setaddFirstname] = useState("");
  const [HomeCourtValid, setHomeCourtValid] = useState();
  const [charCheck, set3charCheck] = useState();


  const [city, setCity] = useState("");

 



  const AddressCheck =async (address) => {
    // Perform address validation

    await axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apikeyss}`
      )
      .then((response) => {
        const { status, results } = response.data;
        set3charCheck(false);
        
        if (status === "OK" && results.length > 5) {
          setHomeCourtValid(false);

        } else {
          // Address is invalid
          console.error("Error while geocoding:", "error");
          setHomeCourtValid(true);

          // Handle the invalid address as needed
        }
      })
      .catch((error) => {
        console.error("Error while geocoding:", error);
        setHomeCourtValid(true);

      });
  };


  const handlePlaceSelected = async (lat, lng) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apikeyss}`
      );

      const { status, results } = response.data;
      if (status === "OK" && results.length > 5) {
        setHomeCourtValid(false);
        set3charCheck(false);


      } else{
        setHomeCourtValid(true);

      }
      let zipCode = "";
      let cityName = "";
   
      for (let i = 0; i < results.length; i++) {
        const addressComponents = results[i].address_components;
        for (let j = 0; j < addressComponents.length; j++) {
          const component = addressComponents[j];
          if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
          }
          if (component.types.includes("locality")) {
            cityName = component.long_name;
          }
          if (zipCode && cityName) {
            break;
          }
        }
        if (zipCode && cityName) {
          break;
        }
      }

      setzipcode(zipCode);
      setCity(cityName);

     

      // You can use the zipCode and cityName variables as per your requirements
    } catch (error) {
      console.error("Error fetching zip code:", error);
    }
  };



  const { ref } = usePlacesWidget({
    apiKey: apikeyss,
    onPlaceSelected: (place) => {     
      getlatitude(place?.geometry?.location.lat());
      getlongitude(place?.geometry?.location.lng());
      setaddFirstname(place?.address_components[0]?.long_name);
      setaddress(place?.formatted_address);
      setLat(place?.geometry?.location?.lat());
      setLng(place?.geometry?.location?.lng());
      handlePlaceSelected(
        place?.geometry?.location?.lat(),
        place?.geometry?.location?.lng()
      );
      
    },
    options: {
      types: ["geocode", "establishment"],
    },
  });
  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Add Court</p>
        </div>
        <div>
          <Link
            to="../homecourtmanagement"
            className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <form
          onSubmit={(e) => {
            addHomecourt();
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
          <div>
              <label
                htmlFor="CourtNickname"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Court Nickname<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <Input
                name="CourtNickname"
                required
                onChange={(e) => getCourtNickname(e.target.value)}
                placeholder="Court Nickname"
                id="CourtNickname"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="CourtName"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Court Name<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <Input
                // value={addFirstname}
                onChangeCapture={(e) => {
                      const inputValue = e.target.value;
                      if ( inputValue.length  <= 3 && inputValue.length > 0 ){
                        set3charCheck(true);
                      }
                  if (e.target.value !== ref?.current?.value) return;
                  inputValue.length > 3 &&
                    AddressCheck(e.target.value)
                }}
                placeholder="Search Your Homecourt Name Here"
                onChange={getname(ref?.current?.value)}
                type="text"
                required
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                ref={ref}
              />
              {/* <Input
                name="CourtName"
                required
                onChange={(e) => getname(e.target.value)}
                placeholder="Court Name"
                id="CourtName"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              /> */}
              
          {/* {     charCheck < 3 &&  */}
               {HomeCourtValid  === true || charCheck === true  ?  
                <span className=" text-[red] text-xs">
                          Please Provide Valid Home Court
                </span>
                :
                ""
              }
                 
              
              
            </div>
          
            <div>
              <label
                htmlFor="Adderess"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Court Address
                <span className="text-[#f00]">&nbsp;*</span>
              </label>
              <Input
                value={myaddres}
                placeholder="Homecourt address "
                onChange={getaddress(myaddres)}
                type="text"
                required
                readOnly
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />

              {/* <Input
          
                type="text"
                required
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                ref={ref}
                defaultValue=""
              /> */}
            </div>

            {/* <div>
                        <label htmlFor="Adderess" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Court Address<span className="text-[#f00]">&nbsp;*</span></label>
                        <Input 
                        name="Address" 
                        required
                        onChange={(e) => getaddress(e.target.value)}
                        placeholder="Address" 
                        id="Address" 
                        type="text" 
                        autoComplete="off" 
                        className="focus:outline-0 w-full text-sm font-medium rounded-md"
                        />
                    </div> */}

            <div>
              <label
                htmlFor="City"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                City<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                required
                defaultValue=""
                id="city"
                name="city"
                onChange={(e) => getcityname(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="" disabled className="hidden">
                  Select city
                </option>
                {citydrop.map((data, index) => (
                  <option value={data?._id} key={index}>
                    {data?.city_name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="Zipcode"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Zip Code<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <Input
                name="Zipcode"
                required
                value={zipcode}
                onChange={ getzipcode(zipcode)}
                placeholder="Zipcode"
                id="Zipcode"
                type="text"
                maxLength={5}
                readOnly
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="Country"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Country<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                id="Country"
                name="Country"
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="USA">USA</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="Status"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Status<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                id="Status"
                name="Status"
                required
                value={status}// Set default value to "true"
                onChange={(e) => getstatus(e.target.value)}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option  value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="Latitude"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Latitude<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <Input
                name="Latitude"
                // required
                value={lat}
                onChange={getlatitude(lat)}
                placeholder="Latitude"
                id="Latitude"
                type="text"
                readOnly
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="Longitude"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Longitude<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <Input
                name="Longitude"
                // required
                value={lng}
                onChange={getlongitude(lng)}
                placeholder="Longitude"
                id="Longitude"
                type="text"
                readOnly
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
            <Link
              to="../homecourtmanagement"
              className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
            >
              <MdOutlineClose className="mr-1" />
              Cancel
            </Link>
            {!addloadhomecourt ? (
              HomeCourtValid  === true || charCheck === true ?    <button disabled className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
              <MdOutlineCheck className="mr-1" />
              Add Court
            </button> :    <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                <MdOutlineCheck className="mr-1" />
                Add Court
              </button> 
           
            ) : (
              <button
                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                type="button"
                //  onClick={()=> changeStep("4")}
              >
                <svg
                  className="animate-spin mr-1 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Loading..
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
});
