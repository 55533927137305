import {createContext,useContext} from 'react'
import { makeAutoObservable,observable, runInAction } from "mobx";
import {postLogin, getMethodData,patchMethodData} from "../../ApiMethods/api"
import { toast } from "react-toastify";

export class HomeStore {
    user={};
    loaduser = false;
    nolist= false;
    errLoggingIn = "";
    users=[];
    PlayerData=[];
    league_chart_data=[];
    players_chart_data=[];
    revanue_chart_data=[];
    loginLoading = false;
    current_leagues="";
    userid = ""
    matches_this_week="";
    tolal_income="";
    approval_pending_home_courts="";
    total_tieBreakerCount="";
    tolal_leagues="";
    tolal_matches="";
    tolal_players="";
    tolal_seasons="";
    tolal_users="";
    deleteuser_count ="";
    adminAuthApproved = false;
    Token = null;
    status = "" ;
    saved=false;
  
    constructor(isloading = false){
        makeAutoObservable(this)
        this.Token = localStorage.getItem("Admintoken");
        // if (isloading.isloading) {
        //   this.getDashboardCount();
    
        //   return;
        // }
       
    }
    getstatusid = (text) => {
        this.userid = text;
      }
      getNewstatus= (text) => {
        this.status = text;
      }
      
      // get Coupen list
      getDashboardCount = () => {
    runInAction(() => {
      this.loaduser = true;
      this.nolist= false;
      this.errLoggingIn = true;
      this.errloadcoupon = "";
      this.saved = false;
    });
    getMethodData("/api/admin/dashboard", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.loadCoupon = false;
            this.errloadcoupon = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.loadCoupon = false;
            this.errloadcoupon = resp?.data?.message;
            if (resp?.data?.data?.new_users === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            
            this.current_leagues= resp?.data?.data?.counts?.current_leagues;
            this.matches_this_week= resp?.data?.data?.counts?.matches_this_week;
            this.approval_pending_home_courts=resp?.data?.data?.counts?.approval_pending_home_courts
            this.tolal_income= resp?.data?.data?.counts?.tolal_income;
            this.total_tieBreakerCount = resp?.data?.data?.counts?.tieBreakerCount;
            this.tolal_leagues= resp?.data?.data?.counts?.tolal_leagues;
            this.tolal_matches= resp?.data?.data?.counts?.tolal_matches;
            this.tolal_players = resp?.data?.data?.counts?.tolal_players;
            this.tolal_seasons= resp?.data?.data?.counts?.tolal_seasons;
            this.tolal_users= resp?.data?.data?.counts?.tolal_users;
            this.deleteuser_count = resp?.data?.data?.counts?.deletedUserCount;
            this.users = resp?.data?.data?.new_users;
            this.PlayerData= resp?.data?.data?.users_chart_data;
            this.league_chart_data= resp?.data?.data?.league_chart_data;
            this.players_chart_data= resp?.data?.data?.players_chart_data;
            this.revanue_chart_data= resp?.data?.data?.revanue_chart_data;
            this.saved = false;
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.addseasoncourt = false;
          this.loadSeason = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loaduser = false;
          this.loadCoupon = false;
          if (!error.response) {
            this.errloadcoupon = error?.message;
            toast.error(error?.message);
          }
          this.errloadcoupon = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      });
  };
  statusChange = () => {
    runInAction(() => {
      this.nolist= false;
      this.errloaduser = "";
    });
    patchMethodData(`/api/admin/user/status/${this.userid}`,{ isActive : `${!this.status}` },this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.errloaduser = resp?.data?.message;
            // toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.errloaduser = resp?.data?.message;
            this.getDashboardCount();
            toast.success(resp?.data?.message);
          })
          return;
        }
        runInAction(() => {
          this.errloaduser = resp?.data?.message;
        })
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
              this.errloaduser = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              this.errloaduser = error?.response?.data?.message;
        })
        return;
    })
  };



};


export const HomeStoreContext = createContext(new HomeStore());
export const useHomeStore = () => useContext(HomeStoreContext);