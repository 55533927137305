
import { observer } from "mobx-react";
import React from 'react';
import { useEffect } from "react";
import { useRef } from "react";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import trophy from "../assets/images/trophy.png"
import { usePlayersTreeStore,PlayersTreeStoreContext, PlayersTreeStore } from "./store/PlayersTreeStore";

const Playerstree = () => {
  const { id,levelid,leaguename } = useParams();
  const store = new PlayersTreeStore({
    isloading: true,
    id:id,
    levelid:levelid,
  });
  return (
    <PlayersTreeStoreContext.Provider value={store}>
      <Screen />
    </PlayersTreeStoreContext.Provider>
  );
};
export default Playerstree;

const Screen = observer(() => {
  const { id,levelid,leaguename } = useParams();

    const {playertreenolist,playertreedata,totalRounds} = usePlayersTreeStore()

    const goBack = () => {
      window.history.back(); // Go back to the previous pagehistory.goBack(); // Go back to the previous page
    };

    // useEffect(() => {
    //   getPlayersTree() 
    // }, [])
    
 
      const divRef = useRef([]);
      const divRefone = useRef(null);

      useEffect(() => {
        if (divRefone.current) {
            const childDivs = Array.from(divRefone.current.childNodes).filter(
              (node) => node.nodeName === 'DIV'
            );
            if(childDivs.length == 1){
                divRefone?.current?.classList.add('has-ul');
            }
          }
      }, [divRefone]);


    return(
      <>
       <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Hierarchy of Playoff</p>
        </div>
        <div>
          <Link
            // to="../leaguemanagement"
            onClick={goBack}
            className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>

        <div className="w-full 2xl:px-10 lg:px-5 md:px-3 px-4 relative z-1  bg-[#000] py-20 overflow-auto">
            <div className="my-3 text-center">
                <p className="text-[white]  text-2xl">{leaguename} Championship</p>
            </div>

        <div>
            {!playertreenolist &&
            <>
            
            <div className="webkitdisplay my-10 w-full">
            {totalRounds && totalRounds.map((res,index)=> (
                <div key={index} className="text-[white] border-r border-color_theme last:border-r-0 w-64 pl-4 first:pl-0">
                    <p className="text-color_theme  font-semibold text-xl capitalize">{res?.round_name}</p>
                    {res?.roundStartDate && <p className="">{moment(new Date(res?.roundStartDate)).format("MMM D")}{res?.roundEndDate && ( " - " + moment(new Date(res?.roundEndDate)).format("MMM D"))}</p>}
                    
                </div>
             ))}
            </div>
           
            

            <div className="text-[white] flex gap-0 tree-container d-flex first-div" ref={divRefone}>
                {playertreedata && playertreedata.map((resone,index)=> (
                    <div key={index} className="grid gap-x-5 gap-y-5 h-auto relative w-fit" ref={(ref) => divRef.current[index] = ref}>
                            {
                                resone.map((res,index)=>(
                                    <ul key={index} className="w-full grid border-after relative pr-12 lastcond">
                                        <li className="capitalize py-2 text-[12px] px-4 border border-color_theme h-fit m-auto relative z-10 bg-[#000] w-full min-w-[13rem] text-center">
                                            <p className="whitespace-nowrap text-overnew">
                                            {res?.winner && 
                                            <img className=" h-full m-auto" src={trophy}/>}
                                            {res?.team?.name}{res?.team?.mate != "" && ", "+res?.team?.mate }
                                            </p>
                                            {res?.score && <p className="text-[#fffe04] absolute left-[50%] right-[50%] top-[110%] translate-x-[-50%] w-max">{res.score}</p>}
                                        </li>
                                        {res?.opponent 
                                        && 
                                        <li className="capitalize text-[12px] py-2 px-4 border border-color_theme whitespace-nowrap relative z-10 bg-[#000] text-overnew w-full min-w-[13rem] text-center">
                                            {res?.opponent?.name}{res?.opponent?.mate != "" && (", "+res?.opponent?.mate) }
                                        </li>}
                                    </ul>
                                ))
                            }
                    </div>
                ))}
            </div>
            </>}

            {playertreenolist && 
                <div className="my-10 text-center">
                  <p className=" font-semibold text-2xl text-[white] text-center">No Data Found</p>
                </div>
            }
        </div>
        </div>
        </>
    )
})



