import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdImageAspectRatio, MdImage } from "react-icons/md";
import { Input, Button, Select } from "react-daisyui";
// import { CityStore, CityStoreContext } from "./store/CityStore";
import { AUserListStore, AUserListStoreContext, useAUserListdStore } from "./store/AUserList";

import { observer } from "mobx-react";
import { useAuthStore } from "../Auth/store/Auth";
import { useState } from "react";
import { RxEyeClosed, RxEyeOpen } from "react-icons/rx";
import { toast } from "react-toastify";

const AddAUser = observer(() => {

    return (
        <AUserListStoreContext.Provider
            value={
                new AUserListStore({
                    loadeditcitybol: false,
                    addloading: true,
                })
            }
        >
            <Screen />
        </AUserListStoreContext.Provider>
    );
});
export default AddAUser;
const Screen = observer(() => {
    const { getstatus, setname, getmobileno, addUser, getToken, setemail, setalert, Confirmpassw, passwordChanged, getseourl, setShopname,seoUrl ,errPassword, saved, addloadcity } = useAUserListdStore();
    const { adminAuthApproved } = useAuthStore();
    const [showpassword, setshowpassword] = useState(false);
    const [confirmpassword, setconfirmpassword] = useState(false);
    // const [crop, setCrop] = useState({ aspect: 16 / 9, dimensions: { width: 480, height: 270 } });
    // const { register, handleSubmit, errors } = useForm();
    const SEO_URL = process.env.REACT_APP_SEO_URL;

    const onSubmit = (data) => {
        // Handle form submission logic here
        console.log(data);
    };

    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);
    const navigate = useNavigate();


    useEffect(() => {
        if (saved) {
            navigate("../approvedusers");
        }
    }, [saved]);



    const handleSubmit = (e) => {
        e.preventDefault();
        addUser();
        // Perform API call or any other logic using MobX store data
        // Example: addCity();
    };



    return (
        <>
            <div className="flex justify-between">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold"> User Setup</p>
                    <p className=" opacity-80 text-sm">Home /  User Setup</p>
                </div>
                <div>
                    <Link
                        to="../approvedusers"
                        className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                        Back
                    </Link>
                </div>
            </div>
            <div className="card bg-base-100 shadow-md p-6 mt-8">
                <form onSubmit={handleSubmit}>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                        <div>
                            <label
                                htmlFor="name"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Full name<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="name"
                                required
                                placeholder="name"
                                onChange={(e) => { setname(e.target.value); }}
                                id="name"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>
                        <div>
                            <label
                                htmlFor="shopname"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Shop name<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="shopname"
                                required
                                placeholder="shopname"
                                onChange={(e) => { setShopname(e.target.value); }}
                                id="shopname"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>
                        <div>
                            <label
                                htmlFor="Mobileno"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Phone number<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="mobileno"
                                required
                                placeholder="mobileno"
                                onChange={(e) => getmobileno(e.target.value)}
                                id="mobileno"
                                type="number"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        <div>

                            <Input
                                name="email"
                                required
                                placeholder="email"
                                onChange={(e) => setemail(e.target.value)}
                                id="type your email"
                                type="email"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>

                        <div>

                            <Input
                                name="seo"
                                required
                                // value={seoUrl}
                                onChange={(e) => getseourl(e.target.value)}
                                placeholder="SEO-friendly URL"
                                id="seo"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                            <span className="text-color_theme font-light ml-1 text-xs">{`${SEO_URL}${seoUrl}`}</span>
                        </div>







                    </div>
                    <div className="grid lg:grid-cols-2   mt-9 md:grid-cols-2 md:gap-8 gap-4">
                        <label className=" relative">
                            <input
                                autoComplete="password"
                                required
                                onChange={(e) => passwordChanged(e.target.value)}
                                className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                                placeholder="New Password"
                                type={showpassword ? "text" : "password"}
                            />
                            <span className=" cursor-pointer absolute right-5 top-4" onClick={() => setshowpassword(!showpassword)}>
                                {showpassword
                                    ?
                                    <RxEyeOpen className=" text-[#837575]" />
                                    :
                                    <RxEyeClosed className=" text-[#837575]" />
                                }
                            </span>
                        </label>



                        <label className=" relative">
                            <input
                                autoComplete="confirm-password"
                                required
                                onChange={(e) => Confirmpassw(e.target.value)}
                                className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                                placeholder="Confirm password"
                                type={confirmpassword ? "text" : "password"}
                            />
                            <span className=" cursor-pointer absolute right-5 top-4" onClick={() => setconfirmpassword(!confirmpassword)}>
                                {confirmpassword
                                    ?
                                    <RxEyeOpen className=" text-[#837575]" />
                                    :
                                    <RxEyeClosed className=" text-[#837575]" />
                                }
                            </span>
                        </label>
                    </div>

                    <div className="grid lg:grid-cols-2  border-dashed border-t-2 mt-9 md:grid-cols-2 md:gap-8 gap-4">

                        <div className="mt-7">
                            {/* <label
                                htmlFor="Status"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Status
                            </label> */}
                            <select
                                id="Status"
                                onChange={(e) => getstatus(e.target.value)}
                                name="Status"
                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                            >
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </select>
                        </div>

                        <div className="mt-7">
                            <label class="relative bg-[#f1f1f1]  justify-between text-[#837575] w-full border border-[#7E7777] rounded-lg px-2 py-3 focus:outline-none inline-flex items-center mb-5 cursor-pointer">

                                <span class=" text-sm  font-medium text-gray-900 dark:text-gray-300"> Allow user to receive system generated alerts</span>
                                <div className="relative">
                                    <input value="1"
                                        onClick={(e) => setalert()} type="checkbox" className="sr-only peer" />
                                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                                </div>
                            </label>


                        </div>

                    </div>

                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <Link
                            to="../approvedusers"
                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                        >
                            <MdOutlineClose className="mr-1" />
                            Cancel
                        </Link>
                        {!addloadcity ?
                            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                                <MdOutlineCheck className="mr-1" />
                                Add User
                            </button>
                            :
                            <button
                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                type="button"
                            //  onClick={()=> changeStep("4")}
                            >
                                <svg
                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Loading..
                            </button>
                        }

                    </div>
                </form>
            </div>
        </>
    );
});

