import React, { useEffect, useState } from "react";
import {
  PlayerStore,
  usePlayerStore,
  PlayerStoreContext,
} from "./store/PlayerStore";
import Filter from "./PlayerFilter/Filter";
import {  useParams,useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { MdSportsTennis, MdSportsHandball } from "react-icons/md";

import ReactPaginate from "react-paginate";
import { useAuthStore } from "../Auth/store/Auth";

const PlayerMangement = () => {
  const store = new PlayerStore({
    isloading: true,
    playerlist: true,
  });
  return (
    <PlayerStoreContext.Provider value={store}>
      <Screen />
    </PlayerStoreContext.Provider>
  );
};
export default PlayerMangement;
const Screen = observer(() => {
  const { PlayerList, loadPlayer, nolist,getToken } = usePlayerStore();
  const { adminAuthApproved } = useAuthStore();

  const { id } = useParams();
  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }
  
  }, [adminAuthApproved]);

  const [currentPage, setCurrentPage] = useState(0);
  const ITEMS_PER_PAGE = 50;
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleClick=()=>{
    const element = document.getElementById("tablescroll");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
  const startIndex = currentPage * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentPlayerList = PlayerList.slice(startIndex, endIndex);
  const navigate = useNavigate();

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Player Management</p>
          {/* <p className=" opacity-80 text-sm">Home / Player</p> */}
        </div>
        {/* <div>
        <Link to="./addplayer" className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"><MdAdd/>Add Player</Link>
      </div> */}
      </div>
      <Filter league={id} />
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
          <table id="tablescroll" className="table table-zebra w-full">
            <thead>
              <tr>
                <th>#</th>
                <th>Player Name</th>
                <th>Email Address</th>
                <th  className="hidden" >League name</th>

                <th>Level</th>
                {/* <th>Partner Name</th> */}
                <th>Home Court</th>
                <th>City</th>
                {/* <th>Action</th> */}

                {/* <th>End date</th>
                <th>Status</th> */}
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {loadPlayer && (
                <>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                </>
              )}
              {
              (currentPlayerList?.length === 0 ? 
             PlayerList : currentPlayerList)?.map((data, index) => (
              <tr key={index} className="capitalize">
                <td>{currentPage * ITEMS_PER_PAGE + index + 1}</td>
                <td>
                  <div className="flex items-center space-x-3">
                    <div>
                      <div className="font-bold capitalize cursor-pointer text-[#7ac46b]"
                      onClick={() => {
                        navigate(`/playerdetail/${data?.user._id}/${ (data?.user?.firstName ?? "-") +
                        " " +
                        (data?.user?.lastName ?? "-")}`);
                      }}
                      >
                        {(data?.user?.firstName ?? "-") +
                          " " +
                          (data?.user?.lastName ?? "-")}
                      </div>
                      {/* <div className="text-sm ">{data?.user?.email}</div> */}
                    </div>
                  </div>
                </td>

                <td>{data?.user?.email}</td>
                <td className="hidden">{data?.league.name ?? "N/A"}</td>
                <td>{data?.lavel?.name.toFixed(1) ?? "N/A"}</td>
                {/* <td>
                  <div className="flex items-center space-x-3">
                    <div>
                      <div className="font-bold capitalize text-[#7ac46b] cursor-pointer"
                      
                       onClick={() => {
                        if(data?.mate !== null){
                          navigate(`/playerdetail/${data?.mate?._id}/${ data?.mate?.firstName + " " + data?.mate?.lastName}`);
                        }
                      }}
                      >
                        {data?.mate?.firstName === undefined ? (
                          <span className="pl-7">---</span>
                        ) : (
                          data?.mate?.firstName + " " + data?.mate?.lastName
                        )}
                      </div>
                      <div className="text-sm ">{data?.mate?.email}</div>
                    </div>
                  </div>
                </td> */}

                <td>
                  {data?.home_court?.displayName ?? "N/A"}
                </td>
                <td>{data?.city?.city_name ?? "N/A"}</td>
                {/* <td className="ml-4" >
                  <div className="tooltip tooltip-left" data-tip="View Player History">
                  <Link className="cursor-pointer"  to={`/playerdetail/${data?.user?._id}`}>
                      <MdRemoveRedEye />
                    </Link>
                    </div>
                    </td> */}

                {/* <td>{data?.year === "" ? "N/A" : data?.year}</td> */}
                {/* <td>
                  {<Moment format="YYYY/MM/DD">{data?.start_date}</Moment> ??
                    "N/A"}
                </td>
                <td>
                  {<Moment format="YYYY/MM/DD">{data?.end_date}</Moment> ??
                    "N/A"}
                </td> */}

                {/* <td className="text-base">
                  <div className="flex items-center space-x-4">
                    <button title="View Matches">
                      <Link  to={`/seasonmanagement/${data?._id}`}>
                        <RxEyeOpen />
                      </Link>
                       
                    </button>
                   
                  
                  </div>
                </td> */}
              </tr>
            )) 
            }
             
              {nolist && (
                <tr>
                  <td colSpan={8} className="my-10 text-center">
                    <MdSportsHandball className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">No Player Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {!PlayerList?.length == 0 &&
          
          <ReactPaginate
          initialPage={0}
          pageCount={Math.ceil(PlayerList.length / ITEMS_PER_PAGE)}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={handlePageChange}
          onClick={handleClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
          }
          

          <input type="checkbox" id="courtstatus" className="modal-toggle" />
          {/* <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="courtstatus"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status ?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="courtstatus"
                  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                  onClick={() => statusChange()}
                >
                  Yes
                </label>
                <label
                  htmlFor="courtstatus"
                  className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                >
                  No
                </label>
              </div>
            </div>
          </div> */}
          <input type="checkbox" id="leaguecreated" className="modal-toggle" />
          {/* <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="leaguecreated"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to create League.
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="leaguecreated"
                  className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                  onClick={() => addLeague()}
                >
                  Yes
                </label>
                <label
                  htmlFor="leaguecreated"
                  className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                >
                  No
                </label>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
});
