import React from "react";
import { MdAdd, MdOutlineDelete, MdOutlinedFlag } from "react-icons/md";
import { RxEyeOpen, RxPencil1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import Filter from "./CouponFilter/Filter";
import Moment from "react-moment";
import {
  useCouponStore,
  CouponStore,
  CouponStoreContext,
} from "./Store/CouponStore";
import { useAuthStore } from "../Auth/store/Auth";
import { useEffect } from "react";
const CoupenManagement = () => {
  const store = new CouponStore({
    isloading: true,
    editloading: false,
  });
  return (
    <CouponStoreContext.Provider value={store}>
      <Screen />
    </CouponStoreContext.Provider>
  );
};
export default CoupenManagement;

const Screen = observer(() => {
  const {
    CoupenLoader,
    Coupenpopup,
    coupon,
    loadCoupon,
    deletepopup,
    delateLoader,
    getid,
    setdelateLoader,
    setdeletepopup,
    deletefunction,
    setcoupenpopup,
    getstatus,
    getcouponid,
    statusChange,
    nolist,
    getToken
  } = useCouponStore();
  const { adminAuthApproved } = useAuthStore();

  useEffect(() => {
    if (adminAuthApproved) {
      getToken(localStorage.getItem("Admintoken"));
    }

  }, [adminAuthApproved]);
  console.log("coupon", coupon);
  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Promo Code Management</p>
          {/* <p className=" opacity-80 text-sm">Home / League</p> */}
        </div>
        <div>
          <Link
            to="./addcoupen"
            className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
          >
            <MdAdd />
            Add Promo Code
          </Link>
        </div>
      </div>
      {/* <Filter /> */}

      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th>#</th>
                <th>Promo Code Name</th>
                {/* <th>Promo Code </th> */}
                <th>Discount Type</th>
                <th>Discount</th>
                <th>CURRENT USAGE</th>
                <th>Total Use Limit</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {loadCoupon && (
                <>
                  <tr>
                    <td colSpan={10} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={10} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={10} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={10} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={10} className="animate-pulse py-6"></td>
                  </tr>
                </>
              )}

              {coupon.map((data, index) => (
                <tr key={index} >
                  <td>{index + 1}</td>
                  <td className="capitalize">{data?.promo_name ?? "N/A"}</td>
                  {/* <td>{data?.code ?? "N/A"}</td> */}
                  <td className="capitalize">{data?.promo_type ?? "N/A"}</td>
                  <td>{ data?.promo_type == "amount" ? `${data?.currency == "INR" ? "₹" : "$"  } ${ data?.promo_value} `  : data?.promo_value ?? "N/A"}</td>
                  <td>{data?.total_used_till_now ?? "N/A"}</td>
                  <td>{data?.total_use_limit ?? "N/A"}</td>

                  <td>
                    {<Moment format="MM/DD/YYYY">{data?.offer_start_time}</Moment> ??
                      "N/A"}
                  </td>
                  <td>
                    {<Moment format="MM/DD/YYYY">{data?.offer_end_time}</Moment> ??
                      "N/A"}
                  </td>

                  {/* <td className=" text-center">{<Moment format="YYYY/MM/DD">{data.last_registration_date}</Moment> ?? "N/A"}</td> */}

                  <th>
                    <label
                      title="Coupon Status"
                      htmlFor="couponstatus"
                      className={`btn capitalize ${data?.is_active
                        ? "bg-[#5dff5d] hover:bg-[#5dff5d]"
                        : "bg-warning hover:bg-warning"
                        } text-[#000] border-hidden  btn-xs`}
                      onClick={(e) => {
                        setcoupenpopup(true);
                        getcouponid(data?.promo_id);
                        getstatus(data?.is_active === 1 ? 0 : 1);
                      }}
                    >
                      {data?.is_active ? "Active" : "Inactive"}
                    </label>
                  </th>
                  <td className="text-base">
                    <div className="flex items-center space-x-4">
                      <label
                        htmlFor="deletecity"
                        className=" text-[#d02c2c] cursor-pointer"
                        title="Delete"
                        onClick={() => { setdeletepopup(true); getid(data?.promo_id) }}
                      >
                        <MdOutlineDelete />
                      </label>
                      <button title="Edit">
                        <Link to={`/coupenmanagement/${data?.promo_id}`}>
                          <RxPencil1 />
                        </Link>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}

              {nolist && (
                <tr>
                  <td colSpan={9} className="my-10 text-center">
                    <MdOutlinedFlag className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">No Promo Code Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {deletepopup &&
            <>
              <input type="checkbox" id="deletecity" className="modal-toggle" />
              <div className="modal">
                <div className="modal-box relative">
                  <label

                    className="btn btn-sm btn-circle absolute right-2 top-2"
                    onClick={() => { setdelateLoader(false); setdeletepopup(false) }}
                  >
                    ✕
                  </label>
                  <h3 className="text-lg font-bold text-center">
                    Are you sure you want to Delete this Coupon
                  </h3>
                  <div className="modal-action justify-center">
                    {/* <label
                                            htmlFor="deletecity"
                                            className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                            onClick={() => deletefunction()}
                                        >
                                            Yes
                                        </label> */}
                    {!delateLoader ?
                      <label

                        className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                        onClick={() => deletefunction()}
                      >
                        Yes
                      </label> :
                      <button
                        // type="submit"
                        disabled
                        className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                      // onClick={() => {
                      //   PostMatchscore();
                      // }}
                      >
                        Yes..
                        <svg
                          className="animate-spin mr-1 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </button>}
                    <label
                      onClick={() => setdeletepopup(false)}
                      className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </>

          }
          {Coupenpopup && (
            <>
              <input
                type="checkbox"
                id="couponstatus"
                className="modal-toggle"
              />
              <div className="modal">
                <div className="modal-box relative">
                  <label
                    className="btn btn-sm btn-circle absolute right-2 top-2"
                    onClick={() => setcoupenpopup(false)}
                  >
                    ✕
                  </label>
                  <h3 className="text-lg font-bold text-center">
                    Are you sure you want to change Status ?
                  </h3>
                  <div className="modal-action justify-center">
                    {!CoupenLoader ? (
                      <label
                        className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                        onClick={() => statusChange()}
                      >
                        Yes
                      </label>
                    ) : (
                      <button
                        // type="submit"
                        disabled
                        className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                      // onClick={() => {
                      //   PostMatchscore();
                      // }}
                      >
                        Yes..
                        <svg
                          className="animate-spin mr-1 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </button>
                    )}

                    <label
                      onClick={() => setcoupenpopup(false)}
                      // htmlFor="voidstatus"
                      className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
});
//
