import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdImageAspectRatio, MdImage, MdEdit } from "react-icons/md";
import { Input, Button, Select } from "react-daisyui";
// import { CityStore, CityStoreContext } from "./store/CityStore";
import { SectionStore, SectionStoreContext, useSectionStore } from "./store/Sectionstore";
import { observer } from "mobx-react";
import { useAuthStore } from "../Auth/store/Auth";
import { useState } from "react";
import ImgCrop from "antd-img-crop";
import { Upload } from 'antd';
import axios from "axios";
const SEO_URL = process.env.REACT_APP_SEO_URL;

const AddSection = observer(() => {
    return (
        <SectionStoreContext.Provider
            value={
                new SectionStore({
                    loadeditcitybol: false,
                    addloading: true,
                    
                })
            }
        >
            <Screen />
        </SectionStoreContext.Provider>
    );
});
export default AddSection;

const Screen = observer(() => {

    const { setSectionFormDetails, addSection, setaddSectionname, getstatus, logofile, SectionFormDetails, crop, logoimage, errorMessages, addSectionname, saved, getseourl, seoUrl, addloadcity, getToken } = useSectionStore();
    const { adminAuthApproved } = useAuthStore();
    const { id } = useParams();

    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);


    const navigate = useNavigate();
    useEffect(() => {
        if (saved) {
            navigate("../sections");
        }
    }, [saved]);


    const handleSubmit = (e) => {
        e.preventDefault();
        addSection()
        // Perform API call or any other logic using MobX store data
        // Example: addCity();
    };




    return (
        <>
            <div className="flex justify-between">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">Add Section</p>
                    <p className=" opacity-80 text-sm">Home /Section/ Add</p>
                </div>
                <div>
                    <Link
                        to="../sections"
                        className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                        Back
                    </Link>
                </div>
            </div>
            <div className="card bg-base-100 shadow-md p-6 mt-8">
                <form onSubmit={handleSubmit} >
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                        <div>
                            <label
                                htmlFor="Sectionname"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Section name<span className="text-[#f00]">&nbsp;*</span>
                            </label>
                            <Input
                                name="Sectionname"
                                required
                                onChange={(e) => { setaddSectionname(e.target.value)}}

                                placeholder="Section Name"
                                id="Sectionname"
                                type="text"
                                autoComplete="off"
                                className=" focus:outline-0 w-full text-sm font-medium rounded-md"
                            />

                        </div>


                        <div>
                            <label
                                htmlFor="Status"
                                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                            >
                                Section Status
                            </label>
                            <select
                                id="Status"
                                onChange={(e) => getstatus(e.target.value)}
                                name="Status"
                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
                            >
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </select>
                        </div>


                    </div>


                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <Link
                            to="../sections"
                            className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                        >
                            <MdOutlineClose className="mr-1" />
                            Cancel
                        </Link>
                        {!addloadcity ?
                            <button className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4">
                                <MdOutlineCheck className="mr-1" />
                                Update
                            </button>
                            :
                            <button
                                className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                type="button"
                            //  onClick={()=> changeStep("4")}
                            >
                                <svg
                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Loading..
                            </button>
                        }

                    </div>
                </form >
            </div >
        </>
    );
});
