import React, { useState } from "react";
import { RxPencil1 } from "react-icons/rx";
import { Link, useParams } from "react-router-dom";
import { MdAdd, MdEmployeeingWatermark, MdLocationCity, MdOutlineDelete, MdOutlineGroup, MdRemoveRedEye, MdExpand, MdMoveUp, MdSwipeUp, MdArrowDropUp } from "react-icons/md";
import { observer } from "mobx-react";
import ReactPaginate from 'react-paginate';
import { EmployeeStore, useEmployeeStore, EmployeeStoreContext } from "./store/EmployeeStore";
import { useAuthStore } from "../Auth/store/Auth";
import { useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
// import { ExpandMore } from "@mui/icons-material";

import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import Moment from "react-moment";

import dem05 from "../assets/images/2.png"
import Aadharcard from "../assets/images/AdharCardjpg.jpg"
import voterId from "../assets/images/Voterid.png"
import license from "../assets/images/License.jpg"
import bankdetail from "../assets/images/BankDetail.jpg"


const EmployeeDetail = () => {
    const { id } = useParams();

    const store = new EmployeeStore({
        Employeeeditloading: true,
        id: id
    });
    return (
        <EmployeeStoreContext.Provider value={store}>
            <Screen />
        </EmployeeStoreContext.Provider>
    )
}
export default EmployeeDetail;


const Screen = observer(() => {
    const {
        AdminUserFormDetails,
        bankDetail,
        OtherFormDetail,
        getToken
    } = useEmployeeStore();

    const { adminAuthApproved, admin_permissions } = useAuthStore();

    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);
    const BASE_URL = process.env.REACT_APP_BASE_URL;






    return (
        <>
            <div className="flex justify-between">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">Employee Details</p>
                    <p className=" opacity-80 text-sm">Home / Employee  / 155 </p>

                </div>
                <div>
                    <Link
                        to="../adminusers"
                        className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                        Back
                    </Link>
                </div>
            </div>


            <div className="flex space-x-5">
                <div className="card bg-base-100 w-5/12 shadow-md p-6 mt-8">
                    <div className="flex justify-center " >

                        <img width="115px" height="115px" crossOrigin="anonymous" className="rounded-full border-2 border-color_theme" src={BASE_URL + `/uploads/adminUsers/${AdminUserFormDetails.image}`} />


                    </div>
                    <span className="text-center text-gray-700 text-lg font-semibold mt-3">
                        {AdminUserFormDetails.full_name}
                    </span>
                    <span className="text-center text-gray-500 text-sm font-semibold mt-1">
                        {OtherFormDetail.designation}
                    </span>
                    <span className="text-center text-gray-500 text-[19px] mt-3">
                        Salary ( {OtherFormDetail.salary})
                    </span>
                    <div className="border-t-[1px] border-dashed mt-3 border-gray-300">
                        <div className="mx-5 mt-3 ">
                            <p className="text-sm text-gray-500 ">Email address</p>
                            <span className="text-sm text-gray-700 font-semibold ">{AdminUserFormDetails.email}</span>
                        </div>
                    </div>

                    <div className="  mt-5 ">

                        <div className="mx-5 mt-3 ">
                            <p className="text-sm text-gray-500 ">Phone</p>
                            <span className="text-sm text-gray-700 font-semibold ">{AdminUserFormDetails.mobile}</span>
                        </div>
                    </div>
                    <div className="  mt-5 ">

                        <div className="mx-5 mt-3 ">
                            <p className="text-sm text-gray-500 ">Address</p>
                            <span className="text-sm text-gray-700 font-semibold ">{OtherFormDetail.address_line_2}</span>
                        </div>
                    </div>
                    <div className="  mt-5 ">

                        <div className="mx-5 mt-3 ">
                            <p className="text-sm text-gray-500 ">Account No</p>
                            <span className="text-sm text-gray-700 font-semibold ">{bankDetail.acc_no}</span>
                        </div>
                    </div>
                    <div className="  mt-5 ">

                        <div className="mx-5 mt-3 ">
                            <p className="text-sm text-gray-500 ">Bank Name</p>
                            <span className="text-sm text-gray-700 font-semibold ">{bankDetail.bank_name}</span>
                        </div>
                    </div>
                    <div className="  mt-5 ">
                        <div className="mx-5 mt-3 ">
                            <p className="text-sm text-gray-500 "> Branch Address</p>
                            <span className="text-sm text-gray-700 font-semibold ">{bankDetail.branch_addr}</span>
                        </div>
                    </div>


                </div>
                <div className="card bg-base-100 w-9/12 shadow-md p-6 mt-8">
                    <div className="flex justify-start">
                        <p className=" text-xl pb-5 font-medium bEmployee-b-[1px] bEmployee-dashed">Other Info</p>





                    </div>

                    <div >
                        <span className="text-[15px] text-gray-700 font-semibold  ">
                            Aadhar No: {OtherFormDetail.aadhar_no}

                        </span>
                        <div className="flex mx-5 justify-between mt-3 ">
                            <a target="_blank" href={BASE_URL + `/uploads/adminUsers/${OtherFormDetail.aadhar_img_1}`}>
                                <img width="155px" height="155px" crossOrigin="anonymous" src={BASE_URL + `/uploads/adminUsers/${OtherFormDetail.aadhar_img_1}`} />

                            </a>
                            <a target="_blank" href={BASE_URL + `/uploads/adminUsers/${OtherFormDetail.aadhar_img_2}`}>
                                <img width="155px" height="155px" crossOrigin="anonymous" src={BASE_URL + `/uploads/adminUsers/${OtherFormDetail.aadhar_img_2}`} />
                            </a>

                        </div>
                    </div>
                    <div className="mt-3" >
                        <span className="text-[15px] text-gray-700 font-semibold  ">
                            ID No: {OtherFormDetail.voter_no}

                        </span>
                        <div className="flex mx-5 justify-between mt-3 ">
                            <a target="_blank" href={BASE_URL + `/uploads/adminUsers/${OtherFormDetail.voter_img_1}`}>
                                <img width="155px" height="155px" crossOrigin="anonymous" src={BASE_URL + `/uploads/adminUsers/${OtherFormDetail.voter_img_1}`} />

                            </a>
                            <a target="_blank" href={BASE_URL + `/uploads/adminUsers/${OtherFormDetail.voter_img_2}`}>
                                <img width="155px" height="155px" crossOrigin="anonymous" src={BASE_URL + `/uploads/adminUsers/${OtherFormDetail.voter_img_2}`} />
                            </a>

                        </div>
                    </div>
                    <div className="mt-3" >
                        <span className="text-[15px] text-gray-700 font-semibold  ">
                            Licence No: {OtherFormDetail.licence_no}


                        </span>
                        <div className="flex mx-5 justify-between mt-3 ">
                            <a target="_blank" href={BASE_URL + `/uploads/adminUsers/${OtherFormDetail.licence_img_1}`}>
                                <img width="155px" height="155px" crossOrigin="anonymous" src={BASE_URL + `/uploads/adminUsers/${OtherFormDetail.licence_img_1}`} />
                            </a>
                            <a target="_blank" href={BASE_URL + `/uploads/adminUsers/${OtherFormDetail.licence_img_2}`}>
                                <img width="155px" height="155px" crossOrigin="anonymous" src={BASE_URL + `/uploads/adminUsers/${OtherFormDetail.licence_img_2}`} />
                            </a>


                        </div>
                    </div>
                    <div className="flex mt-5 justify-between ">
                        <div className="mt-3" >
                            <span className="text-[15px] text-gray-700 font-semibold  ">
                                PAN No: {OtherFormDetail.pan_no}


                            </span>
                            <div className="flex mx-5  mt-3 ">
                                <a target="_blank" href={BASE_URL + `/uploads/adminUsers/${OtherFormDetail.pan_img_1}`}>
                                    <img width="155px" height="155px" crossOrigin="anonymous" src={BASE_URL + `/uploads/adminUsers/${OtherFormDetail.pan_img_1}`} />
                                </a>


                            </div>
                        </div>
                        <div className="mt-3" >
                            <span className="text-[15px] text-gray-700 font-semibold  ">
                                Account No: {bankDetail.acc_no}


                            </span>
                            <div className="flex mx-5  mt-3 ">
                                <a target="_blank" href={BASE_URL + `/uploads/adminUsers/${bankDetail.passbook_img}`}>
                                    <img width="155px" height="155px" crossOrigin="anonymous" src={BASE_URL + `/uploads/adminUsers/${bankDetail.passbook_img}`} />
                                </a>


                            </div>
                        </div>


                    </div>

                </div>


            </div>



        </>
    );
});

